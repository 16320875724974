import { combineReducers } from 'redux'

import authToken from './authToken'
import linkedin from './linkedin'
import api from './api'
import user from './user'
import report from './report'

export default combineReducers({
  authToken,
  linkedin,
  api,
  user,
  report
})
