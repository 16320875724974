import React from 'react'

export default () => (
  <div>
    <h1 className="g--font-h2--light">Salary, skill and job data provided by Lightcast</h1>
    <p className="g--font-body--light">
      Career Mapper uses real-time data from Lightcast Technologies to
      match the skills required for particular roles with suitable program
      offerings from Hult International Business School to facilitate program
      choice based on career goals. All data is based on job postings in the
      U.S. and globally as indicated. All analysis and recommendations should
      be taken as indicative and should be used to help guide your education
      journey, program choice, and course selection.
    </p>
    <p className="g--font-body--light">
      The information provided does not offer any guarantee that you will
      secure a job at your desired seniority and salary level after completing
      a program from Hult. Suitability for a role is dependent on many factors
      including relevant work experience, personal profile, and culture fit.
      Having the skills required does not guarantee employment in your desired
      role.
    </p>
  </div>
)
