import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import PageLoader from '../components/page-loader'
import CareerMapperReport from '../containers/career-mapper/shared-report'
import CareerFinderReport from '../containers/career-finder/shared-report'

import { getReport } from '../actions'

class SharedReport extends Component {
  componentDidMount() {
    const { match, callGetReport } = this.props
    const { uuid, suid } = match.params
    callGetReport(uuid, suid)
  }

  render() {
    const { report, match } = this.props
    const { uuid, suid } = match.params

    console.log({ report, uuid, suid })

    if (uuid && suid && report.uuid === uuid && report.suid === suid) {
      if (report && report.email) {
        if (report.reportDetails) return <CareerFinderReport />
        else if (report.careerPath) return <CareerMapperReport />
      }
    }

    return <PageLoader title='Loading...' />
  }
}

SharedReport.defaultProps = {}

SharedReport.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
  callGetReport: PropTypes.func.isRequired
}

const mapStateToProps = state => ({ report: state.report || {} })

const mapDispatchToProps = dispatch => ({
  callGetReport: (uuid, suid) => {
    dispatch(getReport(uuid, suid))
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SharedReport)
)
