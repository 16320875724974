import _ from 'lodash'
import { parseSkillGroups } from './parseBGSkills'

export const saveData = state => {
  const userData = JSON.parse(JSON.stringify(state.user))
  const { user, api, linkedin } = state
  const { currentRole } = user
  const { jobDetails } = api

  if (linkedin && linkedin.profile) userData.linkedin = linkedin.profile

  if (currentRole && jobDetails[currentRole] && jobDetails[currentRole].result)
    userData.currentRoleDetails = jobDetails[currentRole].result

  if (userData.careerPath) {
    _.each(userData.careerPath, role => {
      if (jobDetails[role.name] && jobDetails[role.name].result) {
        role.details = jobDetails[role.name].result
        const allSkills = _.flatMap(parseSkillGroups(role.details.skills), g =>
          _.flatMap(g, 'name')
        )
        role.skillsGap = _.pullAllBy(allSkills, role.skills)
      }
    })
  }

  if (api.recommendedPrograms && api.recommendedPrograms.programs)
    userData.programs = api.recommendedPrograms.programs

  return userData
}
