import React from 'react'
import PropTypes from 'prop-types'
import { Skill, Card, Button } from 'hult-component-library'
import FontAwesome from '../font-awesome'

const Summary = ({
  className,
  firstName,
  lastName,
  currentRole,
  education,
  experience,
  isHighSchoolStudent,
  strengthList,
  isEditable,
  action
}) => (
  <>
    <p className='g--font-h4--light'>Your career so far</p>
    <Card className={`summary ${className}`}>
      <div className='row row--space-between row--align-center'>
        <div className='row__column'>
          <p className='g--margin-none g--font-body-bold'>
            {firstName || lastName
              ? `${firstName} ${lastName}`
              : 'Your profile'}
          </p>
        </div>
        {isEditable && (
          <div className='row__column'>
            <Button
              icon='pencil'
              action={action}
              Wrapper={'button'}
              wrapperProps={{ onClick: action }}
            />
          </div>
        )}
      </div>
      <hr />
      {isHighSchoolStudent && (
        <div className='statistic statistic--strengths'>
          <FontAwesome className='statistic__icon' icon='school' />
          <p className='statistic__copy g--margin-none g--font-body-bold'>
            High School Student
          </p>
        </div>
      )}
      {education && (
        <div className='statistic statistic--strengths'>
          <FontAwesome className='statistic__icon' icon='graduation-cap' />
          <p className='statistic__copy g--margin-none g--font-body-bold'>
            {education}
          </p>
        </div>
      )}
      {currentRole && (
        <div className='statistic statistic--strengths'>
          <FontAwesome className='statistic__icon' icon='briefcase' />
          <p className='statistic__copy g--margin-none g--font-body-bold'>
            {currentRole}
          </p>
        </div>
      )}
      {experience && experience !== 'Student' && (
        <div className='statistic statistic--strengths'>
          <FontAwesome className='statistic__icon' icon='clock' />
          <p className='statistic__copy g--margin-none g--font-body-bold'>
            {experience + ' years experience'}
          </p>
        </div>
      )}
      {strengthList && strengthList.length > 0 && (
        <>
          <hr />
          <div className='statistic statistic--strengths'>
            <FontAwesome className='statistic__icon' icon='brain' />
            <p className='statistic__copy g--margin-none g--font-body-bold'>
              Skills you already have, keep them up!
            </p>
          </div>
          <div className="g--spacer"/>
          <div className='row row--flow row--gutters-xsmall'>
            {strengthList.slice(0, 3).map(strength => (
              <div key={strength} className={`row__column`}>
                <Skill disableTogle={true} title={strength} />
              </div>
            ))}
          </div>
        </>
      )}
      {experience && experience === 'Student' && (
        <>
          <hr />
          <span className='g--font-span'>
            Salary increases are based against average starting salary.
          </span>
        </>
      )}
    </Card>
  </>
)

Summary.defaultProps = {
  className: '',
  firstName: '',
  lastName: '',
  currentRole: '',
  education: '',
  experience: '',
  isEditable: false,
  isHighSchoolStudent: false,
  strengthList: null,
  action: () => {}
}

Summary.propTypes = {
  className: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  currentRole: PropTypes.string,
  education: PropTypes.string,
  experience: PropTypes.string,
  isEditable: PropTypes.bool,
  isHighSchoolStudent: PropTypes.bool,
  action: PropTypes.func,
  strengthList: PropTypes.array
}

export default Summary
