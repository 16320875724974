import { v4 as uuidv4 } from 'uuid'
import * as ActionTypes from './types'

import { save } from './db'

const setUuid = (uuid) => ({
  type: ActionTypes.SET_USER_UUID,
  uuid
})

const setSuid = (suid) => ({
  type: ActionTypes.SET_USER_SUID,
  suid
})

export const generateUids = (force) => (dispatch, getState) => {
  const { user } = getState()

  if (!user.uuid) {
    dispatch(setUuid(uuidv4()))
  }

  if (force || !user.suid) {
    dispatch(setSuid(uuidv4()))
    dispatch(save())
  }
}

export const registerUser = (user) => ({
  type: ActionTypes.REGISTER_USER,
  user
})

export const setFirstName = (firstName) => ({
  type: ActionTypes.SET_USER_FIRSTNAME,
  firstName
})

export const setLastName = (lastName) => ({
  type: ActionTypes.SET_USER_LASTNAME,
  lastName
})

export const setEmail = (email) => ({
  type: ActionTypes.SET_USER_EMAIL,
  email
})

export const setCountry = (country) => ({
  type: ActionTypes.SET_USER_COUNTRY,
  country
})

export const setEducation = (education) => ({
  type: ActionTypes.SET_USER_EDUCATION,
  education
})

export const setCurrentJob = (job) => ({
  type: ActionTypes.SET_USER_JOB_CURRENT,
  job
})

export const setCurrentJobId = (jobId) => ({
  type: ActionTypes.SET_USER_JOB_ID_CURRENT,
  jobId
})

export const setExperience = (experience) => ({
  type: ActionTypes.SET_USER_EXPERIENCE,
  experience
})

export const setGraduationYear = (graduationYear) => ({
  type: ActionTypes.SET_USER_GRADUATION_YEAR,
  graduationYear
})

export const addUserCareerPathRole = (role) => ({
  type: ActionTypes.ADD_USER_CAREER_PATH_ROLE,
  role
})

export const clearUserCareerPathRole = (pos) => ({
  type: ActionTypes.CLEAR_USER_CAREER_PATH_ROLE,
  pos
})

export const addUserRoleSkill = (roleName, skillName) => ({
  type: ActionTypes.ADD_USER_ROLE_SKILL,
  roleName,
  skillName
})

export const removeUserRoleSkill = (roleName, skillName) => ({
  type: ActionTypes.REMOVE_USER_ROLE_SKILL,
  roleName,
  skillName
})

export const clearUserRoleSkills = (roleName) => ({
  type: ActionTypes.CLEAR_USER_ROLE_SKILL,
  roleName
})

export const addUserRolePreSkills = (roleName, skillsList) => ({
  type: ActionTypes.ADD_USER_ROLE_PRE_SKILL,
  roleName,
  skillsList
})

export const setNextRole = (nextRole) => ({
  type: ActionTypes.SET_USER_NEXT_ROLE,
  nextRole
})

export const clearNextRole = () => ({
  type: ActionTypes.CLEAR_USER_NEXT_ROLE
})

export const setFutureRole = (futureRole) => ({
  type: ActionTypes.SET_USER_FUTURE_ROLE,
  futureRole
})

export const clearFutureRole = () => ({
  type: ActionTypes.CLEAR_USER_FUTURE_ROLE
})

export const setCareerGoal = (careerGoal) => ({
  type: ActionTypes.SET_USER_CAREER_GOAL,
  careerGoal
})

export const clearCareerGoal = () => ({
  type: ActionTypes.CLEAR_USER_CAREER_GOAL
})

export const setReportId = (reportId) => ({
  type: ActionTypes.SET_USER_FINDER_REPORT_ID,
  reportId
})

export const addUserFinderStrength = (strengthName) => ({
  type: ActionTypes.ADD_USER_FINDER_STRENGTH,
  strengthName
})

export const removeUserFinderStrength = (strengthName) => ({
  type: ActionTypes.REMOVE_USER_FINDER_STRENGTH,
  strengthName
})

export const addFinderReport = (report) => ({
  type: ActionTypes.ADD_FINDER_REPORT,
  report
})

export const clearFinder = () => ({
  type: ActionTypes.CLEAR_FINDER_REPORT
})

export const clearMapper = () => ({
  type: ActionTypes.CLEAR_MAPPER_REPORT
})

export const clearUserData = () => ({
  type: ActionTypes.CLEAR_USER_ALL
})
