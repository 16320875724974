// User Data Actions
export const SET_USER_UUID = 'SET_USER_UUID'
export const SET_USER_SUID = 'SET_USER_SUID'
export const REGISTER_USER = 'REGISTER_USER'
export const SET_USER_FIRSTNAME = 'SET_USER_FIRSTNAME'
export const SET_USER_LASTNAME = 'SET_USER_LASTNAME'
export const SET_USER_EMAIL = 'SET_USER_EMAIL'
export const SET_USER_COUNTRY = 'SET_USER_COUNTRY'
export const SET_USER_GRADUATION_YEAR = 'SET_USER_GRADUATION_YEAR'

export const ADD_FINDER_REPORT = 'ADD_FINDER_REPORT'

export const SET_USER_EDUCATION = 'SET_USER_EDUCATION'
export const SET_USER_JOB_CURRENT = 'SET_USER_JOB_CURRENT'
export const SET_USER_JOB_ID_CURRENT = 'SET_USER_JOB_ID_CURRENT'
export const SET_USER_EXPERIENCE = 'SET_USER_EXPERIENCE'

export const ADD_USER_CAREER_PATH_ROLE = 'ADD_USER_CAREER_PATH_ROLE'
export const CLEAR_USER_CAREER_PATH_ROLE = 'CLEAR_USER_CAREER_PATH_ROLE'

export const SET_USER_NEXT_ROLE = 'SET_USER_NEXT_ROLE'
export const SET_USER_FUTURE_ROLE = 'SET_USER_FUTURE_ROLE'
export const SET_USER_CAREER_GOAL = 'SET_USER_CAREER_GOAL'

export const CLEAR_USER_NEXT_ROLE = 'CLEAR_USER_NEXT_ROLE'
export const CLEAR_USER_FUTURE_ROLE = 'CLEAR_USER_FUTURE_ROLE'
export const CLEAR_USER_CAREER_GOAL = 'CLEAR_USER_CAREER_GOAL'

export const ADD_USER_ROLE_SKILL = 'ADD_USER_ROLE_SKILL'
export const REMOVE_USER_ROLE_SKILL = 'REMOVE_USER_ROLE_SKILL'
export const CLEAR_USER_ROLE_SKILL = 'CLEAR_USER_ROLE_SKILL'
export const ADD_USER_ROLE_PRE_SKILL = 'ADD_USER_ROLE_PRE_SKILL'

export const SET_USER_FINDER_REPORT_ID = 'ADD_USER_FINDER_REPORT_ID'
export const CLEAR_USER_FINDER_REPORT_ID = 'CLEAR_USER_FINDER_REPORT_ID'

export const ADD_USER_FINDER_STRENGTH = 'ADD_USER_FINDER_STRENGTH'
export const REMOVE_USER_FINDER_STRENGTH = 'REMOVE_USER_FINDER_STRENGTH'

export const CLEAR_USER_ALL = 'CLEAR_USER_ALL'
export const CLEAR_FINDER_REPORT = 'CLEAR_FINDER_REPORT'
export const CLEAR_MAPPER_REPORT = 'CLEAR_MAPPER_REPORT'

// Linkedin Actions
export const SET_LINKEDIN_AUTH_CODE = 'SET_LINKEDIN_AUTH_CODE'

export const LINKEDIN_AUTH_TOKEN_REQUEST = 'LINKEDIN_AUTH_TOKEN_REQUEST'
export const LINKEDIN_AUTH_TOKEN_SUCCESS = 'LINKEDIN_AUTH_TOKEN_SUCCESS'
export const LINKEDIN_AUTH_TOKEN_FAILURE = 'LINKEDIN_AUTH_TOKEN_FAILURE'

export const LINKEDIN_PROFILE_REQUEST = 'LINKEDIN_PROFILE_REQUEST'
export const LINKEDIN_PROFILE_SUCCESS = 'LINKEDIN_PROFILE_SUCCESS'
export const LINKEDIN_PROFILE_FAILURE = 'LINKEDIN_PROFILE_FAILURE'

// BurningGlass Token Actions
export const AUTH_TOKEN_REQUEST = 'AUTH_TOKEN_REQUEST'
export const AUTH_TOKEN_SUCCESS = 'AUTH_TOKEN_SUCCESS'
export const AUTH_TOKEN_FAILURE = 'AUTH_TOKEN_FAILURE'

// BurningGlass API Actions
export const BG_JOB_TITLE_REQUEST = 'BG_JOB_TITLE_REQUEST'
export const BG_JOB_TITLE_SUCCESS = 'BG_JOB_TITLE_SUCCESS'
export const BG_JOB_TITLE_FAILURE = 'BG_JOB_TITLE_FAILURE'
export const BG_JOB_TITLE_CLEAR = 'BG_JOB_TITLE_CLEAR'

export const BG_JOB_TITLE_LI_REQUEST = 'BG_JOB_TITLE_LI_REQUEST'
export const BG_JOB_TITLE_LI_SUCCESS = 'BG_JOB_TITLE_LI_SUCCESS'
export const BG_JOB_TITLE_LI_FAILURE = 'BG_JOB_TITLE_LI_FAILURE'

export const BG_JOB_DETAILS_REQUEST = 'BG_JOB_DETAILS_REQUEST'
export const BG_JOB_DETAILS_SUCCESS = 'BG_JOB_DETAILS_SUCCESS'
export const BG_JOB_DETAILS_FAILURE = 'BG_JOB_DETAILS_FAILURE'

export const BG_JOB_DETAILS_LI_REQUEST = 'BG_JOB_DETAILS_LI_REQUEST'
export const BG_JOB_DETAILS_LI_SUCCESS = 'BG_JOB_DETAILS_LI_SUCCESS'
export const BG_JOB_DETAILS_LI_FAILURE = 'BG_JOB_DETAILS_LI_FAILURE'

export const BG_JOB_NEXT_STEP_REQUEST = 'BG_JOB_NEXT_STEP_REQUEST'
export const BG_JOB_NEXT_STEP_SUCCESS = 'BG_JOB_NEXT_STEP_SUCCESS'
export const BG_JOB_NEXT_STEP_FAILURE = 'BG_JOB_NEXT_STEP_FAILURE'

// Recommended Programs API
export const PROGRAMS_REQUEST = 'PROGRAMS_REQUEST'
export const PROGRAMS_SUCCESS = 'PROGRAMS_SUCCESS'
export const PROGRAMS_FAILURE = 'PROGRAMS_FAILURE'

// REPORTS
export const REPORT_REQUEST = 'REPORT_REQUEST'
export const REPORT_SUCCESS = 'REPORT_SUCCESS'
export const REPORT_FAILURE = 'REPORT_FAILURE'

// CREATE SF LEAD
export const CREATE_LEAD_REQUEST = 'CREATE_LEAD_REQUEST'
export const CREATE_LEAD_SUCCESS = 'CREATE_LEAD_SUCCESS'
export const CREATE_LEAD_FAILURE = 'CREATE_LEAD_FAILURE'
