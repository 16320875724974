import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import _ from 'lodash'

import { Button, Footer } from 'hult-component-library'

import RoleDetail from '../../components/role-detail'

import {
  getJobDetails,
  addUserRoleSkill,
  removeUserRoleSkill,
  clearUserRoleSkills,
  addUserRolePreSkills,
  save
} from '../../actions'

import { parseSkillGroups } from '../../utils/parseBGSkills'
import { careerPathLabels, studentRole } from '../../utils/config'

import { calculateSalaryIncrease } from '../../utils/methods'

class Detail extends Component {
  state = {
    jobName: decodeURIComponent(this.props.match.params.job),
    selectedSkills: []
  }

  componentDidMount() {
    const { jobName } = this.state
    const { currentJob, callGetJobDetails } = this.props
    const jobDetails =
      this.props.jobsDetails[jobName] && this.props.jobsDetails[jobName].result
        ? this.props.jobsDetails[jobName].result
        : null

    if (!this.props.currentJob) this.props.history.replace('/about-you')
    else {
      if (jobName) callGetJobDetails(jobName, currentJob)

      if (jobDetails) this.checkSelectedSkills(jobDetails)
    }
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps) {
    const { jobName } = this.state
    const jobDetails =
      this.props.jobsDetails[jobName] && this.props.jobsDetails[jobName].result
        ? this.props.jobsDetails[jobName].result
        : null

    const prevJobDetails =
      prevProps.jobsDetails[jobName] && prevProps.jobsDetails[jobName].result
        ? prevProps.jobsDetails[jobName].result
        : null

    if (JSON.stringify(jobDetails) !== JSON.stringify(prevJobDetails)) {
      this.checkSelectedSkills(jobDetails)
    }
  }

  checkSelectedSkills(jobDetails) {
    const { jobName } = this.state
    const { careerPath } = this.props
    const selectedSkills = _.flatMap(careerPath, 'skills')
    const allSkills = _.flatMap(parseSkillGroups(jobDetails.skills), g =>
      _.flatMap(g, 'name')
    )

    const preSelectedSkills = _.intersection(selectedSkills, allSkills)

    this.props.callAddUserRolePreSkills(jobName, preSelectedSkills)
  }

  onSkillSelect = (skill, isSelected) => {
    const { jobName } = this.state
    const { callAddUserRoleSkill, callRemoveUserRoleSkill } = this.props

    if (isSelected) {
      callAddUserRoleSkill(jobName, skill)
    } else {
      callRemoveUserRoleSkill(jobName, skill)
    }
  }

  onNoSkillsSelect = () => {
    const { jobName } = this.state

    this.props.callClearUserRoleSkills(jobName)
    this.props.history.goBack()
  }

  render() {
    const { jobName } = this.state
    const { careerPath, currentJob } = this.props

    const jobDetails =
      this.props.jobsDetails[jobName] && this.props.jobsDetails[jobName].result
        ? this.props.jobsDetails[jobName].result
        : null
    const currentJobDetails =
      this.props.jobsDetails[currentJob] &&
      this.props.jobsDetails[currentJob].result
        ? this.props.jobsDetails[currentJob].result
        : null

    if (!(jobDetails && (currentJobDetails || currentJob === 'Student')))
      return null
    const selectedSkills = _.flatMap(careerPath, 'skills')
    return (
      <>
        <div className='g--spacer--small hide show--tablet' />
        <div
          className={`content ${selectedSkills.length > 0 &&
            'content--footer-visible'}`}
        >
          {jobDetails && (
            <RoleDetail
              title={jobDetails.name}
              salary={calculateSalaryIncrease(
                currentJob === 'Student'
                  ? studentRole.salary
                  : currentJobDetails.salary.mean,
                jobDetails.salary.mean
              )}
              demand={jobDetails.demand.level}
              exp={Math.round(jobDetails.experience.mean)}
              description={jobDetails.description}
              skills={parseSkillGroups(jobDetails.skills)}
              onSkillSelect={this.onSkillSelect}
              onNoSkillsSelect={this.onNoSkillsSelect}
              selectedSkills={selectedSkills}
              level={
                careerPathLabels[
                  _.indexOf(
                    _.flatMap(this.props.careerPath, 'name'),
                    jobDetails.name
                  )
                ]
              }
            />
          )}
        </div>
        {selectedSkills.length > 0 && (
          <Footer>
            <Button
              className='button--wide'
              variant='cta'
              Wrapper={'button'}
              wrapperProps={{
                onClick: () => {
                  this.props.history.goBack()
                }
              }}
              label='Confirm selection'
              icon='check'
            />
          </Footer>
        )}
      </>
    )
  }
}

Detail.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  currentJob: PropTypes.string.isRequired,
  jobsDetails: PropTypes.objectOf(
    PropTypes.shape({
      result: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    })
  ),
  callGetJobDetails: PropTypes.func.isRequired,
  callAddUserRoleSkill: PropTypes.func.isRequired,
  callClearUserRoleSkills: PropTypes.func.isRequired,
  careerPath: PropTypes.arrayOf(PropTypes.shape({}))
}

const mapStateToProps = state => ({
  currentJob: state.user.currentRole,
  jobsDetails: state.api.jobDetails || [],
  careerPath: state.user.careerPath
})
const mapDispatchToProps = dispatch => ({
  callGetJobDetails: (jobTitle, currentJob) => {
    dispatch(getJobDetails(jobTitle))
    if (currentJob !== 'Student') dispatch(getJobDetails(currentJob))
  },
  callAddUserRoleSkill: (role, skill) => {
    dispatch(addUserRoleSkill(role, skill))
    dispatch(save())
  },
  callRemoveUserRoleSkill: (role, skill) => {
    dispatch(removeUserRoleSkill(role, skill))
    dispatch(save())
  },
  callClearUserRoleSkills: role => {
    dispatch(clearUserRoleSkills(role))
    dispatch(save())
  },
  callAddUserRolePreSkills: (role, skills) => {
    dispatch(addUserRolePreSkills(role, skills))
    dispatch(save())
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Detail))
