import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Input from '../../components/input'
import { Message, Button, Footer } from 'hult-component-library'
import ProgressBar from '../../components/progress-bar'

import { graduationOptions } from '../../utils/config'
import countries from '../../utils/countries'

import {
  setGraduationYear,
  setCountry,
  save,
  clearMapper,
  generateUids
} from '../../actions'
class AboutYou extends Component {
  state = {
    enableNextSteps: false
  }

  componentDidMount() {
    if (this.props.careerPath) this.props.callClearMapper()
    this.validateStep(true)
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.validateStep()
    }
  }

  validateStep(redirect) {
    const { graduationYear, country } = this.props
    if (graduationYear && country) {
      if (redirect) this.props.history.replace('/career-finder/career-quiz')
      else this.setState({ enableNextSteps: true })
    } else {
      this.setState({ enableNextSteps: false })
    }
  }

  onGraduationYearChange = (e) => {
    const { value } = e.target
    const { callSetGraduationYear } = this.props

    callSetGraduationYear(value)
  }

  onCountryChange = (e) => {
    const { value } = e.target
    const { callSetCountry } = this.props

    callSetCountry(value)
  }

  render = () => (
    <div>
      <hr />
      <ProgressBar percentage={33.34} length={4} />
      <hr />
      <div className='content'>
        <p className='g--font-h4--light'>
          Welcome to the Career Finder! Tell us a little about yourself.
        </p>

        <Input
          id={'graduation-year'}
          placeholder={'I will graduate/graduated high school in'}
          type='select'
          onChange={this.onGraduationYearChange}
          value={this.props.graduationYear}
        >
          {graduationOptions.map((option) => (
            <option title={option} key={option} value={option}>
              {option}
            </option>
          ))}
        </Input>
        {this.props.graduationYear && (
          <>
            <Input
              id={'country'}
              placeholder={'I currently live in'}
              type='select'
              onChange={this.onCountryChange}
              value={this.props.country}
            >
              {countries.map(({ value, code }) => (
                <option title={value} key={code} value={code}>
                  {value}
                </option>
              ))}
            </Input>
          </>
        )}
        {this.state.enableNextSteps && (
          <>
            <hr />
            <Message message={`Thanks, we're all set!`} />
            <Footer>
              <Button
                className='button--wide'
                variant='cta'
                Wrapper={'button'}
                wrapperProps={{
                  onClick: () => {
                    this.props.history.replace('/career-finder/career-quiz')
                  }
                }}
                label='Plan your career path'
                icon='arrow-right'
              />
            </Footer>
          </>
        )}
      </div>
    </div>
  )
}

AboutYou.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  graduationYear: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  callSetGraduationYear: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  graduationYear: state.user.graduationYear || '',
  country: state.user.country || '',
  careerPath: state.user.careerPath
})

const mapDispatchToProps = (dispatch) => ({
  callSetGraduationYear: (graduationYear) => {
    dispatch(setGraduationYear(graduationYear))
    dispatch(save())
  },
  callSetCountry: (country) => {
    dispatch(setCountry(country))
    dispatch(save())
  },
  callClearMapper: () => {
    dispatch(generateUids(true))
    dispatch(clearMapper())
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AboutYou)
)
