import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Card, Button } from 'hult-component-library'
import FontAwesome from '../components/font-awesome'
import Logo from '../assets/images/logo.svg'
import StoryblokClient from 'storyblok-js-client'

import '../assets/pages.scss'

const Storyblok = new StoryblokClient({
  accessToken: process.env.REACT_APP_SB_TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory'
  }
})

class Landing extends Component {
  state = { articles: [] }

  componentDidMount() {
    this.getArticlesFromStoryblok()
  }

  getArticlesFromStoryblok = () => {
    Storyblok.get('cdn/stories', {
      starts_with: 'careermapper/articles/'
    })
      .then(response => {
        this.handleReportData(response)
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          this.getArticlesFromStoryblok()
        } else console.error(error)
      })
  }

  handleReportData = response => {
    const { stories } = response.data
    if (stories) {
      const articles = stories
      this.setState({
        articles
      })
    }
  }

  render = () => (
    <>
      <div className='pages'>
        <div className='g--spacer--large' />
        <div className='pages__center'>
          <img className='pages__logo' src={Logo} alt={Logo} />
          <p className='g--font-body--light'>
            Think proactively about your career.
          </p>
        </div>
        <hr />
        <p className='pages__center g--font-h4--light'>Which one are you?</p>
        <div className='row row--gutters-small'>
          <div className='row__column row__column--33 row__column--tablet-100'>
            <Link to='/career-finder/about-you'>
              <Card className='pages__option'>
                <div className='pages__option-details'>
                  <FontAwesome
                    icon='backpack'
                    type='fal'
                    className='pages__option-icon'
                  />
                  <p className='pages__option-copy g--font-body-bold'>
                    High school student
                  </p>
                </div>
                <div className='hide--tablet'>
                  <Button
                    className='pages__option-btn button--cta button--wide'
                    label='Get started'
                    icon='arrow-right'
                  />
                </div>
                <div className='show--tablet hide'>
                  <Button
                    className='pages__option-btn button--primary button--icon-only'
                    icon='arrow-right'
                  />
                </div>
              </Card>
            </Link>
          </div>

          <div className='row__column row__column--33 row__column--tablet-100'>
            <Link to='/career-mapper/about-you'>
              <Card className='pages__option'>
                <div className='pages__option-details'>
                  <FontAwesome
                    icon='graduation-cap'
                    type='fal'
                    className='pages__option-icon'
                  />
                  <p className='pages__option-copy g--font-body-bold'>
                    University student
                  </p>
                </div>
                <div className='hide--tablet'>
                  <Button
                    className='pages__option-btn button--cta button--wide'
                    label='Get started'
                    icon='arrow-right'
                  />
                </div>
                <div className='show--tablet hide'>
                  <Button
                    className='pages__option-btn button--primary button--icon-only'
                    icon='arrow-right'
                  />
                </div>
              </Card>
            </Link>
          </div>

          <div className='row__column row__column--33 row__column--tablet-100'>
            <Link to='/career-mapper/about-you'>
              <Card className='pages__option'>
                <div className='pages__option-details'>
                  <FontAwesome
                    icon='user-tie'
                    type='fal'
                    className='pages__option-icon'
                  />
                  <p className='pages__option-copy g--font-body-bold'>
                    Professional
                  </p>
                </div>
                <div className='hide--tablet'>
                  <Button
                    className='pages__option-btn button--cta button--wide'
                    label='Get started'
                    icon='arrow-right'
                  />
                </div>
                <div className='show--tablet hide'>
                  <Button
                    className='pages__option-btn button--primary button--icon-only'
                    icon='arrow-right'
                  />
                </div>
              </Card>
            </Link>
          </div>
        </div>
        {this.state.articles.length > 0 && (
          <>
            <hr />
            <p className='pages__center g--font-h4--light'>
              What people are saying...
            </p>
            <div className='pages__masonary'>
              {this.state.articles.map(article => (
                <div
                  key={article.content.headline}
                  className='pages__masonary-item'
                >
                  <Card>
                    <p className='g--font-body g--margin-none pages__article-copy'>
                      {article.content.body}
                    </p>
                    <p className='g--font-body-bold pages__article-author'>
                      {article.content.publisher}
                    </p>
                    <p className='g--font-body g--margin-none'>
                      {article.content.headline}
                    </p>
                  </Card>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Landing
