import React from 'react'
import PropTypes from 'prop-types'

import RoleOption from '../role-option'

const RoleQuestion = ({
  title,
  options,
  onSelected,
  allowSearch,
  children
}) => {
  console.log({
    title,
    options,
    onSelected,
    allowSearch,
    children
  })
  return (
    <div className="role-question">

      <p className="g--font-h4--light">{title}</p>

      {options.map(option =>
        typeof option === 'string' ? (
          <RoleOption
            key={option}
            title={option}
            onClick={() => {
              // onSelected(option)
            }}
          />
        ) : (
          <RoleOption
            key={option.id}
            title={option.name}
            salary={option.salary}
            demand={option.demand}
            onClick={() => {
              if (option.salary && option.demand)
                onSelected(option.name)
            }}
          />
        )
      )}
      {allowSearch && <>{children}</>}
    </div>
  )
}

RoleQuestion.defaultProps = {
  title: 'What do you want to do next?',
  options: [],
  allowSearch: false
}

RoleQuestion.propTypes = {
  title: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    ),
    PropTypes.arrayOf(PropTypes.string)
  ]),
  onSelected: PropTypes.func.isRequired,
  allowSearch: PropTypes.bool
}

export default RoleQuestion
