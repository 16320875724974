import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import JobSearch from '../jobSearchInput'

import RoleQuestion from '../../../components/role-question'

import {
  getJobDetails,
  getNextJobsDetailsList,
  getJobsDetailsList
} from '../../../actions'

import { careerGoalRoles, studentRole } from '../../../utils/config'
import { calculateSalaryIncrease } from '../../../utils/methods'

class RoleQuestionWrapper extends Component {
  componentDidMount() {
    if (this.props.jobTitle) this.props.callGetJobDetails(this.props.jobTitle, this.props.jobId)
  }

  getJobOptions = isLongTerm => {
    const { jobTitle, currentRole, jobDetails } = this.props
    const options = []

    console.log({ jobDetails })

    if (jobTitle && jobDetails[jobTitle] && jobDetails[jobTitle].result && jobDetails[jobTitle].result.advancementOccupations) {
      let displayJobs = _.flatMap(jobDetails[jobTitle].result.advancementOccupations, 'name')



      if (isLongTerm) {
        displayJobs = _.concat(_.slice(displayJobs, 0, 3), _.flatMap(careerGoalRoles, 'name'))
      }

      _.each(displayJobs, jobName => {
        const job = { name: jobName }
        if (jobDetails[job.name] && jobDetails[job.name].result) {
          job.id = jobDetails[job.name].result.id
          job.demand = jobDetails[job.name].result.demand.level
          if (currentRole === 'Student') {
            job.salary = calculateSalaryIncrease(
              studentRole.salary,
              jobDetails[job.name].result.salary.mean
            )
          } else if (jobDetails[currentRole] && jobDetails[currentRole].result)
            job.salary = calculateSalaryIncrease(
              jobDetails[currentRole].result.salary.mean,
              jobDetails[job.name].result.salary.mean
            )
        }
        options.push(job)
      })
    }

    return options
  }

  getStudentJobOptions = () => {
    const { jobDetails } = this.props
    const options = []

    _.each(studentRole.nextRoles, option => {
      const job = { name: option }
      if (jobDetails[job.name] && jobDetails[job.name].result) {
        job.id = jobDetails[job.name].result.id
        job.demand = jobDetails[job.name].result.demand.level
        job.salary = calculateSalaryIncrease(
          studentRole.salary,
          jobDetails[job.name].result.salary.mean
        )
      }
      options.push(job)
    })

    console.log({ options })

    return options
  }

  isLoading = () => {
    const { jobTitle, jobDetails } = this.props
    if (
      jobTitle === 'Student' ||
      (jobTitle && jobDetails[jobTitle] && !jobDetails[jobTitle].isFetching)
    )
      return false

    return true
  }

  render() {
    const { pos, jobTitle } = this.props

    if (this.isLoading()) return null

    if (jobTitle === 'Student')
      return (
        <RoleQuestion
          title={this.props.title}
          options={this.getStudentJobOptions()}
          onSelected={this.props.onSelected}
          allowSearch={true}
        ></RoleQuestion>
      )

    const jobOptions = this.getJobOptions(pos === 1)
    if (pos === 0 && !jobOptions.length)
      return (
        <>
          <p className='g--font-h4--light'>You've made it!</p>
          <p className='g--font-body--light'>
            Your current role is high enough that we can't find common next step
            roles. You can use the pencil icon to input a more junior role and
            explore the tool.
          </p>
        </>
      )

    if (
      pos === 1 &&
      !jobOptions.length &&
      _.indexOf(_.flatMap(careerGoalRoles, 'name'), jobTitle) === -1
    )
      return (
        <RoleQuestion
          title={this.props.title}
          options={careerGoalRoles}
          onSelected={this.props.onSelected}
          allowSearch={true}
        >
          <JobSearch
            id='job-search'
            label='Search for any other role you want here'
            placeholder='Search for specific role...'
            onSelected={this.props.onSelected}
          />
        </RoleQuestion>
      )

    return (
      <RoleQuestion
        title={this.props.title}
        options={jobOptions}
        onSelected={this.props.onSelected}
        allowSearch={true}
      >
        <hr />
        <p className='g--font-body-bold--light'>
          Search for any other role you want here
        </p>
        <JobSearch
          id='job-search'
          placeholder='Search for specific role...'
          onSelected={this.props.onSelected}
        />
      </RoleQuestion>
    )
  }
}

RoleQuestionWrapper.defaultProps = {
  title: 'What would you like to do next?'
}

RoleQuestionWrapper.propTypes = {
  pos: PropTypes.number.isRequired,
  title: PropTypes.string,
  jobTitle: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  onSelected: PropTypes.func.isRequired,
  jobDetails: PropTypes.objectOf(
    PropTypes.shape({
      result: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        salary: PropTypes.object,
        demand: PropTypes.object
      })
    })
  ).isRequired
}

const mapStateToProps = state => ({
  currentRole: state.user.currentRole,
  jobDetails: state.api.jobDetails || {}
})

const mapDispatchToProps = dispatch => ({
  callGetJobDetails: (jobTitle, jobId) => {
    if (jobTitle === 'Student')
      dispatch(getJobsDetailsList(studentRole.nextRoles))
    else
      dispatch(getJobDetails(jobTitle, jobId))
        .then(() => {
          dispatch(getNextJobsDetailsList(jobTitle, jobId))
          dispatch(getJobsDetailsList(careerGoalRoles))
        })
        .catch(e => {
          console.error({ e })
        })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(RoleQuestionWrapper)
