import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Medal,
  Message,
  Button,
  Card,
  FontAwesome
} from 'hult-component-library'

function truncateDescription(description) {
  return description
    .split(' ')
    .splice(0, 20)
    .join(' ')
}

class Discipline extends Component {
  render() {
    return (
      <>
        <Card className={`career-match ${this.props.className}`}>
          <header className='career-match__header'>
            <div className='career-match__header-left'>
              <Medal
                className='career-match__medal'
                position={this.props.position}
              />
              <p className='career-match__title g--font-h4 g--margin-none'>
                {this.props.title}
              </p>
            </div>
            <span className='career-match__match-rating g--margin-none'>
              {this.props.matchRating}% career match
            </span>
          </header>
          <hr />
          {this.props.description && (
            <div>
              <p className='g--margin-none career-match__description'>
                {truncateDescription(this.props.description)}...
                {this.props.details && (
                  <a
                    href={`${this.props.hrefDetails}${encodeURIComponent(
                      this.props.details
                    )}`}
                  >
                    <b className='career-match__read-more'> continue reading</b>
                  </a>
                )}
              </p>
              <hr />
            </div>
          )}
          <Message
            avatar={this.props.avatar}
            message={`Studying ${this.props.title.toLowerCase()} will equip you with ${
              this.props.allStrengths
            } transferable skills for the future`}
          />
          <hr />
          {this.props.startSalary && (
            <div className={`statistic statistic--positive`}>
              <FontAwesome className='statistic__icon' icon='money-bill-alt' />
              <p className='statistic__copy g--margin-none g--font-body-bold'>
                {this.props.startSalary} potential starting salary
              </p>
            </div>
          )}
          {this.props.futureSalary && (
            <div className={`statistic statistic--positive`}>
              <FontAwesome className='statistic__icon' icon='sack-dollar' />
              <p className='statistic__copy g--margin-none g--font-body-bold'>
                {this.props.futureSalary} potential salary within 8-10 years
              </p>
            </div>
          )}
          {this.props.strengths !== 0 && (
            <div className={`statistic statistic--strengths`}>
              <FontAwesome className='statistic__icon' icon='brain' />
              <p className='statistic__copy g--margin-none g--font-body-bold'>
                {`You already have ${this.props.strengths} of the strengths needed
                to succeed in this field`}
              </p>
            </div>
          )}

          <hr />
          {this.props.details && (
            <Button
              className='button--wide'
              Wrapper={'a'}
              wrapperProps={{
                href: `${this.props.hrefDetails}${encodeURIComponent(
                  this.props.details
                )}`
              }}
              label={`Read more ${this.props.title.toLowerCase()} stats`}
              icon='arrow-right'
            />
          )}
        </Card>
      </>
    )
  }
}

Discipline.defaultProps = {
  className: '',
  matchRating: 0,
  description: '',
  details: '',
  avatar: 'smile',
  strengths: 0,
  allStrengths: 0,
  startSalary: '',
  futureSalary: '',
  hrefDetails: '/career-finder/discipline/'
}

Discipline.propTypes = {
  className: PropTypes.string,
  position: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  matchRating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  description: PropTypes.string,
  details: PropTypes.string,
  avatar: PropTypes.string,
  strengths: PropTypes.number,
  allStrengths: PropTypes.number,
  startSalary: PropTypes.string,
  futureSalary: PropTypes.string,
  hrefDetails: PropTypes.string
}

export default Discipline
