import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import './role-detail.scss'

import { Button, Card, Statistic } from 'hult-component-library'
import FontAwesome from '../font-awesome'
import SkillGroup from '../skill-group'

import { skillGroups } from '../../utils/config'

const RoleDetail = ({
  className,
  level,
  title,
  salary,
  demand,
  exp,
  description,
  skills,
  onSkillSelect,
  onNoSkillsSelect,
  selectedSkills
}) => (
  <>
    {level && <p className='g--font-h4--light'>{level}</p>}
    <Card>
      <p className='g--margin-none g--font-h3'>{title}</p>
      <hr />
      {salary && (
        <div
          className={`statistic ${
            Math.round(salary) < 0
              ? 'statistic--negative'
              : 'statistic--positive'
          }`}
        >
          <div className='statistic__icon'>
            <FontAwesome icon='dollar-sign' />
          </div>
          <p className='statistic__copy g--margin-none g--font-body-bold'>
            {Math.round(salary) < 0 ? '' : '+'}
            {Math.round(salary)}%
          </p>
        </div>
      )}
      {level !== 'Ultimate goal' && <Statistic demand={demand} />}
      {exp && <Statistic minExperience={exp} />}
      {description && (
        <>
          <hr />
          <p className='g--margin-none g--font-body'>{description}</p>
        </>
      )}
      <hr />
      <p className='g--margin-none g--font-h4'>
        Select the skills you already have for this role
      </p>
      {_.map(skills, (skillsList, groupName) =>
        skillsList.length > 0 ? (
          <div key={groupName}>
            <hr />
            <SkillGroup
              isDisabled={false}
              title={`${_.capitalize(groupName)} skills`}
              description={skillGroups[groupName]}
              skills={_.flatMap(skillsList, s => s.name)}
              onSkillSelect={onSkillSelect}
              selectedSkills={selectedSkills}
            />
          </div>
        ) : null
      )}
      {selectedSkills.length === 0 && (
        <>
          <hr />
          <Button
            // variant='cta'
            Wrapper={'button'}
            wrapperProps={{
              onClick: () => {
                onNoSkillsSelect()
              }
            }}
            label="I don't have any of these skills yet"
            icon='arrow-right'
          />
        </>
      )}
    </Card>
  </>
)

RoleDetail.defaultProps = {
  level: ''
}

RoleDetail.propTypes = {
  className: PropTypes.string,
  level: PropTypes.string,
  title: PropTypes.string.isRequired,
  salary: PropTypes.string.isRequired,
  demand: PropTypes.string.isRequired,
  exp: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  skills: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string
      })
    )
  ).isRequired,
  onSkillSelect: PropTypes.func.isRequired,
  onNoSkillsSelect: PropTypes.func.isRequired
}

export default RoleDetail
