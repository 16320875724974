import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Role from '../../../components/role'

import { getJobDetails } from '../../../actions'

import { parseSkillGroups } from '../../../utils/parseBGSkills'
import { studentRole } from '../../../utils/config'

function calculateSalaryIncrease(original, next) {
  if (next > original) {
    const increase = next - original
    return parseFloat((increase / original) * 100).toFixed(1)
  } else {
    const decrease = original - next
    return parseFloat(-((decrease / original) * 100)).toFixed(1)
  }
}

class RoleWrapper extends Component {
  componentDidMount() {
    if (this.props.jobTitle) this.props.callGetJobDetails(this.props.jobTitle)
  }

  getJobDetails = jobTitle => {
    const { jobsDetails } = this.props
    return jobTitle && jobsDetails[jobTitle] && jobsDetails[jobTitle].result
      ? jobsDetails[jobTitle].result
      : null
  }

  render() {
    const { jobTitle, currentJobTitle, selectedSkillsCount } = this.props
    const currentJobDetails =
      currentJobTitle === 'Student' ? null : this.getJobDetails(currentJobTitle)
    const jobDetails = this.getJobDetails(jobTitle)

    if (!(jobDetails && (currentJobDetails || currentJobTitle === 'Student')))
      return null

    return (
      <Role
        className={this.props.className}
        link={
          this.props.isPlan
            ? '#'
            : `/career-mapper/career-path/detail/${encodeURIComponent(
              jobDetails.name
            )}`
        }
        roleLevel={this.props.roleLevel}
        title={jobDetails.name}
        salary={calculateSalaryIncrease(
          currentJobTitle === 'Student'
            ? studentRole.salary
            : currentJobDetails.salary.mean,
          jobDetails.salary.mean
        )}
        demand={
          this.props.roleLevel !== 'Ultimate goal' && jobDetails.demand.level
        }
        skillsSelected={selectedSkillsCount}
        skillsTotal={
          this.props.isPlan
            ? null
            : _.flatMap(
              parseSkillGroups(jobDetails.skills),
              (skills, group) => {
                if (group !== 'other') return skills
                return []
              }
            ).length
        }
        isConfirmed={this.props.isConfirmed}
        changeRole={this.props.changeRole}
        isChangeable={this.props.isChangeable}
      />
    )
  }
}

RoleWrapper.defaultProps = {
  className: '',
  roleLevel: 'Next role',
  jobDetails: {},
  changeRole: () => { },
  selectedSkillsCount: 0,
  isConfirmed: true,
  isChangeable: true,
  isPlan: false
}

RoleWrapper.propTypes = {
  className: PropTypes.string,
  jobTitle: PropTypes.string.isRequired,
  currentJobTitle: PropTypes.string.isRequired,
  roleLevel: PropTypes.string,
  jobsDetails: PropTypes.objectOf(
    PropTypes.shape({
      result: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        salary: PropTypes.shape({ mean: PropTypes.number }),
        demand: PropTypes.shape({ level: PropTypes.string })
      })
    })
  ),
  changeRole: PropTypes.func,
  selectedSkillsCount: PropTypes.number,
  isConfirmed: PropTypes.bool,
  isChangeable: PropTypes.bool,
  isPlan: PropTypes.bool
}

const mapStateToProps = state => ({ jobsDetails: state.api.jobDetails || {} })

const mapDispatchToProps = dispatch => ({
  callGetJobDetails: jobTitle => dispatch(getJobDetails(jobTitle))
})

export default connect(mapStateToProps, mapDispatchToProps)(RoleWrapper)
