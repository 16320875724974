import React from 'react'
import PropTypes from 'prop-types'
import './progress-bar.scss'

const ProgressBar = ({ percentage, length }) => (
  <div className='progress-bar'>
    <div className='progress-bar__row row row--gutters-small'>
      <div className='row__column row__column--20 row__column--mobile-100'>
        <p className='g--font-body-bold--light g--margin-none'>Progress</p>
      </div>
      <div className='row__column row__column--80 row__column--mobile-100'>
        <div className='progress-bar__path'>
          <div
            className='progress-bar__path-indicator'
            style={{ width: `calc(${percentage}%)` }}
          ></div>
          <div className='progress-bar__milestone progress-bar__milestone--active'></div>
          {milestoneHandler(length, percentage)}
        </div>
      </div>
    </div>
  </div>
)

const milestoneHandler = (length, percentage) => {
  let i
  let milestoneArr = []

  for (i = 1; i < length; i++) {
    const percentageActive = (100 / (length - 1)) * i

    milestoneArr.push(
      <div
        key={i}
        className={`progress-bar__milestone ${
          percentage >= percentageActive && 'progress-bar__milestone--active'
        }`}
      ></div>
    )
  }

  return milestoneArr
}

ProgressBar.defaultProps = {
  percentage: 50
}

ProgressBar.propTypes = {
  percentage: PropTypes.number
}

export default ProgressBar
