import { RSAA } from 'redux-api-middleware'
import fetch from 'cross-fetch'
import moment from 'moment'

import * as ActionTypes from './types'

export const setLinkedInAuthCode = code => ({
  type: ActionTypes.SET_LINKEDIN_AUTH_CODE,
  code
})

const fetchLinkedInAuthToken = code => ({
  [RSAA]: {
    types: [
      ActionTypes.LINKEDIN_AUTH_TOKEN_REQUEST,
      {
        type: ActionTypes.LINKEDIN_AUTH_TOKEN_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => ({
            access_token: json.access_token,
            expires_at: moment()
              .add(json.expires_in, 'seconds')
              .toString()
          }))
      },
      ActionTypes.LINKEDIN_AUTH_TOKEN_FAILURE
    ],
    endpoint: `${process.env.REACT_APP_CAREER_LINKEDIN_API}/token`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${code}`
    },
    fetch: async (...args) => fetch(...args)
  }
})

export const verifyLinkedInToken = action => (dispatch, getState) => {
  const { authToken } = getState().linkedin
  const { authCode } = getState().linkedin

  if (authToken && authToken.isFetching) {
    return null
  }

  if (authToken && authToken.access_token) {
    const expireDate = moment.utc(authToken.expires_at)
    const currentDate = moment.utc()
    if (!currentDate.isSameOrAfter(expireDate, 'minute')) {
      if (action && typeof action === 'function') {
        return dispatch(action(getState().authToken.access_token))
      }
      return getState().authToken.access_token
    }
  }

  return dispatch(fetchLinkedInAuthToken(authCode)).then(() => {
    const { access_token } = getState().linkedin.authToken
    if (access_token && action && typeof action === 'function') {
      return dispatch(action(access_token))
    }
    return access_token ? access_token : 'BADTOKEN'
  })
}

const fetchLinkedInProfile = authToken => ({
  [RSAA]: {
    types: [
      ActionTypes.LINKEDIN_PROFILE_REQUEST,
      {
        type: ActionTypes.LINKEDIN_PROFILE_SUCCESS,
        payload: (action, state, res) => res.json()
      },
      ActionTypes.LINKEDIN_PROFILE_FAILURE
    ],
    endpoint: `${process.env.REACT_APP_CAREER_LINKEDIN_API}/me`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    fetch: async (...args) => fetch(...args)
  }
})

export const getLinkedInProfile = () => (dispatch, getState) => {
  const { profile } = getState().linkedin

  if (profile && profile.isFetching) {
    return Promise.resolve()
  }

  return dispatch(verifyLinkedInToken(token => fetchLinkedInProfile(token)))
}
