import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Card, Button } from 'hult-component-library'
import FontAwesome from '../font-awesome'

const Program = props => (
  <a rel='noopener noreferrer' target='_blank' href={props.link}>
    <Card className=''>
      <p className='g--margin-top-none g--font-h4'>{props.title}</p>
      <hr />
      <pre>{props.description}</pre>
      {props.skillPercentage !== 0 && (
        <>
          <hr />
          <div className='g--margin-top-none statistic statistic--strengths'>
            <div className='statistic__icon'>
              <FontAwesome icon='brain' />
            </div>
            <p className='statistic__copy g--margin-none g--font-body-bold'>
              {props.skillPercentage}% of the required skills covered
            </p>
          </div>
        </>
      )}
      <p>{_.join(props.skills, ', ')}</p>
      <hr />
      <Button
        className='button--tablet-wide'
        variant='cta'
        Wrapper={'div'}
        label='Start your journey'
        icon='arrow-right'
      />
    </Card>
  </a>
)

Program.defaultProps = {
  position: 0,
  totalPrograms: 0,
  skillPercentage: 0,
  title: '',
  description: '',
  skills: [],
  link: '#'
}

Program.propTypes = {
  position: PropTypes.number,
  totalPrograms: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  skills: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  skillPercentage: PropTypes.number
}

export default Program
