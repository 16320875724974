import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import TagManager from 'react-gtm-module'

import configureStore from './store'

import App from './containers/app'

import * as serviceWorker from './serviceWorker'

const { store, persistor } = configureStore()

TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_CODE })

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.register()
