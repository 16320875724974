export default [
  { code: 'AF', value: 'Afghanistan' },
  { code: 'AX', value: 'Aland Islands' },
  { code: 'AL', value: 'Albania' },
  { code: 'DZ', value: 'Algeria' },
  { code: 'AS', value: 'American Samoa' },
  { code: 'AD', value: 'Andorra' },
  { code: 'AO', value: 'Angola' },
  { code: 'AI', value: 'Anguilla' },
  { code: 'AQ', value: 'Antarctica' },
  { code: 'AG', value: 'Antigua and Barbuda' },
  { code: 'AR', value: 'Argentina' },
  { code: 'AM', value: 'Armenia' },
  { code: 'AW', value: 'Aruba' },
  { code: 'AU', value: 'Australia' },
  { code: 'AT', value: 'Austria' },
  { code: 'AZ', value: 'Azerbaijan' },
  { code: 'BS', value: 'Bahamas' },
  { code: 'BH', value: 'Bahrain' },
  { code: 'BD', value: 'Bangladesh' },
  { code: 'BB', value: 'Barbados' },
  { code: 'BY', value: 'Belarus' },
  { code: 'BE', value: 'Belgium' },
  { code: 'BZ', value: 'Belize' },
  { code: 'BJ', value: 'Benin' },
  { code: 'BM', value: 'Bermuda' },
  { code: 'BT', value: 'Bhutan' },
  { code: 'BO', value: 'Bolivia' },
  { code: 'BA', value: 'Bosnia and Herzegovina' },
  { code: 'BW', value: 'Botswana' },
  { code: 'BV', value: 'Bouvet Island' },
  {
    code: 'BR',
    value: 'Brazil',
    states: [
      'Acre',
      'Alagoas',
      'Amapa',
      'Amazonas',
      'Bahia',
      'Ceara',
      'Distrito Federal',
      'Espirito Santo',
      'Goias',
      'Maranhao',
      'Mato Grosso',
      'Mato Grosso do Sul',
      'Minas Gerais',
      'Para',
      'Paraiba',
      'Parana',
      'Pernambuco',
      'Piaui',
      'Rio de Janeiro',
      'Rio Grande do Norte',
      'Rio Grande do Sul',
      'Rondonia',
      'Roraima',
      'Santa Catarina',
      'Sao Paulo',
      'Sergipe',
      'Tocantins'
    ]
  },
  { code: 'IO', value: 'British Indian Ocean Territory' },
  { code: 'BN', value: 'Brunei Darussalam' },
  { code: 'BG', value: 'Bulgaria' },
  { code: 'BF', value: 'Burkina Faso' },
  { code: 'BI', value: 'Burundi' },
  { code: 'KH', value: 'Cambodia' },
  { code: 'CM', value: 'Cameroon' },
  {
    code: 'CA',
    value: 'Canada',
    states: [
      'Alberta',
      'British Columbia',
      'Halifax',
      'Labrador',
      'Manitoba',
      'New Brunswick',
      'Newfoundland and Labrador',
      'Northwest Territories',
      'Nova Scotia',
      'Nunavut',
      'Ontario',
      'Prince Edward Island',
      'Quebec',
      'Saskatchewan',
      'Yukon Territory'
    ]
  },
  { code: 'CV', value: 'Cape Verde' },
  { code: 'KY', value: 'Cayman Islands' },
  { code: 'CF', value: 'Central African Republic' },
  { code: 'TD', value: 'Chad' },
  { code: 'CL', value: 'Chile' },
  { code: 'CN', value: 'China' },
  { code: 'CX', value: 'Christmas Island' },
  { code: 'CC', value: 'Cocos (Keeling) Islands' },
  { code: 'CO', value: 'Colombia' },
  { code: 'KM', value: 'Comoros' },
  { code: 'CG', value: 'Congo' },
  { code: 'CK', value: 'Cook Islands' },
  { code: 'CR', value: 'Costa Rica' },
  { code: 'CI', value: 'Cote d Ivoire' },
  { code: 'HR', value: 'Croatia' },
  { code: 'CU', value: 'Cuba' },
  { code: 'CW', value: 'Curacao' },
  { code: 'CY', value: 'Cyprus' },
  { code: 'CZ', value: 'Czech Republic' },
  { code: 'CD', value: 'Democratic Republic of Congo' },
  { code: 'DK', value: 'Denmark' },
  { code: 'DJ', value: 'Djibouti' },
  { code: 'DM', value: 'Dominica' },
  { code: 'DO', value: 'Dominican Republic' },
  { code: 'EC', value: 'Ecuador' },
  { code: 'EG', value: 'Egypt' },
  { code: 'SV', value: 'El Salvador' },
  { code: 'GQ', value: 'Equatorial Guinea' },
  { code: 'ER', value: 'Eritrea' },
  { code: 'EE', value: 'Estonia' },
  { code: 'ET', value: 'Ethiopia' },
  { code: 'FK', value: 'Falkland Islands' },
  { code: 'FO', value: 'Faroe Islands' },
  { code: 'FJ', value: 'Fiji' },
  { code: 'FI', value: 'Finland' },
  { code: 'FR', value: 'France' },
  { code: 'GF', value: 'French Guiana' },
  { code: 'PF', value: 'French Polynesia' },
  { code: 'GA', value: 'Gabon' },
  { code: 'GM', value: 'Gambia' },
  { code: 'GE', value: 'Georgia' },
  {
    code: 'DE',
    value: 'Germany',
    states: [
      'Baden-Wurttemberg',
      'Bavaria',
      'Bayern',
      'Berlin',
      'Brandenburg',
      'Bremen',
      'Hamburg',
      'Hesse',
      'Hessen',
      'Lower Saxony',
      'Mecklenburg-Vorpommern',
      'Niedersachsen',
      'Nordrhein-Westfalen',
      'North Rhine-Westphalia',
      'Northrhine-Westphalia',
      'Rheinland-Pfalz',
      'Rhineland-Palatinate',
      'Saarland',
      'Sachsen',
      'Sachsen-Anhalt',
      'Saxony',
      'Saxony-Anhalt',
      'Schleswig-Holstein',
      'Thuringen',
      'Thüringen',
      'Thuringia'
    ]
  },
  { code: 'GH', value: 'Ghana' },
  { code: 'GI', value: 'Gibraltar' },
  { code: 'GR', value: 'Greece' },
  { code: 'GL', value: 'Greenland' },
  { code: 'GD', value: 'Grenada' },
  { code: 'GP', value: 'Guadeloupe' },
  { code: 'GU', value: 'Guam' },
  { code: 'GT', value: 'Guatemala' },
  { code: 'GG', value: 'Guernsey' },
  { code: 'GN', value: 'Guinea' },
  { code: 'GW', value: 'Guinea-Bissau' },
  { code: 'GY', value: 'Guyana' },
  { code: 'HT', value: 'Haiti' },
  { code: 'HM', value: 'Heard Island/Mcdonald Islands (AU)' },
  { code: 'HN', value: 'Honduras' },
  { code: 'HK', value: 'Hong Kong' },
  { code: 'HU', value: 'Hungary' },
  { code: 'IS', value: 'Iceland' },
  {
    code: 'IN',
    value: 'India',
    states: [
      'Andaman and Nicobar Islands State',
      'Andhra Pradesh',
      'Arunachal Pradesh',
      'Assam',
      'Bihar',
      'Chandigarh',
      'Chhattisgarh',
      'Dadra and Nagar Haveli',
      'Daman and Diu',
      'Delhi',
      'Goa',
      'Gujarat',
      'Haryana',
      'Himachal Pradesh',
      'Jammu and Kashmir',
      'Jharkhand',
      'Karnataka',
      'Kerala',
      'Lakshadweep',
      'Madhya Pradesh',
      'Maharashtra',
      'Manipur',
      'Meghalaya',
      'Mizoram',
      'Nagaland',
      'National Capital Territory of Delhi',
      'Odisha',
      'Pondicherry',
      'Puducherry',
      'Punjab',
      'Rajasthan',
      'Sikkim',
      'Sukhbaatar Duureg',
      'Tamil Nadu',
      'Telangana',
      'Tripura',
      'Uttar Pradesh',
      'Uttarakhand',
      'West Bengal'
    ]
  },
  { code: 'ID', value: 'Indonesia' },
  { code: 'IR', value: 'Iran' },
  { code: 'IQ', value: 'Iraq' },
  { code: 'IE', value: 'Ireland' },
  { code: 'IM', value: 'Isle Of Man' },
  { code: 'IL', value: 'Israel' },
  { code: 'IT', value: 'Italy' },
  { code: 'JM', value: 'Jamaica' },
  { code: 'JP', value: 'Japan' },
  { code: 'JE', value: 'Jersey' },
  { code: 'JO', value: 'Jordan' },
  { code: 'KZ', value: 'Kazakhstan' },
  { code: 'KE', value: 'Kenya' },
  { code: 'KI', value: 'Kiribati' },
  { code: 'KP', value: 'Korea (North)' },
  { code: 'KR', value: 'Korea (South)' },
  { code: 'XK', value: 'Kosovo' },
  { code: 'KW', value: 'Kuwait' },
  { code: 'KG', value: 'Kyrgyzstan' },
  { code: 'LA', value: 'Laos' },
  { code: 'LV', value: 'Latvia' },
  { code: 'LB', value: 'Lebanon' },
  { code: 'LS', value: 'Lesotho' },
  { code: 'LR', value: 'Liberia' },
  { code: 'LY', value: 'Libya' },
  { code: 'LI', value: 'Liechtenstein' },
  { code: 'LT', value: 'Lithuania' },
  { code: 'LU', value: 'Luxembourg' },
  { code: 'MO', value: 'Macau' },
  { code: 'MK', value: 'Macedonia' },
  { code: 'MG', value: 'Madagascar' },
  { code: 'MW', value: 'Malawi' },
  { code: 'MY', value: 'Malaysia' },
  { code: 'MV', value: 'Maldives' },
  { code: 'ML', value: 'Mali' },
  { code: 'MT', value: 'Malta' },
  { code: 'MH', value: 'Marshall Islands' },
  { code: 'MQ', value: 'Martinique' },
  { code: 'MR', value: 'Mauritania' },
  { code: 'MU', value: 'Mauritius' },
  { code: 'YT', value: 'Mayotte' },
  { code: 'MX', value: 'Mexico' },
  { code: 'FM', value: 'Micronesia' },
  { code: 'MD', value: 'Moldova' },
  { code: 'MC', value: 'Monaco' },
  { code: 'MN', value: 'Mongolia' },
  { code: 'ME', value: 'Montenegro' },
  { code: 'MS', value: 'Montserrat' },
  { code: 'MA', value: 'Morocco' },
  { code: 'MZ', value: 'Mozambique' },
  { code: 'MM', value: 'Myanmar' },
  { code: 'NA', value: 'Namibia' },
  { code: 'NR', value: 'Nauru' },
  { code: 'NP', value: 'Nepal' },
  { code: 'NL', value: 'Netherlands' },
  { code: 'AN', value: 'Netherlands Antilles' },
  { code: 'NC', value: 'New Caledonia' },
  { code: 'NZ', value: 'New Zealand' },
  { code: 'NI', value: 'Nicaragua' },
  { code: 'NE', value: 'Niger' },
  { code: 'NG', value: 'Nigeria' },
  { code: 'NU', value: 'Niue' },
  { code: 'NF', value: 'Norfolk Island (AU)' },
  { code: 'MP', value: 'Northern Mariana Islands' },
  { code: 'NO', value: 'Norway' },
  { code: 'OM', value: 'Oman' },
  { code: 'PK', value: 'Pakistan' },
  { code: 'PW', value: 'Palau' },
  { code: 'PS', value: 'Palestine' },
  { code: 'PA', value: 'Panama' },
  { code: 'PG', value: 'Papua New Guinea' },
  { code: 'PY', value: 'Paraguay' },
  { code: 'PE', value: 'Peru' },
  { code: 'PH', value: 'Philippines' },
  { code: 'PN', value: 'Pitcairn' },
  { code: 'PL', value: 'Poland' },
  { code: 'PT', value: 'Portugal' },
  { code: 'PR', value: 'Puerto Rico' },
  { code: 'QA', value: 'Qatar' },
  { code: 'RE', value: 'Reunion' },
  { code: 'RO', value: 'Romania' },
  { code: 'RU', value: 'Russia' },
  { code: 'RW', value: 'Rwanda' },
  { code: 'KN', value: 'Saint Kitts and Nevis' },
  { code: 'LC', value: 'Saint Lucia' },
  { code: 'VC', value: 'Saint Vin. and the Grenadines' },
  { code: 'WS', value: 'Samoa' },
  { code: 'SM', value: 'San Marino' },
  { code: 'ST', value: 'Sao Tome and Principe' },
  { code: 'SA', value: 'Saudi Arabia' },
  { code: 'SN', value: 'Senegal' },
  { code: 'RS', value: 'Serbia' },
  { code: 'SC', value: 'Seychelles' },
  { code: 'SL', value: 'Sierra Leone' },
  { code: 'SG', value: 'Singapore' },
  { code: 'MF', value: 'Sint Marteen' },
  { code: 'SK', value: 'Slovakia' },
  { code: 'SI', value: 'Slovenia' },
  { code: 'SB', value: 'Solomon Islands' },
  { code: 'SO', value: 'Somalia' },
  { code: 'ZA', value: 'South Africa' },
  { code: 'ES', value: 'Spain' },
  { code: 'LK', value: 'Sri Lanka' },
  { code: 'SD', value: 'Sudan' },
  { code: 'SR', value: 'Suriname' },
  { code: 'SZ', value: 'Swaziland' },
  { code: 'SE', value: 'Sweden' },
  { code: 'CH', value: 'Switzerland' },
  { code: 'SY', value: 'Syria' },
  { code: 'TW', value: 'Taiwan' },
  { code: 'TJ', value: 'Tajikistan' },
  { code: 'TZ', value: 'Tanzania' },
  { code: 'TH', value: 'Thailand' },
  { code: 'TL', value: 'Timor-Leste' },
  { code: 'TG', value: 'Togo' },
  { code: 'TK', value: 'Tokelau (NZ)' },
  { code: 'TO', value: 'Tonga' },
  { code: 'TT', value: 'Trinidad and Tobago' },
  { code: 'TN', value: 'Tunisia' },
  { code: 'TR', value: 'Turkey' },
  { code: 'TM', value: 'Turkmenistan' },
  { code: 'TC', value: 'Turks and Caicos Islands' },
  { code: 'TV', value: 'Tuvalu' },
  { code: 'UM', value: 'US Minor Outlying Islands' },
  { code: 'UG', value: 'Uganda' },
  { code: 'UA', value: 'Ukraine' },
  { code: 'AE', value: 'United Arab Emirates' },
  { code: 'GB', value: 'United Kingdom' },
  {
    code: 'US',
    value: 'United States',
    states: [
      'Alabama',
      'Alaska',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Florida',
      'Georgia',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
      'Armed Forces (the) Americas',
      'Armed Forces Europe',
      'Armed Forces Pacific',
      'Army Post Office (U.S. Army and U.S. Air Force)',
      'Fleet Post Office (U.S. Navy and U.S. Marine Corps)'
    ]
  },
  { code: 'UY', value: 'Uruguay' },
  { code: 'UZ', value: 'Uzbekistan' },
  { code: 'VU', value: 'Vanuatu' },
  { code: 'VA', value: 'Vatican City' },
  { code: 'VE', value: 'Venezuela' },
  { code: 'VN', value: 'Vietnam' },
  { code: 'VI', value: 'Virgin Islands (USA)' },
  { code: 'VG', value: 'Virgin Islands(British)' },
  { code: 'YE', value: 'Yemen' },
  { code: 'ZM', value: 'Zambia' },
  { code: 'ZW', value: 'Zimbabwe' }
]
