import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'

import * as FlexWebChat from '@twilio/flex-webchat-ui'

import webChatConfig from '../../utils/webChatConf'

class WebChat extends Component {
  state = {}

  constructor(props) {
    super(props)
    const { user } = props

    FlexWebChat.Manager.create({
      ...webChatConfig,
      context: {
        ...user,
        friendlyName: `${user.firstName} ${user.lastName}`,
        reportLink: `${window.location.origin}/report/${user.uuid}/${user.suid}`,
        chatLocation: this.props.chatLocation
      }
    })
      .then(manager => {
        this.setPredefinedMessages(manager)
        this.setState({ manager }, () => {
          this.setChatActions()
        })
      })
      .catch(error => this.setState({ error }))
  }

  setPredefinedMessages = manager => {
    manager.strings.PredefinedChatMessageBody =
      'Hi there! Let me know what questions you want to discuss about your career options.'
  }

  setChatActions = () => {
    FlexWebChat.Actions.on('afterToggleChatVisibility', () => {
      ReactGA.event({
        category: 'Webchat',
        action: 'Chat Opened',
        label: 'BBA'
      }, ['tracker1', 'tracker2'])
    })
    FlexWebChat.Actions.on('afterSendMessage', () => {
      ReactGA.event({
        category: 'Webchat',
        action: 'Message Sent',
        label: 'BBA'
      }, ['tracker1', 'tracker2'])
    })
  }

  render = () => {
    const { manager, error } = this.state

    if (manager) {
      return (
        <FlexWebChat.ContextProvider manager={manager}>
          <FlexWebChat.RootContainer />
        </FlexWebChat.ContextProvider>
      )
    }

    if (error) {
      console.error('Failed to initialize Flex Web Chat', error)
    }

    return null
  }
}

WebChat.propTypes = {
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.user || {}
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WebChat)
