import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import { Button, Footer } from 'hult-component-library'
import Role from '../../containers/career-mapper/role'
import RoleQuestion from '../../containers/career-mapper/role-question'
import Summary from '../../components/summary'
import ProgressBar from '../../components/progress-bar'

import {
  addUserCareerPathRole,
  clearUserCareerPathRole,
  clearUserData,
  generateUids,
  save,
  clearFinder
} from '../../actions'

import { careerPathQuestions, careerPathLabels } from '../../utils/config'

class CareerPath extends Component {
  state = {
    careerPath: this.props.careerPath || []
  }

  constructor(props) {
    super(props)

    if (!props.currentRole) props.history.replace('/career-mapper/about-you')
  }

  componentDidMount() {
    if (this.props.finderDetails) this.props.callClearFinder()
  }

  openJobDetails = (jobTitle) => {
    const { history } = this.props
    history.push(
      `/career-mapper/career-path/detail/${encodeURIComponent(jobTitle)}`
    )
  }

  onRoleSelect = (optionName) => {
    console.log({ optionName })
    const careerPath = JSON.parse(JSON.stringify(this.state.careerPath || []))
    careerPath.push({ name: optionName, skills: [] })

    this.setState({ careerPath }, () => {
      this.props.callAddUserCareerPathRole(optionName)
      this.openJobDetails(optionName)
    })
  }

  onChangeRole = (pos) => {
    let careerPath = JSON.parse(JSON.stringify(this.state.careerPath || []))
    careerPath = _.dropRight(careerPath, careerPath.length - pos)

    this.setState({ careerPath }, () => {
      this.props.callClearUserCareerPathRole(pos)
    })
  }

  drawNextRole() {
    const lastRole = _.last(this.state.careerPath)
    const role = lastRole ? lastRole.name : this.props.currentRole
    const roleId = this.props.jobDetails[role]?.result?.id

    return (
      <RoleQuestion
        pos={this.state.careerPath.length}
        title={careerPathQuestions[this.state.careerPath.length]}
        jobTitle={role}
        jobId={roleId}
        onSelected={this.onRoleSelect}
      />
    )
  }

  showFooter() {
    const { careerPath } = this.state
    const { currentRole, jobOptions } = this.props

    const lastRole = _.last(careerPath)
    const role = lastRole ? lastRole.name : currentRole
    const options =
      role && jobOptions[role] && jobOptions[role].result
        ? jobOptions[role].result
        : []

    const completeCareerPath = careerPath.length === 2
    return careerPath.length > 0 && (completeCareerPath || options.length === 0)
  }

  percentageHandler = () => {
    const { careerPath } = this.props

    switch (true) {
      case careerPath.length === 1:
        return 60
      case careerPath.length === 2:
        return 80
      default:
        return 40
    }
  }

  render = () => (
    <>
      <ProgressBar percentage={this.percentageHandler()} length={6} />
      <hr />
      <div
        className={`content ${this.props.currentRole &&
          this.showFooter() &&
          'content--footer-visible'
          }`}
      >
        <div className='g--spacer--small hide show--tablet' />
        {this.props.currentRole && this.showFooter() && (
          <div className='hide--tablet'>
            <Button
              className='button--wide'
              variant='cta'
              Wrapper={'a'}
              wrapperProps={{
                href: this.props.registered
                  ? '/career-mapper/report'
                  : '/career-mapper/register'
              }}
              label='Your career plan'
              icon='arrow-right'
            />
            <hr />
          </div>
        )}
        <Summary
          className='summary--path'
          firstName={this.props.firstName}
          lastName={this.props.lastName}
          currentRole={this.props.currentRole}
          education={this.props.education}
          experience={this.props.experience}
          action={() => {
            this.props.callClearUserData()
            this.props.history.replace('/career-mapper/about-you')
          }}
          isEditable
        />
        {this.props.currentRole && (
          <>
            {this.state.careerPath.map((role, pos) => (
              <>
                <hr />
                <div key={role.name}>
                  <Role
                    key={role.name}
                    jobTitle={role.name}
                    roleLevel={careerPathLabels[pos]}
                    currentJobTitle={this.props.currentRole}
                    changeRole={() => this.onChangeRole(pos)}
                    selectedSkillsCount={role.skills.length}
                  />
                </div>
              </>
            ))}
            {this.state.careerPath.length < 2 && <hr />}
            {this.state.careerPath.length < 2 && this.drawNextRole()}
          </>
        )}
      </div>
      {this.props.currentRole && this.showFooter() && (
        <Footer>
          <Button
            className='button--wide'
            variant='cta'
            Wrapper={'a'}
            wrapperProps={{
              href: this.props.registered
                ? '/career-mapper/report'
                : '/career-mapper/register'
            }}
            label='Your career plan'
            icon='arrow-right'
          />
        </Footer>
      )}
    </>
  )
}

CareerPath.defaultProps = {
  currentRole: null
}

CareerPath.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  currentRole: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  education: PropTypes.string.isRequired,
  experience: PropTypes.string.isRequired,
  careerPath: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      skills: PropTypes.arrayOf(PropTypes.string)
    })
  ).isRequired,
  callAddUserCareerPathRole: PropTypes.func.isRequired,
  callClearUserData: PropTypes.func.isRequired,
  registered: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  currentRole: state.user.currentRole,
  jobDetails: state.api.jobDetails,
  firstName: state.user.firstName || '',
  lastName: state.user.lastName || '',
  education: state.user.education,
  experience: state.user.experience,
  careerPath: state.user.careerPath || [],
  jobOptions: state.api.next || {},
  registered: !!(
    state.user.firstName &&
    state.user.lastName &&
    state.user.email &&
    state.user.country
  ),
  finderDetails: state.user.reportDetails
})

const mapDispatchToProps = (dispatch) => ({
  callAddUserCareerPathRole: (role) => {
    dispatch(addUserCareerPathRole(role))
    dispatch(save())
  },
  callClearUserCareerPathRole: (pos) => {
    dispatch(clearUserCareerPathRole(pos))
    dispatch(save())
  },
  callClearUserData: () => {
    dispatch(clearUserData())
    dispatch(generateUids(true))
  },
  callClearFinder: () => {
    dispatch(generateUids(true))
    dispatch(clearFinder())
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CareerPath)
)
