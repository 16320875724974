import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  split,
  union,
  forEach,
  trim,
  flatMap,
  intersection,
  startsWith
} from 'lodash'

import { Medal, Card, Statistic } from 'hult-component-library'
import FontAwesome from '../../components/font-awesome'
import SkillGroup from '../../components/skill-group'
import HultIntro from '../../components/hult-intro'
import WebChat from '../../containers/web-chat'

import { getJobsDetailsList } from '../../actions'
import { parseSkillGroups } from '../../utils/parseBGSkills'

import '../../assets/twilio.scss'

class Detail extends Component {
  state = {
    discipline: decodeURIComponent(this.props.match.params.discipline),
    graduateArray: [],
    futureArray: []
  }
  constructor(props) {
    super(props)

    if (startsWith(props.location.pathname, '/report'))
      this.state.isReport = true
  }

  componentDidMount() {
    const { discipline } = this.state
    const { report, reportDetails, callGetJobsDetailsList } = this.props
    let disciplineDetails

    if (this.state.isReport) {
      disciplineDetails = reportDetails[discipline]
    } else {
      disciplineDetails = report[discipline]
    }
    const { graduate_roles, future_roles } = disciplineDetails
    const graduateArray = split(graduate_roles, '\n')
    const futureArray = split(future_roles, '\n')

    forEach(graduateArray, (val, idx) => {
      graduateArray[idx] = trim(val)
    })

    forEach(futureArray, (val, idx) => {
      futureArray[idx] = trim(val)
    })

    const rolesArray = union(graduateArray, futureArray)

    callGetJobsDetailsList(rolesArray)
    this.setState({ graduateArray, futureArray })
  }

  getPosition() {
    const { discipline } = this.state
    switch (discipline) {
      case 'first_discipline':
        return '1'
      case 'second_discipline':
        return '2'
      case 'third_discipline':
        return '3'
      default:
        return '0'
    }
  }

  getSuccessStrengths = roleSkills => {
    const { selectedSkills } = this.props
    const strengthsCount = intersection(
      selectedSkills,
      flatMap(parseSkillGroups(roleSkills), g => flatMap(g, 'name'))
    ).length

    return strengthsCount === 0 ? (
      <></>
    ) : (
      <div className={`statistic statistic--strengths`}>
        <FontAwesome className='statistic__icon' icon='brain' />
        <p className='statistic__copy g--margin-none g--font-body-bold'>
          {`You have ${strengthsCount} of the strengths to succeed`}
        </p>
      </div>
    )
  }

  render() {
    const { discipline, graduateArray, futureArray, isReport } = this.state
    const { report, roleDetails, reportDetails } = this.props
    let disciplineDetails, disciplinePercentage

    if (isReport) {
      disciplineDetails = reportDetails[discipline]
      disciplinePercentage = reportDetails[`${discipline}_percentage`]
    } else {
      disciplineDetails = report[discipline]
      disciplinePercentage = report[`${discipline}_percentage`]
    }

    return (
      <>
        <Card className='match-details'>
          <header className='match-details__header'>
            <div className='match-details__header-left'>
              <Medal
                className='match-details__medal'
                position={this.getPosition()}
              />
              <h3 className='match-details__title g--margin-none'>
                {disciplineDetails.title}
              </h3>
            </div>
            <span className='match-details__match-rating g--margin-none'>
              {disciplinePercentage || 0}% career match
            </span>
          </header>
          <hr />
          {disciplineDetails.description && (
            <div>
              <p className='g--margin-none match-details__description'>
                {disciplineDetails.description}
              </p>
              <hr />
            </div>
          )}
          {disciplineDetails.skills && (
            <div>
              <p className='g--font-h4'>Transferable skills</p>
              <p>
                These will prepare you for whatever your future career holds.
                Studying {disciplineDetails.title} equips you with...
              </p>
              <SkillGroup skills={disciplineDetails.skills} isDisabled />
              <hr />
            </div>
          )}
          {disciplineDetails.strengths && (
            <div>
              <p className='g--font-h4'>Personal strengths</p>
              <p>
                You can make the most of your talents in a well-chosen career.
                In {disciplineDetails.title}, you'll use...
              </p>
              <SkillGroup skills={disciplineDetails.strengths} isDisabled />
            </div>
          )}
        </Card>
        {disciplineDetails.skills && (
          <>
            {graduateArray.length > 0 && (
              <>
                <hr />
                <p className='g--font-h4--light'>Graduate roles</p>
                <div className='row row--snap'>
                  {graduateArray.map(
                    role =>
                      roleDetails[role] &&
                      roleDetails[role].result && (
                        <div
                          className='row__column'
                          key={roleDetails[role].result.id}
                        >
                          <Card className='match-details'>
                            <p className='g--font-h4 g--margin-none'>
                              {roleDetails[role].result.name}
                            </p>
                            <hr />
                            {roleDetails[role].result.salary.mean && (
                              <div className={`statistic statistic--positive`}>
                                <FontAwesome
                                  icon='dollar-sign'
                                  className='statistic__icon'
                                />
                                <p className='statistic__copy g--margin-none g--font-body-bold'>
                                  {Math.round(
                                    roleDetails[role].result.salary.mean / 1000
                                  )}
                                  K / year potential starting salary
                                </p>
                              </div>
                            )}
                            {roleDetails[role].result.demand && (
                              <Statistic
                                demand={roleDetails[role].result.demand.level}
                              />
                            )}
                            {roleDetails[role].result.skills &&
                              this.getSuccessStrengths(
                                roleDetails[role].result.skills
                              )}
                          </Card>
                        </div>
                      )
                  )}
                </div>
              </>
            )}
            {futureArray.length > 0 && (
              <>
                <hr />
                <p className='g--font-h4--light'>Future roles</p>
                <div className='row row--snap'>
                  {futureArray.map(
                    role =>
                      roleDetails[role] &&
                      roleDetails[role].result && (
                        <div
                          className='row__column'
                          key={roleDetails[role].result.id}
                        >
                          <Card className='match-details'>
                            <p className='g--font-h4 g--margin-none'>
                              {roleDetails[role].result.name}
                            </p>
                            <hr />
                            {roleDetails[role].result.salary.mean && (
                              <div className={`statistic statistic--positive`}>
                                <FontAwesome
                                  icon='dollar-sign'
                                  className='statistic__icon'
                                />
                                <p className='statistic__copy g--margin-none g--font-body-bold'>
                                  {Math.round(
                                    roleDetails[role].result.salary.mean / 1000
                                  )}
                                  K / year potential starting salary
                                </p>
                              </div>
                            )}
                            {roleDetails[role].result.demand && (
                              <Statistic
                                demand={roleDetails[role].result.demand.level}
                              />
                            )}
                            {roleDetails[role].result.skills &&
                              this.getSuccessStrengths(
                                roleDetails[role].result.skills
                              )}
                          </Card>
                        </div>
                      )
                  )}
                </div>
              </>
            )}
          </>
        )}

        <hr />

        <HultIntro />
        <WebChat />
      </>
    )
  }
}

Detail.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  callGetJobsDetailsList: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  report: state.user.reportDetails,
  roleDetails: state.api.jobDetails,
  selectedSkills: state.user.finderStrenghts,
  reportDetails: state.report && state.report.reportDetails
})

const mapDispatchToProps = dispatch => ({
  callGetJobsDetailsList: roleList => {
    dispatch(getJobsDetailsList(roleList))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Detail))
