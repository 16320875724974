import * as ActionTypes from '../actions/types'

export default (
  state = {
    isFetching: false
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.AUTH_TOKEN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        fetchingFrom: Date.now()
      })

    case ActionTypes.AUTH_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        token: action.payload
      })

    case ActionTypes.AUTH_TOKEN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    default:
      return state
  }
}
