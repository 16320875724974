import { combineReducers } from 'redux'

import * as ActionTypes from '../actions/types'

const jobTitles = (
  state = {
    isFetching: false
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.BG_JOB_TITLE_REQUEST:
      return {
        isFetching: true
      }
    case ActionTypes.BG_JOB_TITLE_SUCCESS:
      return {
        isFetching: false,
        values: action.payload
      }
    case ActionTypes.BG_JOB_TITLE_FAILURE:
    case ActionTypes.BG_JOB_TITLE_CLEAR:
      return {
        isFetching: false
      }
    default:
      return state
  }
}

const jobDetails = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.BG_JOB_DETAILS_LI_REQUEST:
    case ActionTypes.BG_JOB_DETAILS_REQUEST:
      return Object.assign({}, state, action.payload)
    case ActionTypes.BG_JOB_DETAILS_LI_SUCCESS:
    case ActionTypes.BG_JOB_DETAILS_SUCCESS:
      return Object.assign({}, state, action.payload)
    case ActionTypes.BG_JOB_DETAILS_LI_FAILURE:
    case ActionTypes.BG_JOB_DETAILS_FAILURE:
      return Object.assign({}, state, action.payload.occupationsObj)
    default:
      return state
  }
}

const next = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.BG_JOB_NEXT_STEP_REQUEST:
      return Object.assign({}, state, action.payload)
    case ActionTypes.BG_JOB_NEXT_STEP_SUCCESS:
      return Object.assign({}, state, action.payload)
    case ActionTypes.BG_JOB_NEXT_STEP_FAILURE:
      return Object.assign({}, state, action.payload.nextSteps)
    default:
      return state
  }
}

const recommendedPrograms = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.PROGRAMS_REQUEST:
      return Object.assign({}, state, action.payload)
    case ActionTypes.PROGRAMS_SUCCESS:
      return Object.assign({}, state, action.payload)
    case ActionTypes.PROGRAMS_FAILURE:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}

const apiState = (state = { status: 200 }, action) => {
  switch (action.type) {
    case ActionTypes.AUTH_TOKEN_SUCCESS:
      return Object.assign({}, state, { status: 200 })
    case ActionTypes.BG_JOB_TITLE_SUCCESS:
    case ActionTypes.BG_JOB_DETAILS_SUCCESS:
    case ActionTypes.BG_JOB_NEXT_STEP_SUCCESS:
      return { status: 200 }
    case ActionTypes.BG_JOB_TITLE_FAILURE:
    case ActionTypes.BG_JOB_DETAILS_FAILURE:
    case ActionTypes.BG_JOB_NEXT_STEP_FAILURE:
      if (action.payload.status && action.payload.status === 401)
        return Object.assign({}, state, action.payload)
      return state
    default:
      return state
  }
}

export default combineReducers({
  apiState,
  jobTitles,
  jobDetails,
  next,
  recommendedPrograms
})
