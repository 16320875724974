import _ from 'lodash'

import Landing from '../pages/landing'

import CareerMapperLanding from '../pages/career-mapper/landing'
import CareerMapperAbout from '../pages/career-mapper/about-you'
import CareerMapperData from '../pages/career-mapper/data'
import CareerMapperCareerPath from '../pages/career-mapper/career-path'
import CareerMapperDetail from '../pages/career-mapper/detail'
import CareerMapperRegister from '../pages/career-mapper/register'
import CareerMapperReport from '../pages/career-mapper/report'
import CareerMapperSharedReport from '../pages/shared-report'

import CareerFinderLanding from '../pages/career-finder/landing'
import CareerFinderAbout from '../pages/career-finder/about-you'
import CareerFinderQuiz from '../pages/career-finder/career-quiz'
import CareerFinderStrengths from '../pages/career-finder/personal-strengths'
import CareerFinderRegister from '../pages/career-finder/register'
import CareerFinderReport from '../pages/career-finder/report'
import CareerFinderDetail from '../pages/career-finder/detail'

const routes = [
  {
    name: 'Think proactively about your career',
    exact: true,
    path: '/',
    component: Landing
  },
  // CareerMapper Pages
  {
    name: 'Think proactively about your career',
    exact: true,
    path: '/career-mapper',
    component: CareerMapperLanding
  },
  {
    name: 'Your career so far',
    exact: true,
    path: '/career-mapper/about-you',
    component: CareerMapperAbout
  },
  {
    name: 'Career Mapper data',
    exact: true,
    path: '/career-mapper/data',
    component: CareerMapperData
  },
  {
    exact: true,
    name: 'Your career path',
    path: '/career-mapper/career-path',
    component: CareerMapperCareerPath
  },
  {
    name: 'Your current skillset',
    path: '/career-mapper/career-path/detail/:job',
    component: CareerMapperDetail
  },
  {
    name: 'Your future awaits',
    path: '/career-mapper/register',
    component: CareerMapperRegister
  },
  {
    exact: true,
    name: 'Your career plan',
    path: '/career-mapper/report',
    component: CareerMapperReport
  },
  // CareerFinder pages
  {
    name: 'Think proactively about your career',
    exact: true,
    path: '/career-finder',
    component: CareerFinderLanding
  },
  {
    name: 'Your profile',
    exact: true,
    path: '/career-finder/about-you',
    component: CareerFinderAbout
  },
  {
    name: 'Quiz',
    exact: true,
    path: '/career-finder/career-quiz',
    component: CareerFinderQuiz
  },
  {
    name: 'Personal strengths',
    exact: true,
    path: '/career-finder/personal-strengths',
    component: CareerFinderStrengths
  },
  {
    name: 'Career Track',
    path: '/career-finder/discipline/:discipline',
    component: CareerFinderDetail
  },
  {
    name: 'Your future awaits...',
    exact: true,
    path: '/career-finder/register',
    component: CareerFinderRegister
  },
  {
    name: 'Your Career Finder',
    exact: true,
    path: '/career-finder/report',
    component: CareerFinderReport
  },
  // Shared Report
  {
    exact: true,
    name: 'Discipline',
    path: '/report-discipline/:discipline',
    component: CareerFinderDetail
  },
  {
    exact: true,
    name: 'Your Career Path',
    path: '/report/:uuid/:suid',
    component: CareerMapperSharedReport
  }
]

export default routes
export function getRouteDefinition(path) {
  const noSlashPath = _.endsWith(path, '/') ? _.trimEnd(path, '/') : path
  const splitPath = _.split(noSlashPath, '/')

  let route = _.find(routes, r => {
    const noSlashRoutePath = _.endsWith(r.path, '/')
      ? _.trimEnd(r.path, '/')
      : r.path
    const splitRouterPath = _.split(noSlashRoutePath, '/')
    let dropRoute = 0
    _.eachRight(splitRouterPath, rp => {
      if (_.startsWith(rp, ':')) dropRoute++
    })

    const parsedPath = _.join(_.dropRight(splitPath, dropRoute), '/')
    const parsedRoutePath = _.join(_.dropRight(splitRouterPath, dropRoute), '/')

    return parsedRoutePath === parsedPath
  })

  return route || { name: 'Career Mapper' }
}
