import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import validator from 'validator'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga'
import { find } from 'lodash'

import Input from '../../components/input'
import { Button, Footer } from 'hult-component-library'
import { Link } from 'react-router-dom'
import ProgressBar from '../../components/progress-bar'

import countries from '../../utils/countries'
import { isNameValid } from '../../utils/methods'

import { registerUser, save } from '../../actions'
class Register extends Component {
  state = {
    firstName: this.props.firstName,
    lastName: this.props.lastName,
    email: '',
    phone: '',
    country: this.props.country,
    countryState: this.props.countryState,
    firstNameValid: true,
    lastNameValid: true,
    emailValid: true,
    phoneValid: true,
    countryValid: true,
    countryStateValid: true,
    privacyPolicy: false,
    countryStateList: []
  }

  componentDidMount() {
    this.validateStep()
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.validateStep()
    }
  }

  validateStep() {
    const {
      firstName,
      lastName,
      email,
      country,
      privacyPolicy,
      phone
    } = this.props

    if (firstName && lastName && email && country && privacyPolicy && phone)
      this.props.history.replace('/career-finder/report')
  }

  checkLinkedInToken() {
    const { linkedinToken } = this.props
    if (linkedinToken && linkedinToken.access_token) {
      const expireDate = moment.utc(linkedinToken.expires_at)
      const currentDate = moment.utc()
      if (!currentDate.isSameOrAfter(expireDate, 'minute')) {
        return true
      }
    }
    return false
  }

  handleFailure = (error) => {
    console.error(error)
  }

  onFirstNameChange = (event) => {
    this.setState({ firstName: event.target.value })
  }

  onLastNameChange = (event) => {
    this.setState({ lastName: event.target.value })
  }

  onEmailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  onPhoneChange = (event) => {
    this.setState({ phone: event.target.value })
  }

  getCountryStates(countryCode) {
    const country = find(countries, (c) => c.code === countryCode)

    return (country && country.states) || []
  }

  onCountryChange = (event) => {
    const countryStateList = this.getCountryStates(event.target.value)
    this.setState({
      country: event.target.value,
      countryStateList,
      countryState: '',
      countryValid: event.target.value,
      countryStateValid: countryStateList.length === 0
    })
  }

  onStateChange = (event) => {
    this.setState({
      countryState: event.target.value,
      countryStateValid: event.target.value
    })
  }

  onChecked = (event) => {
    this.setState({ privacyPolicy: !this.state.privacyPolicy })
  }

  register = () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      country,
      countryState,
      privacyPolicy
    } = this.state

    this.props.callRegister({
      firstName,
      lastName,
      email,
      phone,
      country,
      countryState,
      privacyPolicy
    })

    ReactGA.event(
      {
        category: 'User',
        action: 'Registration'
      },
      ['tracker1', 'tracker2']
    )

    this.props.history.replace('/career-finder/report')
  }

  showFooter() {
    const {
      firstName,
      firstNameValid,
      lastName,
      lastNameValid,
      email,
      emailValid,
      phone,
      phoneValid,
      country,
      countryValid,
      countryStateValid,
      privacyPolicy
    } = this.state

    return (
      firstName &&
      firstNameValid &&
      lastName &&
      lastNameValid &&
      email &&
      emailValid &&
      phone &&
      phoneValid &&
      country &&
      countryValid &&
      countryStateValid &&
      privacyPolicy
    )
  }

  render = () => (
    <>
      <hr />
      <ProgressBar percentage={100} length={4} />
      <hr />
      <div className='g--spacer--small hide show--tablet' />
      <p className='g--font-h4--light'>
        Congratulations! Our Career Finder algorithm has crunched the data and
        created your personalized report.
      </p>
      <p className='g--font-body--light'>
        Fill in your details below to view your results
      </p>
      <div className='row row--gutters-small'>
        <div className='row__column row__column--50'>
          <Input
            id='first-name'
            type='text'
            placeholder='First name'
            icon='user'
            value={this.state.firstName}
            onChange={this.onFirstNameChange}
            onBlur={() => {
              this.setState({
                firstNameValid: isNameValid(this.state.firstName)
              })
            }}
            isValid={this.state.firstNameValid}
          />
        </div>
        <div className='row__column row__column--50'>
          <Input
            id='last-name'
            type='text'
            placeholder='Last name'
            icon='user'
            value={this.state.lastName}
            onChange={this.onLastNameChange}
            onBlur={() => {
              this.setState({
                lastNameValid: isNameValid(this.state.lastName)
              })
            }}
            isValid={this.state.lastNameValid}
          />
        </div>
      </div>
      <Input
        id='email'
        type='email'
        placeholder='Email'
        icon='envelope'
        value={this.state.email}
        onChange={this.onEmailChange}
        onBlur={() => {
          this.setState({
            emailValid: validator.isEmail(this.state.email)
          })
        }}
        isValid={this.state.emailValid}
      />
      <Input
        id='phone'
        type='phone'
        placeholder='Phone Number'
        icon='phone'
        value={this.state.phone}
        onChange={this.onPhoneChange}
        onBlur={() => {
          this.setState({
            phoneValid: validator.isMobilePhone(this.state.phone)
          })
        }}
        isValid={this.state.phoneValid}
      />
      <Input
        className='hide'
        id='country'
        placeholder='Country'
        type='select'
        value={this.state.country}
        onChange={this.onCountryChange}
      >
        {countries.map((country) => (
          <option title={country.value} key={country.code} value={country.code}>
            {country.value}
          </option>
        ))}
      </Input>
      {this.state.countryStateList.length > 0 && (
        <Input
          id='state'
          placeholder='State'
          type='select'
          value={this.state.countryState}
          onChange={this.onStateChange}
        >
          {this.state.countryStateList.map((state) => (
            <option title={state} key={state} value={state}>
              {state}
            </option>
          ))}
        </Input>
      )}
      <Input
        id='terms'
        label='I agree to my personal data being processed in accordance with Hult’s Privacy Policy and for Hult and its affiliates to use my personal data to provide me with further information about its programs.'
        type='checkbox'
        checked={this.state.privacyPolicy}
        onChange={this.onChecked}
      />
      <hr />
      <p className='g--font-body--light'>
        Read the{' '}
        <a
          className='g--font-body-bold--light'
          href='https://www.hult.edu/en/legal-privacy/'
          rel='noopener noreferrer'
          target='_blank'
        >
          Hult Privacy Policy
        </a>
      </p>
      {this.showFooter() && (
        <Footer>
          <Button
            className='button--wide'
            variant='cta'
            Wrapper={'button'}
            wrapperProps={{
              onClick: () => {
                this.register()
              }
            }}
            label='Get your career plan'
            icon='arrow-right'
          />
        </Footer>
      )}
      <hr />
      <p className='g--font-body--light'>
        <Link to='/career-mapper/data'>
          <b className='g--font-body-bold--light'>Career Mapper data</b>
        </Link>{' '}
        is provided by various leading public &amp; private big data sources
      </p>
    </>
  )
}

Register.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  callRegister: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  firstName: state.user.firstName || '',
  lastName: state.user.lastName || '',
  email: state.user.email || '',
  country: state.user.country || '',
  state: state.user.state || '',
  privacyPolicy: state.user.privacyPolicy || false
})

const mapDispatchToProps = (dispatch) => ({
  callRegister: (user) => {
    dispatch(registerUser(user))
    dispatch(save())
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Register)
)
