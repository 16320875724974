import React from 'react'
import PropTypes from 'prop-types'

import Input from './input'

const SelectSearchWrapper = ({
  id,
  className,
  placeholder,
  options,
  onChange,
  onSelected,
  onBlur,
  value,
  isSearching,
  autocomplete
}) => (
  <div>
    <Input
      className={`${className} input__field--predictive`}
      id={id}
      type='text'
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      isSearch={true}
      isSearching={isSearching}
      autocomplete={autocomplete}
    />
    <div className='predictive'>
      {options.map(option => (
        <div
          className='predictive__option g--font-body g--margin-none'
          onClick={() => {
            onSelected(option.id, option.name)
          }}
          key={option.id}
        >
          {option.name}
        </div>
      ))}
    </div>
  </div>
)

SelectSearchWrapper.defaultProps = {
  value: '',
  className: '',
  placeholder: '',
  onChange: () => { },
  onSelected: () => { },
  onBlur: () => { },
  options: [],
  autocomplete: 'on'
}

SelectSearchWrapper.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  onChange: PropTypes.func,
  onSelected: PropTypes.func,
  onBlur: PropTypes.func,
  isSearching: PropTypes.bool.isRequired,
  autocomplete: PropTypes.string
}

export default SelectSearchWrapper
