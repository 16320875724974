import React from 'react'
import PropTypes from 'prop-types'
import { Card, Statistic, Spinner } from 'hult-component-library'
import FontAwesome from '../font-awesome'

const RoleOption = props => (
  <>
    <Card onClick={props.onClick}>
      <p className='g--margin-top-none g--font-body-bold'>{props.title}</p>
      {props.salary && (
        <div
          className={`statistic ${Math.round(props.salary) < 0
            ? 'statistic--negative'
            : 'statistic--positive'
            }`}
        >
          <div className='statistic__icon'>
            <FontAwesome icon='dollar-sign' />
          </div>
          <p className='statistic__copy g--margin-none g--font-body-bold'>
            {
              Math.round(props.salary) < 0
                ? ''
                : '+'
            }
            {Math.round(props.salary)}%
          </p>
        </div>
      )}
      {props.demand && (
        <Statistic demand={props.demand} />
      )}
      {props.loading && (
        <Spinner className='spinner--small' />
      )}
    </Card>
    <div className="g--spacer g--spacer--small" />
  </>
)

RoleOption.defaultProps = {
  onClick: () => { },
  salary: null,
  demand: '',
  loading: false
}

RoleOption.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  salary: PropTypes.string,
  demand: PropTypes.string,
  loading: PropTypes.bool
}

export default RoleOption
