import { RSAA } from 'redux-api-middleware'
import fetch from 'cross-fetch'

import { saveData } from '../utils/saveData'

import * as ActionTypes from './types'

export const save = () => (dispatch, getState) => {
  const { user } = getState()
  return fetch(
    `${process.env.REACT_APP_CAREER_USER_API}/${user.uuid}/${user.suid}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(saveData(getState()))
    }
  )
    .then(res => {
      console.log(res)
      return null
    })
    .catch(err => {
      console.log(err)
      return null
    })
}

export const getReport = (uuid, suid) => ({
  [RSAA]: {
    types: [
      ActionTypes.REPORT_REQUEST,
      {
        type: ActionTypes.REPORT_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => JSON.parse(json.UserData.S))
      },
      ActionTypes.REPORT_FAILURE
    ],
    endpoint: `${process.env.REACT_APP_CAREER_USER_API}/${uuid}/${suid}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    fetch: async (...args) => fetch(...args)
  }
})
