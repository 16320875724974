import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import { verifyToken, generateUids } from '../../actions'
import Header from '../header'
import { Container } from 'hult-component-library'
import WrapperGA from '../ga-wrapper'
import routes from '../../utils/routes'
import handleCookies from '../../utils/handleCookies'

import '../../assets/fonts.scss'
import HultPower from '../../components/hult-power'

class App extends Component {
  componentDidMount() {
    const { callGenerateUids, refreshTokenAction } = this.props
    handleCookies()
    refreshTokenAction()
    callGenerateUids()
  }

  componentDidUpdate() {
    const { callGenerateUids, refreshTokenAction } = this.props
    refreshTokenAction()
    callGenerateUids()
  }

  render() {
    return (
      <Router>
        <WrapperGA>
          <Header />
          <Container>
            {routes.map((route, i) => (
              <Route key={i} {...route} />
            ))}
          </Container>
          <HultPower />
        </WrapperGA>
      </Router>
    )
  }
}

App.propTypes = {
  callGenerateUids: PropTypes.func.isRequired,
  refreshTokenAction: PropTypes.func.isRequired,
  bgApiState: PropTypes.shape({
    status: PropTypes.number.isRequired
  }).isRequired
}

const mapStateToProps = state => ({
  bgApiState: state.api.apiState
})

const mapDispatchToProps = dispatch => ({
  refreshTokenAction: () => {
    dispatch(verifyToken())
  },
  callGenerateUids: () => {
    dispatch(generateUids())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
