import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Card, Button, Statistic } from 'hult-component-library'
import FontAwesome from '../font-awesome'
import { Link } from 'react-router-dom'

class Role extends Component {
  render() {

    return (
      <>
        {this.props.roleLevel && (
          <p className='g--font-h4--light'>{this.props.roleLevel}</p>
        )}
          <Card>
            <div className='row row--nowrap row--space-between row--align-center'>
              <div className='row__column row__column--heading'>
                <Link to={this.props.link}>
                  <p className='g--ellipsis g--margin-none g--font-body-bold'>{this.props.title}</p>
                </Link>
              </div>
              {this.props.isConfirmed && this.props.isChangeable && (
                <div className='row__column'>
                  <Button
                    icon='pencil'
                    Wrapper={'button'}
                    wrapperProps={{ onClick: this.props.changeRole }}
                  />
                </div>
              )}
            </div>
            <Link to={this.props.link}>
              {/* <hr /> */}
              {this.props.salary && (
                <div
                  className={`statistic ${
                    Math.round(this.props.salary) < 0
                      ? 'statistic--negative'
                      : 'statistic--positive'
                  }`}
                >
                  <div className='statistic__icon'>
                    <FontAwesome icon='dollar-sign' />
                  </div>
                  <p className='statistic__copy g--margin-none g--font-body-bold'>
                    {
                      Math.round(this.props.salary) < 0
                        ? ''
                        : '+'
                    }
                    {Math.round(this.props.salary)}%
                  </p>
                </div>
              )}
              {this.props.demand && (
                <Statistic demand={this.props.demand} />
              )}
              {(this.props.skillsTotal || this.props.skillsTotal === 0) && (
                <div className='statistic statistic--strengths'>
                  <div className='statistic__icon'>
                    <FontAwesome icon='brain' />
                  </div>
                  <p className='statistic__copy g--margin-none g--font-body-bold'>
                    You have {this.props.skillsSelected} of {this.props.skillsTotal} skills
                    needed
                  </p>
                </div>
              )}
              {this.props.experience && (
                <Statistic minExperience={this.props.experience} />
              )}
            </Link>
          </Card>
      </>
    )
  }
}

Role.defaultProps = {
  className: '',
  isConfirmed: false,
  roleLevel: '',
  title: '',
  salary: null,
  demand: '',
  experience: null,
  skillsSelected: null,
  skillsTotal: null,
  link: '#',
  isChangeable: true,
  changeRole: () => {}
}

Role.propTypes = {
  className: PropTypes.string,
  isConfirmed: PropTypes.bool,
  roleLevel: PropTypes.string,
  title: PropTypes.string,
  salary: PropTypes.string,
  demand: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  experience: PropTypes.number,
  skillsSelected: PropTypes.number,
  skillsTotal: PropTypes.number,
  link: PropTypes.string,
  isChangeable: PropTypes.bool,
  changeRole: PropTypes.func
}

export default Role
