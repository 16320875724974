export const educationOptions = [
  'High School',
  "Bachelor's Degree",
  "Master's Degree"
]

export const experienceOptions = [
  { value: 'Student', label: 'Student' },
  { value: '0-3', label: '0-3 years' },
  { value: '3-5', label: '3-5 years' },
  { value: '5+', label: '5+ years' }
]

export const careerPathQuestions = [
  'Select your short term goal',
  'Select your long-term opportunity'
]

export const careerPathLabels = [
  'Your short-term goal',
  'Your long-term opportunity'
]

export const careerGoalRoles = [
  {
    id: "11131010",
    name: 'Chief Executive Officer'
  },
  {
    id: "19141010",
    name: 'Chief Financial Officer',
  },
  {
    id: "23141012",
    name: 'Chief Information Officer',
  },
  {
    id: "27111110",
    name: 'Chief Marketing Officer'
  }
]

export const studentRole = {
  salary: 38000,
  nextRoles: [
    {
      id: "19131211",
      name: 'Financial Analyst (General)'
    },
    {
      id: "27121112",
      name: 'Marketing Analyst',
    },
    {
      id: "23171541",
      name: 'Software Developer / Engineer',
    },
    {
      id: "11161510",
      name: 'Operations Coordinator'
    },
    {
      id: "32161416",
      name: 'Retail Sales Associate (General)',
    },
    {
      id: "11111012",
      name: 'Business Analyst (General)'
    }
  ]
}

export const skillGroups = {
  Essential: 'The fundamental skills required for any role in this field',
  Specialist: 'The professional skills that qualify you for this specific role',
  Distinguishing:
    'The differentiating skills that make you a standout candidate'
}

const currentYear = new Date().getFullYear();
export const graduationOptions = [`${currentYear}`, `${currentYear + 1}`, `${currentYear + 2}`, `${currentYear + 3}`, `${currentYear + 4}`, `${currentYear + 5}`]

export const unwantedSkills = ['truck driving', 'construction management']
