import { applyMiddleware, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
// defaults to localStorage for web and AsyncStorage for react-native
import storage from 'redux-persist/lib/storage'
import { apiMiddleware } from 'redux-api-middleware'
import thunkMiddleware from 'redux-thunk'

import reducers from '../reducers'

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default preloadedState => {
  const store = createStore(
    persistedReducer,
    preloadedState,
    applyMiddleware(apiMiddleware, thunkMiddleware)
  )

  const persistor = persistStore(store)

  return {
    store,
    persistor
  }
}
