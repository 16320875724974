import React from 'react'
import { Link } from 'react-router-dom'

import { Card, Button, Footer } from 'hult-component-library'

import Logo from '../../assets/images/logo.svg'
import IconDirection from '../../assets/images/icon-direction.svg'
import IconSearch from '../../assets/images/icon-search.svg'
import IconMoney from '../../assets/images/icon-money.svg'

import ProgressBar from '../../components/progress-bar'

import '../../assets/pages.scss'

const Landing = () => (
  <div className='pages'>
    <div className='g--spacer--large' />
    <div className='pages__center'>
      <img className='pages__logo' src={Logo} alt={Logo} />
      <p className='g--font-body--light'>
        Think proactively about your career.
      </p>
    </div>
    <hr />
    <ProgressBar percentage={0} length={6} />
    <hr />
    <Card>
      <div className='row row--align-center'>
        <div className='row__column row__column--20 pages__icon'>
          <img src={IconDirection} alt={IconDirection} />
        </div>
        <div className='row__column row__column--80'>
          <p className='g--font-body-bold'>
            Map your career path using big data
          </p>
        </div>
      </div>
      <hr />
      <div className='row row--align-center'>
        <div className='row__column row__column--20 pages__icon'>
          <img src={IconSearch} alt={IconSearch} />
        </div>
        <div className='row__column row__column--80'>
          <p className='g--font-body-bold'>
            Identify the skills you need for your next roles
          </p>
        </div>
      </div>
      <hr />
      <div className='row row--align-center'>
        <div className='row__column row__column--20 pages__icon'>
          <img src={IconMoney} alt={IconMoney} />
        </div>
        <div className='row__column row__column--80'>
          <p className='g--font-body-bold'>
            Discover which roles will maximize your salary
          </p>
        </div>
      </div>
    </Card>
    <Footer>
      <Button
        className='button--wide'
        variant='cta'
        Wrapper={Link}
        wrapperProps={{
          to: '/career-mapper/about-you'
        }}
        label='Start your journey'
        icon='arrow-right'
      />
    </Footer>
  </div>
)

export default Landing
