import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import StoryblokClient from 'storyblok-js-client'
import { flatMap, uniq } from 'lodash'
import { Message, Button, Footer } from 'hult-component-library'

import SkillGroup from '../../components/skill-group'
import { addUserFinderStrength, removeUserFinderStrength } from '../../actions'
import ProgressBar from '../../components/progress-bar'

const Storyblok = new StoryblokClient({
  accessToken: process.env.REACT_APP_SB_TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory'
  }
})
class PersonalStrengths extends Component {
  state = {
    strengths: [],
    enableNextSteps: false,
    showMessage: false
  }

  constructor(props) {
    super(props)

    if (!props.reportId) props.history.replace('/career-finder/career-quiz')
    if (props.selectedStrengths.length > 0) this.state.enableNextSteps = true
  }

  componentDidMount() {
    const { reportId } = this.props
    this.getReportFromStoryblok(reportId)
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      const { selectedStrengths } = this.props
      let enableNextSteps = false

      if (selectedStrengths.length > 0) {
        enableNextSteps = true
      }

      this.setState({ enableNextSteps, showMessage: true })
    }
  }

  getReportFromStoryblok = (reportId = 'fallback') => {
    Storyblok.get(`cdn/stories/careermapper/reports/${reportId.toLowerCase()}`)
      .then((response) => {
        this.handleReportData(response)
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          this.getReportFromStoryblok()
        } else console.error(error)
      })
  }

  handleReportData = (response) => {
    const { story } = response.data
    if (story && story.content) {
      const report = story.content
      const disciplines = []
      if (report.first_discipline) disciplines.push(report.first_discipline)
      if (report.second_discipline) disciplines.push(report.second_discipline)
      if (report.third_discipline) disciplines.push(report.third_discipline)

      Storyblok.get(`cdn/stories`, {
        by_uuids: disciplines.join(',')
      })
        .then((discRes) => {
          const { stories } = discRes.data
          if (stories) {
            this.setState({
              strengths: uniq(flatMap(stories, (s) => s.content.strengths))
            })
          }
        })
        .catch((error) => {
          console.log('ERROR GETTING DISCIPLINES', error)
        })
    }
  }

  onStrengthSelect = (strengthName, isSelected) => {
    const {
      callAddUserFinderStrength,
      callRemoveUserFinderStrength
    } = this.props
    if (isSelected) callAddUserFinderStrength(strengthName)
    else callRemoveUserFinderStrength(strengthName)
  }

  render = () => (
    <>
      <hr />
      <ProgressBar percentage={66.67} length={4} />
      <hr />
      <div className='g--spacer--small hide show--tablet' />
      <p className='g--font-h4--light'>
        On to your strengths - which of the following do you have? Don't be shy,
        select as many as you like.
      </p>
      <SkillGroup
        isDisabled={false}
        skills={this.state.strengths}
        onSkillSelect={this.onStrengthSelect}
        selectedSkills={this.props.selectedStrengths}
      />
      {this.state.showMessage && (
        <>
          <hr />
          <Message message={`Nice choice!`} />
        </>
      )}
      {this.state.enableNextSteps ? (
        <Footer>
          <Button
            className='button--wide'
            variant='cta'
            Wrapper={'button'}
            wrapperProps={{
              onClick: () => {
                this.props.history.push(
                  this.props.registered
                    ? '/career-finder/report'
                    : '/career-finder/register'
                )
              }
            }}
            label='Find out your results'
            icon='arrow-right'
          />
        </Footer>
      ) : (
        <Footer>
          <Button
            variant='dark'
            className='button--wide'
            Wrapper={'button'}
            wrapperProps={{
              onClick: () => {
                this.props.history.push(
                  this.props.registered
                    ? '/career-finder/report'
                    : '/career-finder/register'
                )
              }
            }}
            label={`I don't know which strengths I have yet`}
            icon='arrow-right'
          />
        </Footer>
      )}
    </>
  )
}

PersonalStrengths.defaultProps = {
  reportId: ''
}

PersonalStrengths.propTypes = {
  reportId: PropTypes.string
}

const mapStateToProps = (state) => ({
  reportId: state.user.finderReportId,
  selectedStrengths: state.user.finderStrenghts || [],
  registered: !!(
    state.user.firstName &&
    state.user.lastName &&
    state.user.email &&
    state.user.phone &&
    state.user.country &&
    state.user.privacyPolicy
  )
})

const mapDispatchToProps = (dispatch) => ({
  callAddUserFinderStrength: (strengthName) => {
    dispatch(addUserFinderStrength(strengthName))
  },
  callRemoveUserFinderStrength: (strengthName) => {
    dispatch(removeUserFinderStrength(strengthName))
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PersonalStrengths)
)
