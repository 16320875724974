import {
  orderBy,
  uniqBy,
  union,
  keys,
  filter,
  indexOf,
  slice,
  concat
} from 'lodash'
import { skillGroups, unwantedSkills } from './config'

const filterUnwantedSkills = skillsArr => {
  return skillsArr.filter(
    skill => !unwantedSkills.includes(skill.name.toLowerCase())
  )
}

export const parseSkillGroups = skills => {
  const parsedSkills = {}

  const definingSkills = filterUnwantedSkills(
    orderBy(
      uniqBy(union(skills['baselineSkills'], skills['definingSkills']), 'name'),
      'count',
      'desc'
    )
  )
  const necessarySkills = filterUnwantedSkills(
    orderBy(skills['necessarySkills'], 'count', 'desc')
  )
  const distinguishingSkills = filterUnwantedSkills(
    orderBy(skills['distinguishingSkills'], 'count', 'desc')
  )

  parsedSkills[keys(skillGroups)[0]] = definingSkills
  parsedSkills[keys(skillGroups)[1]] = necessarySkills
  parsedSkills[keys(skillGroups)[2]] = distinguishingSkills

  return parsedSkills
}

export const parseSkillGroupsReport = (skills, selectedSkills) => {
  const parsedSkills = {}
  let otherSkills = []

  const definingSkills = filterUnwantedSkills(
    filter(
      orderBy(
        uniqBy(
          union(skills['baselineSkills'], skills['definingSkills']),
          'name'
        ),
        'count',
        'desc'
      ),
      skill => indexOf(selectedSkills, skill.name) === -1
    )
  )
  const necessarySkills = filterUnwantedSkills(
    filter(
      orderBy(skills['necessarySkills'], 'count', 'desc'),
      skill => indexOf(selectedSkills, skill.name) === -1
    )
  )
  const distinguishingSkills = filterUnwantedSkills(
    filter(
      orderBy(skills['distinguishingSkills'], 'count', 'desc'),
      skill => indexOf(selectedSkills, skill.name) === -1
    )
  )
  parsedSkills['specialist'] = definingSkills
  if (definingSkills.length > 5) {
    parsedSkills['specialist'] = slice(definingSkills, 0, 5)
    otherSkills = concat(otherSkills, slice(definingSkills, 5))
  }

  parsedSkills['qualifying'] = necessarySkills
  if (necessarySkills.length > 3) {
    parsedSkills['qualifying'] = slice(necessarySkills, 0, 3)
    otherSkills = concat(otherSkills, slice(necessarySkills, 3))
  }

  parsedSkills['essential'] = distinguishingSkills
  if (distinguishingSkills.length > 3) {
    parsedSkills['essential'] = slice(distinguishingSkills, 0, 3)
    otherSkills = concat(otherSkills, slice(distinguishingSkills, 3))
  }

  parsedSkills['other'] = otherSkills

  return parsedSkills
}
