import validator from 'validator'

export function calculateSalaryIncrease(original, next) {
  if (next > original) {
    const increase = next - original
    return parseFloat((increase / original) * 100).toFixed(1)
  } else {
    const decrease = original - next
    return parseFloat(-((decrease / original) * 100)).toFixed(1)
  }
}

export function isNameValid(string) {
  return (
    validator.isAlphanumeric(
      string.replace(/[\s\-`'.]/g, '').trim(),
      'en-US'
    ) ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'cs-CZ') ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'da-DK') ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'de-DE') ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'es-ES') ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'fr-FR') ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'nb-NO') ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'nl-NL') ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'nn-NO') ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'hu-HU') ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'pl-PL') ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'pt-PT') ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'ru-RU') ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'sr-RS@latin') ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'sr-RS') ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'tr-TR') ||
    validator.isAlphanumeric(string.replace(/\s/g, '').trim(), 'uk-UA')
  )
}

export const getRecommendations = (arr, jobTitle) => {
  return arr.filter(story => {
    return (
      story.content.jobTitle &&
      story.content.jobTitle.toLowerCase() === jobTitle.toLowerCase()
    )
  })
}

export const getProgramCode = (arr, workExperience, educationLevel) => {
  return arr.filter(
    recom =>
      recom.educationLevel
        .toLowerCase()
        .split(' ')
        .join('') ===
        educationLevel
          .toLowerCase()
          .split(' ')
          .join('') && recom.workExperience === workExperience
  )[0].program
}

export const getProgram = (codeArr, programArr) => {
  return codeArr.map(code => {
    return programArr.filter(
      program => program.content.programCode === code.programCode
    )[0].content
  })
}
