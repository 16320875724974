import React from 'react'
import PropTypes from 'prop-types'

import { Spinner } from 'hult-component-library'
import FontAwesome from '../font-awesome'

function styleClass(isValid, icon) {
  const className = ['input__field', 'input__field--text']
  if (!isValid) className.push('input__field--error')
  if (icon !== '') className.push('input__field--has-icon')
  return className.join(' ')
}

const Input = ({
  id,
  type,
  className,
  placeholder,
  onChange,
  onBlur,
  isSearch,
  isSearching,
  value,
  icon,
  autocomplete,
  isValid
}) => (
  <div>
    <input
      className={styleClass(isValid)}
      id={id}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      autoComplete={autocomplete}
    />
    {icon && (
      <FontAwesome className="input__icon" icon={icon} type="far" />
    )}
    {isSearch && isSearching && (
      <div className="input__icon">
        <Spinner className='spinner--small' />
      </div>
    )}
  </div>
)

Input.defaultProps = {
  id: '',
  autocomplete: 'on',
  className: '',
  value: '',
  type: 'text',
  placeholder: 'Please select',
  isSearch: false,
  isSearching: false,
  isValid: true,
  onChange: () => {},
  onBlur: () => {}
}

Input.propTypes = {
  id: PropTypes.string,
  autocomplete: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  isSearch: PropTypes.bool,
  isSearching: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isValid: PropTypes.bool
}

export default Input
