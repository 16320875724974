import { combineReducers } from 'redux'

import * as ActionTypes from '../actions/types'

const authCode = (state = '', action) => {
  switch (action.type) {
    case ActionTypes.SET_LINKEDIN_AUTH_CODE:
      return action.code
    case ActionTypes.CLEAR_USER_ALL:
      return ''
    default:
      return state
  }
}

const authToken = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.LINKEDIN_AUTH_TOKEN_REQUEST:
      return { isFetching: true }
    case ActionTypes.LINKEDIN_AUTH_TOKEN_SUCCESS:
      return Object.assign({}, action.payload, {
        isFetching: false
      })
    case ActionTypes.LINKEDIN_AUTH_TOKEN_FAILURE:
    case ActionTypes.LINKEDIN_PROFILE_SUCCESS:
    case ActionTypes.LINKEDIN_PROFILE_FAILURE:
      return { isFetching: false }
    default:
      return state
  }
}

const profile = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.LINKEDIN_PROFILE_REQUEST:
      return { isFetching: true }
    case ActionTypes.LINKEDIN_PROFILE_SUCCESS:
      return Object.assign({}, action.payload, {
        isFetching: false
      })
    case ActionTypes.LINKEDIN_PROFILE_FAILURE:
      return { isFetching: false }
    case ActionTypes.CLEAR_USER_ALL:
      return {}
    default:
      return state
  }
}

export default combineReducers({
  authCode,
  authToken,
  profile
})
