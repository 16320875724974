import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import { Link } from 'react-router-dom'
import Program from '../../components/program'
import Role from '../../components/role'
import Summary from '../../components/summary'
import Share from '../../components/share'
import WebChat from '../../containers/web-chat'
import { Skill, Card, Button, Course, Spinner } from 'hult-component-library'

import {
  retrieveRecommendedPrograms,
  generateUids,
  clearUserCareerPathRole,
  save,
  createLead
} from '../../actions'

import { calculateSalaryIncrease } from '../../utils/methods'
import { parseSkillGroupsReport } from '../../utils/parseBGSkills'
import { studentRole } from '../../utils/config'

class Report extends Component {
  state = {
    missingSkills: [],
    shortTermMissingSkills: [],
    longTermMissingSkills: [],
    shortTermSalaryBoosterSkills: [],
    longTermSalaryBoosterSkills: [],
    shortTermUncoveredSkills: [],
    longTermUncoveredSkills: []
  }

  constructor(props) {
    super(props)
    if (!props.currentRole) props.history.replace('/career-mapper/about-you')
    else if (props.careerPath.length === 0)
      props.history.replace('/career-mapper/career-path')
    else if (!props.registered) props.history.replace('/career-mapper/register')
  }

  componentDidMount() {
    setTimeout(function noProgramsFound() {
      const noProgramsAlert = document.getElementById('noProgramsAlert')
      const loadingPrograms = document.getElementById('loadingPrograms')

      noProgramsAlert.style.display = 'block'
      loadingPrograms.style.display = 'none'
    }, 45000)
    this.calculateSkillsState(true)
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.recommendedPrograms, this.props.recommendedPrograms)
    ) {
      this.calculateSkillsState(false)
    }
  }

  calculateSkillsState(init) {
    const { currentRole, careerPath } = this.props

    if (!currentRole || careerPath.length === 0) return
    if (init) this.props.callRetrieveRecommendedPrograms()

    const missingSkills = this.missingSkillsMethod()
    const shortTermMissingSkills = this.roleMissingSkills(
      _.first(careerPath).name
    )
    const shortTermSalaryBoosterSkills = this.salaryBoostSkills(
      _.first(careerPath).name
    )
    const shortTermUncoveredSkills = this.checkUncoveredSkills(
      shortTermMissingSkills
    )
    const longTermMissingSkills = this.roleMissingSkills(
      _.last(careerPath).name
    )
    const longTermSalaryBoosterSkills = this.salaryBoostSkills(
      _.last(careerPath).name
    )
    const longTermUncoveredSkills = this.checkUncoveredSkills(
      longTermMissingSkills
    )

    this.setState({
      missingSkills,
      shortTermMissingSkills,
      longTermMissingSkills,
      shortTermSalaryBoosterSkills,
      longTermSalaryBoosterSkills,
      shortTermUncoveredSkills,
      longTermUncoveredSkills
    })
  }

  missingSkillsMethod() {
    const { jobDetails, careerPath } = this.props
    const selectedSkills = _.uniq(_.flatMap(careerPath, 'skills'))
    let missingSkills = []

    _.each(careerPath, r => {
      const details = jobDetails[r.name].result
      const skills = _.flatMap(details.skills, s => _.flatMap(s, 'name'))

      missingSkills = _.concat(
        missingSkills,
        _.filter(skills, s => _.indexOf(selectedSkills, s) === -1)
      )
    })

    return missingSkills
  }

  roleMissingSkills(roleName) {
    const roleDetails = this.props.jobDetails[roleName].result
    const roleMissingSkills = parseSkillGroupsReport(
      roleDetails.skills,
      _.uniq(_.flatMap(this.props.careerPath, 'skills'))
    )
    return roleMissingSkills
  }

  checkUncoveredSkills(roleMissingSkills) {
    const coveredSkills = _.uniq(
      _.flatMap(this.props.recommendedPrograms || [], p =>
        _.flatMap(p.Skills, 'Title')
      )
    )

    const roleSkills = _.flatMap(roleMissingSkills, (s, g) => {
      if (g !== 'other') return s
      return []
    })

    return _.filter(
      _.uniqBy(_.orderBy(roleSkills, 'count', 'desc'), 'name'),
      s => _.indexOf(coveredSkills, s.name) === -1
    )
  }

  salaryBoostSkills(roleName) {
    const { jobDetails } = this.props

    if (jobDetails[roleName] && jobDetails[roleName].result) {
      const roleDetails = jobDetails[roleName].result
      const salaryBoostSkills = _.slice(
        _.orderBy(
          JSON.parse(JSON.stringify(roleDetails.salaryBoostSkills || [])),
          'count'
        ),
        0,
        3
      )

      _.each(
        salaryBoostSkills,
        sbs =>
          (sbs.salaryIncrease = calculateSalaryIncrease(
            roleDetails.salary.mean,
            roleDetails.salary.mean + sbs.marginalValue
          ))
      )

      return salaryBoostSkills
    }

    return []
  }

  getRoleSalaryIncrease(roleName) {
    const { currentRole, jobDetails } = this.props
    const roleDetails = jobDetails[roleName].result
    let currentRoleMeanSalary = studentRole.salary
    if (jobDetails[currentRole] && jobDetails[currentRole].result) {
      currentRoleMeanSalary = jobDetails[currentRole].result.salary.mean
    }

    return calculateSalaryIncrease(
      currentRoleMeanSalary,
      roleDetails.salary.mean
    )
  }

  startOver = () => {
    this.props.startOver()
    this.props.history.replace('/career-mapper/about-you')
  }

  skillMatches = (array1, array2) =>
    array1.filter(element => array2.includes(element))

  render() {
    const { currentRole, careerPath, jobDetails } = this.props
    const shortTermMissingSkills = _.uniqBy(
      _.orderBy(
        _.flatMap(this.state.shortTermMissingSkills, (s, g) => {
          if (g !== 'other') return s
          return []
        }),
        'count',
        'desc'
      ),
      'name'
    )

    const longTermMissingSkills = _.uniqBy(
      _.orderBy(
        _.flatMap(this.state.longTermMissingSkills, (s, g) => {
          if (g !== 'other') return s
          return []
        }),
        'count',
        'desc'
      ),
      'name'
    )
    return !currentRole || careerPath.length === 0 ? null : (
      <>
        <div className='content'>
          <div className='g--spacer--small hide show--tablet' />
          <Summary
            firstName={this.props.firstName}
            lastName={this.props.lastName}
            currentRole={this.props.currentRole}
            education={this.props.education}
            experience={this.props.experience}
          />
          {careerPath.length > 1 && (
            <>
              <hr />
              <p className='g--font-h4--light'>Your short-term goal</p>
              <Card>
                <Role
                  title={_.first(careerPath).name}
                  salary={this.getRoleSalaryIncrease(_.first(careerPath).name)}
                  experience={Math.round(
                    jobDetails[_.first(careerPath).name].result.experience.mean
                  )}
                  demand={
                    jobDetails[_.first(careerPath).name].result.demand.level
                  }
                />
                <hr />
                <p className='g--font-body-bold'>
                  Top 3 most in-demand skills for this role
                </p>
                {_.slice(shortTermMissingSkills, 0, 3).map(s => (
                  <Course
                    key={s.name}
                    skill={s.name}
                    desc={s.description}
                    href={`https://www.udemy.com/courses/search/?q=${s.name}`}
                  />
                ))}
                {this.state.shortTermSalaryBoosterSkills &&
                  this.state.shortTermSalaryBoosterSkills.length > 0 && (
                    <>
                      <hr />
                      <p className='g--font-body-bold'>Maximize your salary</p>
                      <p>Skills that companies are paying a premium for</p>
                      {this.state.shortTermSalaryBoosterSkills &&
                        this.state.shortTermSalaryBoosterSkills.map(s => (
                          <div key={s.name}>
                            <Course
                              skill={s.name}
                              salary={s.salaryIncrease}
                              desc={s.description}
                              href={`https://www.udemy.com/courses/search/?q=${s.name}`}
                            />
                          </div>
                        ))}
                    </>
                  )}
                {shortTermMissingSkills.length > 3 && (
                  <>
                    <hr />
                    <p className='g--font-body-bold'>
                      Other skills typically needed for this role
                    </p>
                    <div className='row row--flow row--gutters-xsmall'>
                      {_.slice(shortTermMissingSkills, 3).map(s => (
                        <div key={s.name} className='row__column'>
                          <Skill title={s.name} disableTogle={true} />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </Card>
            </>
          )}
          <>
            <hr />
            <p className='g--font-h4--light'>Your long-term opportunity</p>
            <Card>
              <Role
                title={_.last(careerPath).name}
                salary={this.getRoleSalaryIncrease(_.last(careerPath).name)}
                experience={Math.round(
                  jobDetails[_.last(careerPath).name].result.experience.mean
                )}
              />
              <hr />
              {longTermMissingSkills.length > 0 && (
                <>
                  <p className='g--font-body-bold'>
                    Skills typically needed for this role
                  </p>
                  <div className='row row--flow row--gutters-xsmall'>
                    {_.slice(longTermMissingSkills).map(s => (
                      <div key={s.name} className='row__column'>
                        <Skill title={s.name} disableTogle={true} />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </Card>
            <hr />
            <p className='g--font-body-bold--light'>
              {`Progams to build the skills you need to become a  ${
                _.last(careerPath).name
              }`}
            </p>
            {this.props.recommendedPrograms.length > 0 && (
              <div className='programs row row--snap'>
                {this.props.recommendedPrograms.map((p, pos) => (
                  <div key={p.title} className='row__column'>
                    <Program
                      position={++pos}
                      totalPrograms={this.props.recommendedPrograms.length}
                      title={p.title}
                      description={p.description}
                      skillPercentage={parseInt(
                        (this.skillMatches(
                          p.skills_list,
                          _.uniq(
                            _.flatMap(this.state.longTermMissingSkills, s =>
                              _.flatMap(s, 'name')
                            )
                          )
                        ).length *
                          100) /
                          _.uniq(
                            _.flatMap(this.state.longTermMissingSkills, s =>
                              _.flatMap(s, 'name')
                            )
                          ).length
                      )}
                      link={p.link}
                    />
                  </div>
                ))}
              </div>
            )}
            <div className='programs__loaders'>
              <div id='loadingPrograms' className='programs__loading'>
                <Spinner />
                <p className='g--font-body--light'>{`Our algorithms are working at high speeds to suggest programs to become a ${
                  _.last(careerPath).name
                }`}</p>
              </div>
              <p
                id='noProgramsAlert'
                className='programs__alert g--font-body--light'
              >
                We advise you to look for mentors that have been on a similar
                career path
              </p>
            </div>
          </>
        </div>
        <hr />
        <div className='row row--gutters-small'>
          <div className='row__column row__column--50 row__column--tablet-100'>
            <Button
              className='button--wide'
              variant='dark'
              Wrapper={'button'}
              wrapperProps={{
                onClick: () => {
                  this.startOver()
                }
              }}
              label='Start over'
              icon='undo'
            />
          </div>
          <div className='row__column row__column--50 row__column--tablet-100'>
            <Share
              name={this.props.firstName}
              uuid={this.props.uuid}
              suid={this.props.suid}
            />
          </div>
        </div>
        <hr />
        <p className='g--font-body--light'>
          <Link to='/career-mapper/data'>
            <b className='g--font-body-bold--light'>Career Mapper data</b>
          </Link>{' '}
          is provided by various leading public &amp; private big data sources
        </p>
        <WebChat chatLocation='career-mapper' />
      </>
    )
  }
}

Report.defaultProps = {
  currentRole: null,
  careerPath: []
}

Report.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  uuid: PropTypes.string.isRequired,
  suid: PropTypes.string.isRequired,
  currentRole: PropTypes.string,
  careerPath: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      skills: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  jobDetails: PropTypes.objectOf(
    PropTypes.shape({
      result: PropTypes.shape({
        name: PropTypes.string,
        skills: PropTypes.objectOf(
          PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string
            })
          )
        )
      })
    })
  ),
  recommendedPrograms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  callRetrieveRecommendedPrograms: PropTypes.func.isRequired,
  registered: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  currentRole: state.user.currentRole,
  jobDetails: state.api.jobDetails,
  careerPath: state.user.careerPath || [],
  recommendedPrograms: state.api.recommendedPrograms.programs || [],
  uuid: state.user.uuid,
  suid: state.user.suid,
  firstName: state.user.firstName,
  lastName: state.user.lastName,
  education: state.user.education,
  experience: state.user.experience,
  registered: !!(
    state.user.firstName &&
    state.user.lastName &&
    state.user.email &&
    state.user.country
  )
})

const mapDispatchToProps = dispatch => ({
  callRetrieveRecommendedPrograms: () => {
    dispatch(retrieveRecommendedPrograms())
      .then(() => {
        dispatch(createLead())
      })
      .then(() => {
        dispatch(save())
      })
  },
  startOver: () => {
    dispatch(generateUids(true))
    dispatch(clearUserCareerPathRole(0))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Report))
