import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import JobSearch from '../../containers/career-mapper/jobSearchInput'

import Input from '../../components/input'
import { Button, Footer } from 'hult-component-library'
import PageLoader from '../../components/page-loader'
import ProgressBar from '../../components/progress-bar'

import { educationOptions, experienceOptions } from '../../utils/config'

import {
  setEducation,
  setCurrentJob,
  setExperience,
  getJobDetails,
  save,
  generateUids,
  clearFinder,
  setCurrentJobId
} from '../../actions'

class AboutYou extends Component {
  state = {
    enableNextSteps: false,
    loading: false,
    hasJobDetails: false
  }

  componentDidMount() {
    if (this.props.finderDetails) this.props.callClearFinder()
    this.validateStep(true)
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.validateStep()
    }

    if (this.state.loading && this.state.enableNextSteps) {
      setTimeout(
        () => this.props.history.replace('/career-mapper/career-path'),
        3000
      )
    }
  }

  validateStep(redirect) {
    const { education, job, experience } = this.props
    if (education && job && experience) {
      if (redirect) this.props.history.replace('/career-mapper/career-path')
      else this.setState({ enableNextSteps: true })
    } else {
      this.setState({ enableNextSteps: false })
    }
  }

  onEducationChange = (e) => {
    const { value } = e.target
    const { callSetEducation } = this.props

    callSetEducation(value)
  }

  onJobSelect = (jobId, jobName) => {
    console.log({ jobId, jobName })
    this.setState({ hasJobDetails: false })
    this.props.callSetCurrentJob(jobName, jobId).then(() => {
      this.setState({ hasJobDetails: true })
    })
  }

  onExperienceChange = (e) => {
    const { value } = e.target
    const { callSetExperience } = this.props

    callSetExperience(value)
  }

  render = () =>
    this.state.loading ? (
      <PageLoader title='Creating your career path' />
    ) : (
      <div>
        <div className='g--spacer--small hide show--tablet' />
        <ProgressBar percentage={20} length={6} />
        <div className='content'>
          <p className='g--font-h4--light'>Tell us a bit about you...</p>
          <Input
            id='education-level'
            placeholder='Highest level of education*'
            type='select'
            onChange={this.onEducationChange}
            value={this.props.education}
          >
            {educationOptions.map((option) => (
              <option title={option} key={option} value={option}>
                {option}
              </option>
            ))}
          </Input>
          {/* <span className="g--font-span--light">*(in progress or completed)</span> */}
          <Input
            id='current-role-experience'
            placeholder='Years of relevant work experience'
            type='select'
            onChange={this.onExperienceChange}
            value={this.props.experience}
          >
            {experienceOptions.map((option) => (
              <option
                title={option.value}
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </Input>
          {this.props.experience !== 'Student' && (
            <>
              <hr />
              <p className='g--font-body-bold--light'>
                Your current (or similar) job title *
              </p>
              <JobSearch
                id='current-role'
                placeholder='Start typing...'
                onSelected={this.onJobSelect}
                value={this.props.job}
              />
              <p className='g--font-body--light'>
                * Job titles are standardized internationally. Select the title
                that is closest to your current role.
              </p>
            </>
          )}
          {this.state.enableNextSteps && (
            <Footer>
              <Button
                className='button--wide'
                variant='cta'
                Wrapper={'button'}
                wrapperProps={{
                  onClick: () => {
                    this.setState({ loading: true })
                  }
                }}
                label='Plan your career path'
                icon='arrow-right'
                disabled={!this.state.hasJobDetails}
              />
            </Footer>
          )}
        </div>
      </div>
    )
}

AboutYou.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  job: PropTypes.string.isRequired,
  education: PropTypes.string.isRequired,
  experience: PropTypes.string.isRequired,
  jobOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  linkedinToken: PropTypes.shape({
    access_token: PropTypes.string,
    expires_at: PropTypes.string
  }),
  callSetEducation: PropTypes.func.isRequired,
  callSetCurrentJob: PropTypes.func.isRequired,
  callSetExperience: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  job: state.user.currentRole || '',
  education: state.user.education || '',
  experience: state.user.experience || '',
  jobOptions: state.api.jobTitles.values || [],
  linkedinToken: state.linkedin.authToken || {},
  finderDetails: state.user.reportDetails
})

const mapDispatchToProps = (dispatch) => ({
  callSetEducation: (education) => {
    dispatch(setEducation(education))
    dispatch(save())
  },
  callSetCurrentJob: (job, jobId) => {
    dispatch(setCurrentJob(job))
    dispatch(setCurrentJobId(jobId))
    return dispatch(getJobDetails(job, jobId)).then(() => {
      dispatch(save())
      return 'done'
    })
  },
  callSetExperience: (experience) => {
    dispatch(setExperience(experience))
    dispatch(save())
  },
  callClearFinder: () => {
    dispatch(generateUids(true))
    dispatch(clearFinder())
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AboutYou)
)
