import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Header } from 'hult-component-library'

import { getRouteDefinition } from '../../utils/routes'

import { generateUids, clearUserCareerPathRole } from '../../actions'

class HeaderContainer extends Component {
  constructor(props) {
    super(props)
    const { location } = props
    const route = getRouteDefinition(location.pathname)

    this.state = {
      route,
      sharedReport: route.path === '/report/:uuid/:suid'
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props

    if (prevProps.location.pathname !== location.pathname) {
      const route = getRouteDefinition(location.pathname)
      this.setState({
        route,
        sharedReport: route.path === '/report/:uuid/:suid'
      })
    }
  }

  backAction = () => {
    const { history, location, startOver } = this.props

    if (location.pathname === '/career-mapper/report') {
      startOver()
      history.replace('/career-mapper/career-path')
    } else if (location.pathname === '/career-finder/report') {
      startOver()
      history.replace('/career-finder/career-quiz')
    } else if (location.pathname !== '/') {
      history.goBack()
    }
  }

  pageTitle = () => {
    const { route, sharedReport } = this.state
    const { report } = this.props

    if (sharedReport) return `${report.firstName}'s career path | Career Mapper`

    return route.name === 'Career Mapper'
      ? 'Career Mapper'
      : `${route.name} | Career Mapper`
  }

  pageHeader = () => {
    const { route, sharedReport } = this.state
    const { report } = this.props

    switch (true) {
      case sharedReport:
        return (
          <Header
            title={`${report.firstName}'s career path`}
            showShareButton={false}
            showBackButton={false}
          />
        )
      case route.path === '/':
      case route.path === '/career-mapper':
        return ''
      case route.path === '/career-finder':
        return ''
      case route.path === '/career-finder/career-quiz':
        return <Header showBackButton={false} title={route.name || ''} />
      case route.path === '/career-finder/personal-strengths':
        return <Header showBackButton={false} title={route.name || ''} />
      default:
        return <Header backAction={this.backAction} title={route.name || ''} />
    }
  }

  render = () => (
    <>
      <Helmet>
        <title>{this.pageTitle()}</title>
      </Helmet>

      {this.pageHeader()}

      {false && (
        <p className='g--font-body--light'>
          Please check something before proceeding.
        </p>
      )}
    </>
  )
}

HeaderContainer.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  report: state.report || {}
})

const mapDispatchToProps = dispatch => ({
  startOver: () => {
    dispatch(generateUids(true))
    dispatch(clearUserCareerPathRole(0))
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
)
