import * as ActionTypes from '../actions/types'

export default (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.REPORT_REQUEST:
      return state
    case ActionTypes.REPORT_SUCCESS:
      return Object.assign({}, state, action.payload)
    case ActionTypes.REPORT_FAILURE:
      return {}
    default:
      return state
  }
}
