const brandColor1 = '#2A29A4'
const brandColor2 = '#FF5A5F'
const brandTextColor = '#ffffff'

const brandMessageBubbleColors = bgColor => {
  return {
    Bubble: {
      background: bgColor,
      color: brandTextColor
    },
    Avatar: {
      background: bgColor,
      color: brandTextColor
    },
    Header: {
      color: brandTextColor
    }
  }
}

const careerTheme = {
  EntryPoint: {
    Container: {
      background: brandColor2,
      color: brandTextColor,
      '&:hover': {
        backgroundColor: brandColor2
      },
      ' span': {
        color: brandTextColor
      }
    }
  },

  MainHeader: {
    Container: {
      background: brandColor2,
      color: brandTextColor
    },
    Logo: {
      fill: brandTextColor
    }
  },

  Chat: {
    MessageListItem: {
      FromOthers: brandMessageBubbleColors(brandColor2),
      FromMe: brandMessageBubbleColors(brandColor1)
    },
    MessageInput: {
      Button: {
        background: brandColor2,
        color: brandTextColor
      }
    },
    MessageCanvasTray: {
      Container: {
        background: brandColor2,
        color: brandTextColor
      }
    }
  }
}

export default {
  accountSid: process.env.REACT_APP_TWILIO_ACCOUNT_SID,
  flexFlowSid: process.env.REACT_APP_TWILIO_FLOW_SID,
  colorTheme: {
    overrides: careerTheme
  },
  componentProps: {
    EntryPoint: {
      iconClosed: 'MessageBold'
    },
    MainHeader: {
      showImage: false,
      titleText: 'Hultbot'
    },
    MessagingCanvas: {
      showWelcomeMessage: false,
      memberDisplayOptions: {
        yourDefaultName: 'You',
        theirDefaultName: 'Agent',
        yourFriendlyNameOverride: false,
        theirFriendlyNameOverride: true
      },
      showReadStatus: false
    }
  }
}
