import React from 'react'
import PropTypes from 'prop-types'

import { Spinner } from 'hult-component-library'

import './page-loader.scss'

const PageLoader = ({ className, title }) => (
  <div className={`page-loader ${className}`}>
    <div className="g--spacer--large" />
    <Spinner />
    <div className="g--spacer" />
    <p className='g--font-body--light'>{title}</p>
  </div>
)

PageLoader.defaultProps = {
  className: '',
  title: ''
}

PageLoader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string
}

export default PageLoader
