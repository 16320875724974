import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ClipboardJS from 'clipboard'
import { Button, Card, Container } from 'hult-component-library'

class Share extends Component {
  state = {
    displayInfo: false
  }

  componentDidMount() {
    new ClipboardJS('.clipboard-btn')
  }

  share = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `${this.props.name}'s Career Plan`,
          text: `Check out ${this.props.name}'s Career Plan — it rocks!`,
          url: `${document.location.protocol}//${document.location.hostname}/report/${this.props.uuid}/${this.props.suid}`
        })
        .then(() => console.log('Successful share'))
        .catch(error => console.log('Error sharing', error))
    } else {
      this.toggleInfo()
    }
  }

  toggleInfo = () => {
    const { displayInfo } = this.state

    this.setState({
      displayInfo: !displayInfo
    })
  }

  copyToClipboard() {
    const input = document.createElement('input')
    input.type = 'text'
    input.value = `${document.location.protocol}//${document.location.hostname}/report/${this.props.uuid}/${this.props.suid}`

    document.appendChild(input)
    input.select()

    document.execCommand('copy')
  }

  render() {
    const { displayInfo } = this.state

    return (
      <>
        <Button
          variant='cta'
          className='button--wide'
          icon='share'
          label='Share your plan'
          Wrapper='button'
          wrapperProps={{ onClick: () => this.share() }}
        />
        <div className={`share ${displayInfo ? 'share--active' : ''}`}>
          <Container className='share__container'>
            <Card className='share__content'>
              <header className='share__header'>
                <p className='g--margin-none g--font-h4'>Share your plan</p>
                <Button
                  icon='times'
                  Wrapper='button'
                  wrapperProps={{ onClick: () => this.toggleInfo() }}
                />
              </header>
              <hr />
              <div className='share__options'>
                <Button
                  className='share__button button--wide'
                  iconType='fab'
                  icon='facebook-square'
                  label='Share on Facebook'
                  Wrapper='a'
                  wrapperProps={{
                    href: `https://facebook.com/sharer/sharer.php?u=${document.location.protocol}//${document.location.host}/report/${this.props.uuid}/${this.props.suid}`,
                    target: '_blank',
                    rel: 'noopener',
                    'aria-label': 'Share on Facebook'
                  }}
                />

                <Button
                  className='share__button button--wide'
                  iconType='fab'
                  icon='twitter-square'
                  label='Share on Twitter'
                  Wrapper='a'
                  wrapperProps={{
                    href: `https://twitter.com/intent/tweet/?text=Check out ${this.props.name}'s Career Plan — it rocks!;url=${document.location.protocol}//${document.location.host}/report/${this.props.uuid}/${this.props.suid}`,
                    target: '_blank',
                    rel: 'noopener',
                    'aria-label': 'Share on Twitter'
                  }}
                />

                <Button
                  className='share__button button--wide'
                  iconType='fab'
                  icon='linkedin'
                  label='Share on Linkedin'
                  Wrapper='a'
                  wrapperProps={{
                    href: `https://www.linkedin.com/shareArticle?mini=true&url=${document.location.protocol}//${document.location.host}/report/${this.props.uuid}/${this.props.suid}`,
                    target: '_blank',
                    rel: 'noopener',
                    'aria-label': 'Share on Linkedin'
                  }}
                />

                <Button
                  className='share__button button--wide clipboard-btn'
                  icon='link'
                  label='Copy link'
                  Wrapper='button'
                  wrapperProps={{
                    'data-clipboard-text': `${document.location.protocol}//${document.location.host}/report/${this.props.uuid}/${this.props.suid}`
                  }}
                />
              </div>
            </Card>
          </Container>
        </div>
      </>
    )
  }
}

Share.defaultProps = {
  className: ''
}

Share.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  suid: PropTypes.string.isRequired
}

export default Share
