import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Input from '../../../components/input'

import { searchJobTitle, clearJobTitles } from '../../../actions'

class JobSearchInput extends Component {
  state = {
    jobSearch: null
  }

  searchJobTitleTimer = null
  searchJobClearTimer = null

  onJobBlur = () => {
    const { callClearJobTitles } = this.props

    clearTimeout(this.searchJobTitleTimer)
    this.setState({ jobSearch: '' }, () => {
      this.searchJobClearTimer = setTimeout(() => {
        if (this.props.jobOptions.length > 0) callClearJobTitles()
      }, 200)
    })
  }

  onJobChange = e => {
    const { value } = e.target
    const { callSearchJobTitle } = this.props

    if (value !== this.props.job) {
      clearTimeout(this.searchJobTitleTimer)
      this.setState({ jobSearch: value }, () => {
        this.searchJobTitleTimer = setTimeout(() => {
          if (value && value.length > 2) {
            callSearchJobTitle(value)
          }
        }, 1000)
      })
    }
  }

  onJobSelect = (id, job) => {
    this.props.callClearJobTitles()
    this.props.onSelected(id, job)
    this.setState({ jobSearch: null })
  }

  render = () => (
    <Input
      id={this.props.id}
      label={this.props.label}
      type='select'
      isSearch={true}
      isSearching={this.props.isSearchingTitles}
      placeholder={this.props.placeholder}
      onBlur={this.onJobBlur}
      onChange={this.onJobChange}
      onSelected={this.onJobSelect}
      options={this.props.jobOptions}
      autocomplete={'off'}
      value={
        this.state.jobSearch !== null ? this.state.jobSearch : this.props.value
      }
    />
  )
}

JobSearchInput.defaultProps = {
  label: '',
  placeholder: '',
  value: ''
}

JobSearchInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  jobOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  isSearchingTitles: PropTypes.bool.isRequired,
  value: PropTypes.string,
  callSearchJobTitle: PropTypes.func.isRequired,
  callClearJobTitles: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  jobOptions: state.api.jobTitles.values || [],
  isSearchingTitles: state.api.jobTitles.isFetching || false
})

const mapDispatchToProps = dispatch => ({
  callSearchJobTitle: search => {
    dispatch(searchJobTitle(search))
  },
  callClearJobTitles: () => {
    dispatch(clearJobTitles())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(JobSearchInput)
