import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import StoryblokClient from 'storyblok-js-client'
import { find, intersection } from 'lodash'

import { Button, Card, Spinner, Degree, Message } from 'hult-component-library'
import HultIntro from '../../components/hult-intro'
import Summary from '../../components/summary'
import Discipline from '../../components/discipline'
import Share from '../../components/share'
import WebChat from '../../containers/web-chat'

import {
  addFinderReport,
  generateUids,
  clearFinder,
  createBBALead,
  save
} from '../../actions'

const Storyblok = new StoryblokClient({
  accessToken: process.env.REACT_APP_SB_TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory'
  }
})

class Report extends Component {
  state = { report: null, disciplineCount: 0 }

  constructor(props) {
    super(props)
    if (!props.reportId) props.history.replace('/career-finder/about-you')
    else if (!props.registered) props.history.replace('/career-finder/register')
  }

  componentDidMount() {
    const { reportId } = this.props

    this.getReportFromStoryblok(reportId)
  }

  getReportFromStoryblok = (reportId = 'fallback') => {
    Storyblok.get(`cdn/stories/careermapper/reports/${reportId.toLowerCase()}`)
      .then(response => {
        this.handleReportData(response)
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          this.getReportFromStoryblok()
        } else console.error(error)
      })
  }

  handleReportData = response => {
    const { story } = response.data
    let { disciplineCount } = this.state

    if (story && story.content) {
      const report = story.content
      const disciplines = []
      if (report.first_discipline) {
        disciplines.push(report.first_discipline)
        disciplineCount++
      }
      if (report.second_discipline) {
        disciplines.push(report.second_discipline)
        disciplineCount++
      }
      if (report.third_discipline) {
        disciplines.push(report.third_discipline)
        disciplineCount++
      }
      if (report.recommendation_major)
        disciplines.push(report.recommendation_major)
      if (report.recommendation_minor)
        disciplines.push(report.recommendation_minor)

      Storyblok.get(`cdn/stories`, {
        by_uuids: disciplines.join(',')
      })
        .then(discRes => {
          const { stories } = discRes.data
          if (stories) {
            const firstDisc = find(
              stories,
              s => s.uuid === report.first_discipline
            )
            report.first_discipline = firstDisc
              ? firstDisc.content
              : report.first_discipline

            const secondDisc = find(
              stories,
              s => s.uuid === report.second_discipline
            )
            report.second_discipline = secondDisc
              ? secondDisc.content
              : report.second_discipline
            const thirdDisc = find(
              stories,
              s => s.uuid === report.third_discipline
            )
            report.third_discipline = thirdDisc
              ? thirdDisc.content
              : report.third_discipline

            const major = find(
              stories,
              s => s.uuid === report.recommendation_major
            )
            report.recommendation_major = major
              ? major.content
              : report.recommendation_major

            const minor = find(
              stories,
              s => s.uuid === report.recommendation_minor
            )
            report.recommendation_minor = minor
              ? minor.content
              : report.recommendation_minor
          }

          this.setState(
            {
              report,
              disciplineCount
            },
            () => {
              this.props.callAddFinderReport(report)
            }
          )
        })
        .catch(error => {
          console.log('ERROR GETTING DISCIPLINES', error)
        })
    }
  }

  getMatchRating(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  startOver = () => {
    this.props.startOver()
    this.props.history.replace('/career-finder/career-quiz')
  }

  render = () => (
    <>
      <div className='content'>
        <div className='g--spacer--small hide show--tablet' />
        <Summary
          firstName={this.props.firstName}
          lastName={this.props.lastName}
          education={`Class of ${this.props.graduationYear}`}
          strengthList={
            this.state.report
              ? [
                  this.state.report.response_q1 || '',
                  this.state.report.response_q2 || '',
                  this.state.report.response_q3 || ''
                ]
              : []
          }
          isHighSchoolStudent
        />
        {this.state.disciplineCount > 0 && (
          <>
            <hr />
            <Message
              message={`Based on your answers, your top ${this.state.disciplineCount} career tracks are...`}
            />
          </>
        )}
        {this.state.report ? (
          <>
            {this.state.report.first_discipline &&
              typeof this.state.report.first_discipline === 'object' && (
                <>
                  <hr />
                  <p className='g--font-h4--light'>Top match</p>
                  <Discipline
                    position='1'
                    matchRating={this.state.report.first_discipline_percentage}
                    title={this.state.report.first_discipline.title}
                    description={this.state.report.first_discipline.description}
                    avatar='smile'
                    startSalary={
                      this.state.report.first_discipline.start_salary
                    }
                    futureSalary={
                      this.state.report.first_discipline.future_salary
                    }
                    details='first_discipline'
                    allStrengths={
                      this.state.report.first_discipline.strengths.length
                    }
                    strengths={
                      intersection(
                        this.state.report.first_discipline.strengths,
                        this.props.selectedStrenghts
                      ).length
                    }
                  />
                </>
              )}
            {this.state.report.second_discipline &&
              typeof this.state.report.second_discipline === 'object' && (
                <>
                  <hr />
                  <p className='g--font-h4--light'>You could also look at...</p>
                  <Discipline
                    position='2'
                    matchRating={this.state.report.second_discipline_percentage}
                    title={this.state.report.second_discipline.title}
                    description={
                      this.state.report.second_discipline.description
                    }
                    startSalary={
                      this.state.report.second_discipline.start_salary
                    }
                    futureSalary={
                      this.state.report.second_discipline.future_salary
                    }
                    details='second_discipline'
                    allStrengths={
                      this.state.report.second_discipline.strengths.length
                    }
                    strengths={
                      intersection(
                        this.state.report.second_discipline.strengths,
                        this.props.selectedStrenghts
                      ).length
                    }
                  />
                </>
              )}
            {this.state.report.third_discipline &&
              typeof this.state.report.third_discipline === 'object' && (
                <>
                  <hr />
                  <p className='g--font-h4--light'>Or how about...</p>
                  <Discipline
                    position='3'
                    matchRating={this.state.report.third_discipline_percentage}
                    title={this.state.report.third_discipline.title}
                    description={this.state.report.third_discipline.description}
                    startSalary={
                      this.state.report.third_discipline.start_salary
                    }
                    futureSalary={
                      this.state.report.third_discipline.future_salary
                    }
                    details='third_discipline'
                    allStrengths={
                      this.state.report.third_discipline.strengths.length
                    }
                    strengths={
                      intersection(
                        this.state.report.third_discipline.strengths,
                        this.props.selectedStrenghts
                      ).length
                    }
                  />
                </>
              )}
            {this.state.report.recommendation_major &&
              this.state.report.recommendation_minor && (
                <>
                  <hr />
                  <p className='g--font-h4--light'>Hult's recommendation</p>
                  <Card>
                    <Message message='You’re on track—now let’s get you there! The HultBot recommends the following undergraduate program...' />
                    <hr />
                    <Degree
                      title={this.state.report.recommendation_major.title}
                      type='Major'
                      description={
                        this.state.report.recommendation_major.description
                      }
                    />
                    <Degree
                      title={this.state.report.recommendation_minor.title}
                      type='Minor'
                      description={
                        this.state.report.recommendation_minor.description
                      }
                    />
                    <hr />
                    <HultIntro />
                  </Card>
                  <hr />
                  <div className='row row--gutters-small'>
                    <div className='row__column row__column--50 row__column--tablet-100'>
                      <Button
                        className='button--wide'
                        variant='dark'
                        Wrapper={'button'}
                        wrapperProps={{
                          onClick: () => {
                            this.startOver()
                          }
                        }}
                        label='Start over'
                        icon='undo'
                      />
                    </div>
                    <div className='row__column row__column--50 row__column--tablet-100'>
                      <Share
                        name={this.props.firstName}
                        uuid={this.props.uuid}
                        suid={this.props.suid}
                      />
                    </div>
                  </div>
                  <hr />
                  <p className='g--font-body--light'>
                    <Link to='/career-mapper/data'>
                      <b className='g--font-body-bold--light'>
                        Career Finder data
                      </b>
                    </Link>{' '}
                    is provided by various leading public &amp; private big data
                    sources
                  </p>
                </>
              )}
            <WebChat chatLocation='career-finder' />
          </>
        ) : (
          <>
            <hr />
            <Spinner />
          </>
        )}
      </div>
    </>
  )
}

Report.defaultProps = {
  selectedStrenghts: []
}

Report.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  uuid: PropTypes.string.isRequired,
  suid: PropTypes.string.isRequired,
  selectedStrenghts: PropTypes.array,
  registered: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  reportId: state.user.finderReportId,
  uuid: state.user.uuid,
  suid: state.user.suid,
  firstName: state.user.firstName,
  lastName: state.user.lastName,
  graduationYear: state.user.graduationYear,
  selectedStrenghts: state.user.finderStrenghts,
  registered: !!(
    state.user.firstName &&
    state.user.lastName &&
    state.user.email &&
    state.user.country
  )
})

const mapDispatchToProps = dispatch => ({
  callAddFinderReport: report => {
    dispatch(addFinderReport(report))
    dispatch(createBBALead())
    dispatch(save())
  },
  startOver: () => {
    dispatch(generateUids(true))
    dispatch(clearFinder())
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Report))
