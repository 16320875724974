import { RSAA } from 'redux-api-middleware'
import fetch from 'cross-fetch'
import { flatMap, keys, values, first, orderBy, filter, slice } from 'lodash'

import * as ActionTypes from './types'

import { verifyToken } from './authToken'

// Fetch Possible Job Titles from a User input string
export const fetchJobTitle = ({ token, title, fromLinkedIn }) => ({
  [RSAA]: {
    types: [
      fromLinkedIn
        ? ActionTypes.BG_JOB_TITLE_LI_REQUEST
        : ActionTypes.BG_JOB_TITLE_REQUEST,
      {
        type: fromLinkedIn
          ? ActionTypes.BG_JOB_TITLE_LI_SUCCESS
          : ActionTypes.BG_JOB_TITLE_SUCCESS,
        payload: (action, state, res) =>
          res
            .json()
            .then((json) =>
              flatMap(json.data, (occ) => { return { id: occ.id, name: occ.name } })
            )
      },
      {
        type: fromLinkedIn
          ? ActionTypes.BG_JOB_TITLE_LI_FAILURE
          : ActionTypes.BG_JOB_TITLE_FAILURE,
        payload: (action, state, res) => {
          return {
            method: { func: fetchJobTitle, values: { title, fromLinkedIn } },
            ...res
          }
        }
      }
    ],
    // endpoint: `${process.env.REACT_APP_BG_API}/classifier/subocc`,
    // endpoint: `https://classification.emsicloud.com/taxonomies/lot/versions/6.12.0/concepts?filter=level:3&q=${title}`,
    endpoint: `https://q21xh3v3yj.execute-api.us-east-1.amazonaws.com/qa/occupation/search?filter=level:3&q=${title}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    },
    // body: JSON.stringify({
    //   title,
    //   count: 6
    // }),
    fetch: async (...args) => fetch(...args)
  }
})

// Fetches Job Titles from Burning-glass api unless it is already fetching.
export const searchJobTitle = (title, fromLinkedIn) => (dispatch, getState) => {
  const { jobTitles } = getState().api
  if (jobTitles && jobTitles.isFetching) {
    return null
  }

  return dispatch(
    verifyToken((token) => fetchJobTitle({ token, title, fromLinkedIn }))
  )
}

// Fetches Job Titles from Burning-glass api unless it is already fetching.
export const searchLinkedInJobTitle = () => (dispatch, getState) => {
  const { positions } = getState().linkedin.profile

  if (positions && keys(positions).length > 0) {
    const jobtitle = values(
      first(
        orderBy(
          flatMap(positions),
          ['startMonthYear.year', 'startMonthYear.month'],
          ['desc', 'desc']
        )
      ).title.localized
    )[0]

    return dispatch(
      verifyToken((token) =>
        fetchJobTitle({ token, jobtitle, fromLinkedIn: true })
      )
    )
  }

  return Promise.resolve()
}

export const clearJobTitles = () => ({
  type: ActionTypes.BG_JOB_TITLE_CLEAR
})

// Retrieve Job Details
const fetchJobDetails = ({ token, jobTitle, jobId, fromLinkedIn }) => ({
  [RSAA]: {
    types: [
      {
        type: fromLinkedIn
          ? ActionTypes.BG_JOB_DETAILS_LI_REQUEST
          : ActionTypes.BG_JOB_DETAILS_REQUEST,
        payload: () => {
          const occupationsObj = {}
          occupationsObj[`${jobTitle}`] = {
            isFetching: true,
            lastFetch: Date.now()
          }
          return occupationsObj
        }
      },
      {
        type: fromLinkedIn
          ? ActionTypes.BG_JOB_DETAILS_LI_SUCCESS
          : ActionTypes.BG_JOB_DETAILS_SUCCESS,
        payload: (action, state, res) =>
          res.json().then((json) => {
            const { data } = json
            const { advancementOccupations } = data

            data.advancementOccupations = slice(orderBy(advancementOccupations, 'score', 'desc'), 0, 6)

            const occData = {}

            const {
              commonSkills,
              specializedSkills,
              definingSkills,
              necessarySkills,
              distinguishingSkills
            } = data

            data.skills = {
              baselineSkills: commonSkills,
              specializedSkills,
              definingSkills,
              necessarySkills,
              distinguishingSkills
            }

            occData[`${jobTitle}`] = {
              isFetching: false,
              receivedAt: Date.now(),
              refreshAt: new Date().setDate(new Date().getDate() + 1),
              result: data
            }

            return occData
          })
      },
      {
        type: fromLinkedIn
          ? ActionTypes.BG_JOB_DETAILS_LI_FAILURE
          : ActionTypes.BG_JOB_DETAILS_FAILURE,
        payload: (action, state, res) => {
          const occupationsObj = state.api.jobDetails

          occupationsObj[`${jobTitle}`].isFetching = false
          occupationsObj[`${jobTitle}`].receivedAt = 0

          return {
            occupationsObj,
            method: {
              func: fetchJobDetails,
              values: { jobTitle, jobId, fromLinkedIn }
            },
            ...res
          }
        }
      }
    ],
    // endpoint: `${process.env.REACT_APP_BG_API
    //   }/profile/odata/subocc?$filter=${occupationsList
    //     .map((title) => `name eq '${title}'`)
    //     .join(
    //       ' or '
    //     )}&$expand=careerArea,occGroup,bgtOccInfo,education,experience,salary,demand,jobTitle,baselineSkills,specializedSkills,definingSkills,necessarySkills,distinguishingSkills,salaryBoostSkills`,
    // endpoint: `https://emsiservices.com/occupation-benchmark/dimensions/lotspecocc`,
    endpoint: `https://q21xh3v3yj.execute-api.us-east-1.amazonaws.com/qa/occupation/details`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'en-US',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      "id": jobId,
      // "region": { "level": "state", "id": "6" },
      "datasets": [
        "salary",
        // "education",
        "experience",
        "demand",
        // "titles",
        "commonSkills",
        "specializedSkills",
        "definingSkills",
        "necessarySkills",
        "distinguishingSkills",
        // "salaryBoostingSkills",
        "advancementOccupations"
      ]
    }),
    fetch: async (...args) => fetch(...args)
  }
})

export const getJobDetails = (jobTitle, jobId) => (dispatch, getState) => {
  const { jobDetails } = getState().api

  if (
    jobDetails[jobTitle] &&
    ((jobDetails[jobTitle].isFetching &&
      jobDetails[jobTitle].lastFetch < Date.now() + 15000) ||
      jobDetails[jobTitle].refreshAt > Date.now())
  ) {
    return Promise.resolve()
  }

  if (!jobId) {
    console.error('NO ID', { jobTitle, jobId })
    return Promise.reject()
  }

  return dispatch(
    verifyToken((token) =>
      fetchJobDetails({ token, jobTitle, jobId })
    )
  )
}

export const getJobsDetailsList = (list) => (dispatch, getState) => {
  const { jobDetails } = getState().api

  const occupationsList = filter(
    list,
    (jobTitle) =>
      !(
        jobDetails[jobTitle] &&
        ((jobDetails[jobTitle].isFetching &&
          jobDetails[jobTitle].lastFetch < Date.now() + 15000) ||
          jobDetails[jobTitle].refreshAt > Date.now())
      )
  )

  if (occupationsList.length) {
    do {
      const occ = occupationsList.splice(0, 1).at(0)
      const jobTitle = occ.name
      const jobId = occ.id

      if (!jobId) {
        console.error('NO ID', { jobTitle, jobId })
      }

      dispatch(
        verifyToken((token) =>
          fetchJobDetails({
            token,
            jobTitle, jobId
          })
        )
      )
    } while (occupationsList.length)
  }

  return Promise.resolve()
}

export const getNextJobsDetailsList = (jobTitle) => (dispatch, getState) => {
  const { jobDetails } = getState().api

  if (
    jobDetails[jobTitle] &&
    jobDetails[jobTitle].result &&
    jobDetails[jobTitle].result.advancementOccupations &&
    jobDetails[jobTitle].result.advancementOccupations.length > 0
  ) {
    // dispatch(getJobsDetailsList(flatMap(jobDetails[jobTitle].result, 'name')))

    jobDetails[jobTitle].result.advancementOccupations.forEach(nextJob => {
      dispatch(getJobDetails(nextJob.name, nextJob.id))
    });
  }
}

export const getLinkedInJobDetails = () => (dispatch, getState) => {
  const { jobDetails } = getState().api
  const { currentRole } = getState().user

  if (
    jobDetails[currentRole] &&
    ((jobDetails[currentRole].isFetching &&
      jobDetails[currentRole].lastFetch < Date.now() + 15000) ||
      jobDetails[currentRole].refreshAt > Date.now())
  ) {
    if (!jobDetails[currentRole] || jobDetails[currentRole].isFetching)
      return Promise.resolve()

    return Promise.resolve(
      dispatch({
        type: ActionTypes.BG_JOB_DETAILS_LI_SUCCESS,
        payload: jobDetails[currentRole]
      })
    )
  }

  return dispatch(
    verifyToken((token) =>
      fetchJobDetails({
        token,
        occupationsList: [currentRole],
        fromLinkedIn: true
      })
    )
  )
}

// Retrieve Job Next Steps
const fetchJobNextSteps = ({ token, id, jobTitle }) => ({
  [RSAA]: {
    types: [
      {
        type: ActionTypes.BG_JOB_NEXT_STEP_REQUEST,
        payload: () => {
          const nextSteps = {}
          nextSteps[jobTitle] = { isFetching: true, lastFetch: Date.now() }
          return nextSteps
        }
      },
      {
        type: ActionTypes.BG_JOB_NEXT_STEP_SUCCESS,
        payload: (action, state, res) =>
          res.json().then((json) => {
            console.log({ json })
            const nextSteps = {}
            nextSteps[jobTitle] = {
              isFetching: false,
              receivedAt: Date.now(),
              refreshAt: new Date().setDate(new Date().getDate() + 1),
              result: json.advancementOccupations
            }
            return nextSteps
          })
      },
      {
        type: ActionTypes.BG_JOB_NEXT_STEP_FAILURE,
        payload: (action, state, res) => {
          const nextSteps = {}
          nextSteps[jobTitle] = { isFetching: false }
          return {
            nextSteps,
            method: {
              func: fetchJobNextSteps,
              values: { id, jobTitle }
            },
            ...res
          }
        }
      }
    ],
    // endpoint: `${process.env.REACT_APP_BG_API}/profile/odata/subocc(${id})?$expand=advancementOccupations($filter=score gt 0.43)`,
    endpoint: `https://emsiservices.com/occupation-benchmark/dimensions/onet`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'en-US',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      "id": "17-2081.00",
      "datasets": ["advancementOccupations"]
    }),
    fetch: async (...args) => fetch(...args)
  }
})

export const getJobNextSteps = (jobTitle) => (dispatch, getState) => {
  if (jobTitle) {
    const { next, jobDetails } = getState().api
    const id =
      jobDetails[jobTitle] &&
      jobDetails[jobTitle].result &&
      jobDetails[jobTitle].result.id

    if (
      !id ||
      (next[jobTitle] &&
        next[jobTitle].isFetching &&
        next[jobTitle].lastFetch < Date.now() + 15000)
    ) {
      return null
    }

    return dispatch(
      verifyToken((token) => fetchJobNextSteps({ token, id, jobTitle }))
    )
  }
}
