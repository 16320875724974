import React, { Component } from 'react'
import { connect } from 'react-redux'
import fetch from 'cross-fetch'
import { find, keys, forEach, flatMap, sum } from 'lodash'
import { withRouter } from 'react-router-dom'

import SurveyQuestion from './components/SurveyQuestion'
import PageLoader from '../../../components/page-loader'

import { setReportId } from '../../../actions'

class Survey extends Component {
  state = {
    flow: null,
    blocks: null,
    questions: null,
    currentFlow: null,
    drawElements: [],
    selections: {},
    reportId: [],
    endSurvey: false
  }

  componentDidMount() {
    fetch(`/survey/data.json`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        if (res.status >= 400) {
          throw new Error('Bad response from server')
        }

        return res.json()
      })
      .then((survey) => {
        const flow = survey.result.Flow
        const blocks = survey.result.Blocks
        const questions = survey.result.Questions
        const currentFlow = survey.result.SurveyFlow.Flow
        const drawElements = this.getElementsToDraw(
          currentFlow,
          blocks,
          questions
        )

        this.setState({
          flow,
          blocks,
          questions,
          currentFlow,
          drawElements
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  displayLogic(elementLogic, selections = this.state.selections) {
    if (!elementLogic) return true

    let display = false
    switch (elementLogic.Type) {
      case 'BooleanExpression':
        forEach(keys(elementLogic[0]), (logicKey) => {
          const logic = elementLogic[0][logicKey]
          if (
            logicKey !== 'Type' &&
            elementLogic[0].Type === 'If' &&
            logic.Operator === 'Selected' &&
            logic.LogicType === 'Question'
          ) {
            const re = /^q:\/\/(QID\d+)\/SelectableChoice\/(\d)$/
            const match = re.exec(logic.ChoiceLocator)
            const logicQuestionId = match[1]
            const logicResponseId = match[2]
            if (
              selections[logicQuestionId] &&
              `${selections[logicQuestionId].id}` === `${logicResponseId}`
            ) {
              display = true
            }
          }
        })
        break
      default:
        console.error('Unhandled Branch Logic', elementLogic.Type)
    }

    return display
  }

  getElementsToDraw = (
    currentFlow = this.state.currentFlow,
    blocks = this.state.blocks,
    questions = this.state.questions
  ) => {
    const renderElements = []
    forEach(currentFlow, (flowElement) => {
      switch (flowElement.Type) {
        case 'Block':
        case 'Standard':
          const currentBlock = blocks[flowElement.ID]
          forEach(currentBlock.BlockElements, (element) => {
            if (element.Type === 'Question') {
              const question = questions[element.QuestionID]
              renderElements.push({
                id: question.QuestionID,
                text: question.QuestionText,
                tag: question.DataExportTag,
                parentId: flowElement.ID,
                choices: question.Choices,
                choicesOrder: question.ChoiceOrder,
                displayLogic: question.DisplayLogic
              })
            } else console.error('UNHANDLED ELEMENT', element)
          })
          break
        default:
          break
      }
    })

    return renderElements
  }

  getReportId = (block, selections) => {
    const { questions } = this.state
    const questionElement = find(block.BlockElements, (element) => {
      return (
        element.Type === 'Question' &&
        !selections[element.QuestionID] &&
        questions[element.QuestionID] &&
        this.displayLogic(questions[element.QuestionID].DisplayLogic)
      )
    })

    if (questionElement) return questions[questionElement.QuestionID]
    return null
  }

  handleSurveyEnd = (reportId, parentId, answer = null) => {
    const { blocks, selections } = this.state
    const block = blocks[parentId]
    if (answer) {
      Array.prototype.push.apply(reportId, [block.Description, answer.tag])
    } else {
      const finalAnswer = this.getReportId(block, selections)
      Array.prototype.push.apply(reportId, [
        block.Description,
        finalAnswer ? finalAnswer.DataExportTag : ''
      ])
    }

    this.props.callSetReportId(
      reportId.join('-').replace(' ', '-').replace('&', 'and')
    )

    this.setState(
      {
        selections,
        reportId,
        endSurvey: true
      },
      () => {
        setTimeout(
          () => this.props.history.replace('/career-finder/personal-strengths'),
          3000
        )
      }
    )
  }

  handleResponse = (props) => {
    const { currentFlow, selections, reportId } = this.state
    const { id, questionId, text, parentId } = props

    selections[questionId] = { id, text }
    let newCurrentFlow = currentFlow

    const selectedBranch = find(currentFlow, (flowElement) => {
      let isNextBranch = false
      if (flowElement.Type === 'Branch') {
        isNextBranch = this.displayLogic(flowElement.BranchLogic, selections)
      }

      return isNextBranch
    })

    if (selectedBranch) {
      newCurrentFlow = selectedBranch.Flow
      if (
        selectedBranch.Flow.length === 1 &&
        selectedBranch.Flow[0].Type === 'ReferenceSurvey'
      ) {
        reportId.push(selectedBranch.Flow[0].Description)
        newCurrentFlow = selectedBranch.Flow[0].Flow
      }

      const drawElements = this.getElementsToDraw(newCurrentFlow)
      const nrChoices = sum(
        flatMap(drawElements, (element) => element.choicesOrder.length)
      )

      if (nrChoices === 0) {
        this.handleSurveyEnd(
          reportId,
          drawElements[0].parentId,
          drawElements[0]
        )
      } else {
        this.setState({
          currentFlow: newCurrentFlow,
          selections,
          reportId,
          drawElements
        })
      }
    } else {
      // TODO: Move to Strengths
      this.handleSurveyEnd(reportId, parentId)
    }
  }

  renderElements() {
    const { drawElements, selections } = this.state
    return drawElements.map((element) => {
      this.props.questionNumber()
      return (
        <SurveyQuestion
          key={element.id}
          id={element.id}
          text={element.text}
          tag={element.tag}
          parentId={element.parentId}
          choices={element.choices}
          choicesOrder={element.choicesOrder}
          display={
            !selections[element.id] && this.displayLogic(element.displayLogic)
          }
          onClickAction={this.handleResponse}
        />
      )
    })
  }

  render = () =>
    this.state.endSurvey ? (
      <PageLoader title='Analysing answers...' />
    ) : this.state.currentFlow ? (
      this.renderElements()
    ) : (
      <PageLoader title='Retrieving answers...' />
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  callSetReportId: (reportId) => dispatch(setReportId(reportId))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Survey))
