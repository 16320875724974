// Global document
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactGA from 'react-ga'

class GaWrapper extends Component {
  componentDidMount() {
    const { uuid, suid } = this.props
    ReactGA.initialize(
      [
        {
          trackingId: process.env.REACT_APP_GA_TRACKING_CODE,
          gaOptions: {
            name: 'tracker1'
          }
        },
        {
          trackingId: process.env.REACT_APP_GA_HULT360_TRACKING_CODE,
          gaOptions: { name: 'tracker2', allowLinker: true }
        }
      ],
      {
        debug: process.env.REACT_APP_ENV === 'QA'
      }
    )

    if (uuid && suid) {
      ReactGA.set({ dimension1: uuid, dimension2: suid }, [
        'tracker1',
        'tracker2'
      ])
    }

    ReactGA.pageview(document.location.pathname + document.location.search, [
      'tracker1',
      'tracker2'
    ])
    this.props.history.listen(location =>
      ReactGA.pageview(location.pathname + location.search, [
        'tracker1',
        'tracker2'
      ])
    )
  }
  componentDidUpdate(prevProps) {
    const { uuid, suid } = this.props

    if (uuid && suid && prevProps.uuid !== uuid && prevProps.suid !== suid) {
      ReactGA.set({ dimension1: uuid, dimension2: suid }, [
        'tracker1',
        'tracker2'
      ])
    }
  }
  render = () => <>{this.props.children}</>
}

GaWrapper.defaultProps = {
  uuid: null,
  suid: null
}

GaWrapper.propTypes = {
  uuid: PropTypes.string,
  suid: PropTypes.string,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
}

const mapStateToProps = state => ({
  uuid: state.user.uuid,
  suid: state.user.suid
})

const mapDispatchToProps = () => ({})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GaWrapper)
)
