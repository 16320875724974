import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import Survey from '../../containers/career-finder/Survey'
import ProgressBar from '../../components/progress-bar'

import { clearMapper, generateUids } from '../../actions'

class CareerQuiz extends Component {
  state = {
    questionNumber: -1
  }
  componentDidMount() {
    if (this.props.careerPath) this.props.callClearMapper()

    const { graduationYear, country, reportId } = this.props
    if (!graduationYear && !country) {
      this.props.history.replace('/career-finder/about-you')
    }

    if (reportId) {
      this.props.history.replace('/career-finder/personal-strengths')
    }
  }

  questionHandler = () => {
    const { questionNumber } = this.state
    this.setState({ questionNumber: questionNumber + 1 })
  }

  render = () => {
    const { questionNumber } = this.state
    const questionPercentage = questionNumber * 5
    return (
      <>
        <hr />
        <ProgressBar
          percentage={
            questionPercentage > 0 ? 33.34 + questionPercentage : 33.34
          }
          length={4}
        />
        <hr />
        <div className='g--spacer--small hide show--tablet' />
        <Survey questionNumber={this.questionHandler} />
      </>
    )
  }
}

CareerQuiz.defaultProps = {
  currentRole: null
}

CareerQuiz.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  graduationYear: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
  graduationYear: state.user.graduationYear,
  country: state.user.country,
  reportId: state.user.finderReportId,
  careerPath: state.user.careerPath
})

const mapDispatchToProps = (dispatch) => ({
  callClearMapper: () => {
    dispatch(generateUids(true))
    dispatch(clearMapper())
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CareerQuiz)
)
