import React from 'react'
import PropTypes from 'prop-types'
import { Answer } from 'hult-component-library'

const SurveyQuestion = props =>
  props.display && (
    <div>
      <div
        className='g--font-h4--light'
        dangerouslySetInnerHTML={{ __html: props.text }}
      />
      <div className='row row--stretch row--gutters-small'>
        {props.choicesOrder.map(order => (
          <div key={order} className='row__column row__column--50'>
            <Answer
              id={order}
              questionId={props.id}
              text={props.choices[order].Display}
              tag={props.tag}
              parentId={props.parentId}
              image={props.choices[order].Image}
              onClickAction={props.onClickAction}
            />
          </div>
        ))}
      </div>
    </div>
  )

SurveyQuestion.defaultProps = {
  choicesOrder: [],
  choices: {},
  onClickAction: props => {
    console.log('Unhandled Click', props)
  },
  display: true
}

SurveyQuestion.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  parentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  choicesOrder: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string)
  ]),
  choices: PropTypes.object,
  onClickAction: PropTypes.func,
  display: PropTypes.bool
}

export default SurveyQuestion
