import { RSAA } from 'redux-api-middleware'
import fetch from 'cross-fetch'

import {
  getProgramCode,
  getProgram,
  getRecommendations
} from '../utils/methods'
import * as ActionTypes from './types'

export const retrieveRecommendedPrograms = () => (dispatch, getState) =>
  dispatch({
    [RSAA]: {
      types: [
        ActionTypes.PROGRAMS_REQUEST,
        {
          type: ActionTypes.PROGRAMS_SUCCESS,
          payload: (action, state, res) =>
            res.json().then(json => {
              const { careerPath, education, experience } = getState().user

              let recommendations

              if (
                getRecommendations(json.stories, careerPath[1].name).length > 0
              ) {
                recommendations = getRecommendations(
                  json.stories,
                  careerPath[1].name
                )[0].content.recommendation
              } else {
                recommendations = getRecommendations(json.stories, 'Other')[0]
                  .content.recommendation
              }

              return {
                programs: getProgram(
                  getProgramCode(recommendations, experience, education),
                  json.stories
                )
              }
            })
        },
        ActionTypes.PROGRAMS_FAILURE
      ],
      endpoint: `https://api.storyblok.com/v1/cdn/stories/?starts_with=careermapper/program-recommendations&token=${
        process.env.REACT_APP_SB_TOKEN
      }&cv=${Date.now()}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },

      fetch: async (...args) => fetch(...args)
    }
  })
