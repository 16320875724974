import React from 'react'
import hultLogo from '../../assets/images/hult-logo.png'
import './hult-power.scss'
import { Container } from 'hult-component-library'

const HultPower = () => (
  <Container>
    <hr />
    <div className='hult-power row row--align-center'>
      <div className="row__column">
        <p className='g--margin-none g--font-body--light'>Powered by</p>
      </div>
      <div className="row__column">
        <img className='hult-power__logo' src={hultLogo} alt={hultLogo} />
      </div>
    </div>
    <div className="g--spacer hide--tablet" />
  </Container>
)

export default HultPower
