import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Skill } from 'hult-component-library'
// import Skill from '../skill'

const SkillGroup = ({
  className,
  title,
  description,
  skills,
  isDisabled,
  onSkillSelect,
  selectedSkills
}) => (
  <div
    className={`skill-group 
        ${isDisabled ? 'skill-group--disabled' : ''}
        ${className}`}
  >
    {title && <p className='g--font-body-bold'>{title}</p>}
    {description && <p className='g--font-body'>{description}</p>}
    <div className='row row--flow row--gutters-xsmall'>
      {skills.map(skill => (
        <div key={skill} className='row__column'>
          <Skill
            title={skill}
            onSkillSelect={onSkillSelect}
            isSelected={_.indexOf(selectedSkills, skill) !== -1}
          />
        </div>
      ))}
    </div>
  </div>
)

SkillGroup.defaultProps = {
  className: '',
  isDisabled: true,
  title: '',
  description: '',
  onSkillSelect: () => {},
  selectedSkills: []
}

SkillGroup.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  onSkillSelect: PropTypes.func,
  selectedSkills: PropTypes.arrayOf(PropTypes.string)
}

export default SkillGroup
