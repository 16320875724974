import { RSAA } from 'redux-api-middleware'
import fetch from 'cross-fetch'
import { utc } from 'moment'

import * as ActionTypes from './types'

const fetchAuthToken = (force = null) => ({
  [RSAA]: {
    types: [
      ActionTypes.AUTH_TOKEN_REQUEST,
      {
        type: ActionTypes.AUTH_TOKEN_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json.token)
      },
      ActionTypes.AUTH_TOKEN_FAILURE
    ],
    endpoint: `${process.env.REACT_APP_TOKEN}${force ? '?force=true' : ''}`,
    method: 'GET',
    fetch: async (...args) => fetch(...args)
  }
})

export const verifyToken = action => (dispatch, getState) => {
  const { authToken, api } = getState()

  if (
    authToken &&
    authToken.isFetching &&
    authToken.fetchingFrom < Date.now() + 15000
  ) {
    return null
  }

  if (api.apiState && api.apiState.status === 401) {
    const { method } = api.apiState
    return dispatch(fetchAuthToken(true)).then(() => {
      const token = getState().authToken.token
      if (token && method && method.func && typeof method.func === 'function') {
        const { values } = method
        values.token = token.access_token
        return dispatch(method.func(values))
      }
      return token ? token.access_token : 'BADTOKEN'
    })
  }

  if (authToken && authToken.token) {
    const expireDate = utc(authToken.token.expires_at)
    const currentDate = utc()
    if (!currentDate.isSameOrAfter(expireDate, 'minute')) {
      if (action && typeof action === 'function') {
        return dispatch(action(getState().authToken.token.access_token))
      }
      return getState().authToken.token.access_token
    }
  }

  return dispatch(fetchAuthToken()).then(() => {
    const token = getState().authToken.token
    if (token && action && typeof action === 'function') {
      return dispatch(action(token.access_token))
    }
    return token ? token.access_token : 'BADTOKEN'
  })
}
