import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import { Link } from 'react-router-dom'
import Program from '../../../components/program'
import PageLoader from '../../../components/page-loader'
import Role from '../../../components/role'
import Summary from '../../../components/summary'
import Share from '../../../components/share'
import { Spinner, Skill, Card, Course } from 'hult-component-library'

import { studentRole } from '../../../utils/config'
import { parseSkillGroupsReport } from '../../../utils/parseBGSkills'
import { calculateSalaryIncrease } from '../../../utils/methods'

class SharedReport extends Component {
  state = {
    missingSkills: [],
    shortTermMissingSkills: [],
    longTermMissingSkills: [],
    shortTermSalaryBoosterSkills: [],
    longTermSalaryBoosterSkills: [],
    shortTermUncoveredSkills: [],
    longTermUncoveredSkills: []
  }

  componentDidMount() {
    const { report, match } = this.props
    const { uuid, suid } = match.params

    if (uuid && suid && report.uuid === uuid && report.suid === suid)
      this.calculateSkillsState()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.report, this.props.report)) {
      this.calculateSkillsState()
    }
  }

  calculateSkillsState() {
    const { report, match } = this.props
    const { uuid, suid } = match.params

    if (uuid && suid && report.uuid === uuid && report.suid === suid) {
      const missingSkills = this.missingSkillsMethod()
      const shortTermMissingSkills = this.roleMissingSkills(
        _.first(report.careerPath)
      )
      const shortTermSalaryBoosterSkills = this.salaryBoostSkills(
        _.first(report.careerPath)
      )
      const shortTermUncoveredSkills = this.checkUncoveredSkills(
        shortTermMissingSkills
      )
      const longTermMissingSkills = this.roleMissingSkills(
        _.last(report.careerPath)
      )
      const longTermSalaryBoosterSkills = this.salaryBoostSkills(
        _.last(report.careerPath)
      )
      const longTermUncoveredSkills = this.checkUncoveredSkills(
        longTermMissingSkills
      )
      this.setState({
        missingSkills,
        shortTermMissingSkills,
        longTermMissingSkills,
        shortTermSalaryBoosterSkills,
        longTermSalaryBoosterSkills,
        shortTermUncoveredSkills,
        longTermUncoveredSkills
      })
    }
  }

  missingSkillsMethod() {
    const { careerPath } = this.props.report
    const selectedSkills = _.uniq(_.flatMap(careerPath, 'skills'))
    let missingSkills = []

    _.each(careerPath, r => {
      const details = r.details
      const skills = _.flatMap(details.skills, s => _.flatMap(s, 'name'))

      missingSkills = _.concat(
        missingSkills,
        _.filter(skills, s => _.indexOf(selectedSkills, s) === -1)
      )
    })
    return missingSkills
  }

  roleMissingSkills(role) {
    const roleDetails = role.details
    const roleMissingSkills = parseSkillGroupsReport(
      roleDetails.skills,
      _.uniq(_.flatMap(this.props.report.careerPath, 'skills'))
    )

    return roleMissingSkills
  }

  checkUncoveredSkills(roleMissingSkills) {
    const { report } = this.props
    const coveredSkills = _.uniq(
      _.flatMap(report.programs || [], p => _.flatMap(p.Skills, 'Title'))
    )

    const roleSkills = _.flatMap(roleMissingSkills, (s, g) => {
      if (g !== 'other') return s
      return []
    })

    return _.filter(
      _.uniqBy(_.orderBy(roleSkills, 'count', 'desc'), 'name'),
      s => _.indexOf(coveredSkills, s.name) === -1
    )
  }

  salaryBoostSkills(role) {
    const roleDetails = role.details

    if (roleDetails) {
      const salaryBoostSkills = _.slice(
        _.orderBy(
          JSON.parse(JSON.stringify(roleDetails.salaryBoostSkills || [])),
          'count'
        ),
        0,
        3
      )

      _.each(
        salaryBoostSkills,
        sbs =>
          (sbs.salaryIncrease = calculateSalaryIncrease(
            roleDetails.salary.mean,
            roleDetails.salary.mean + sbs.marginalValue
          ))
      )

      return salaryBoostSkills
    }

    return []
  }

  getRoleSalaryIncrease(roleDetails) {
    const { currentRoleDetails } = this.props.report

    let currentRoleMeanSalary = studentRole.salary
    if (currentRoleDetails && currentRoleDetails.salary) {
      currentRoleMeanSalary = currentRoleDetails.salary.mean
    }

    return calculateSalaryIncrease(
      currentRoleMeanSalary,
      roleDetails.salary.mean
    )
  }

  skillMatches = (array1, array2) =>
    array1.filter(element => array2.includes(element))

  render() {
    const { report, match } = this.props
    const { uuid, suid } = match.params

    if (uuid && suid && report.uuid === uuid && report.suid === suid) {
      const { careerPath } = report
      const shortTermMissingSkills = _.uniqBy(
        _.orderBy(
          _.flatMap(this.state.shortTermMissingSkills, (s, g) => {
            if (g !== 'other') return s
            return []
          }),
          'count',
          'desc'
        ),
        'name'
      )

      const longTermMissingSkills = _.uniqBy(
        _.orderBy(
          _.flatMap(this.state.longTermMissingSkills, (s, g) => {
            if (g !== 'other') return s
            return []
          }),
          'count',
          'desc'
        ),
        'name'
      )

      return (
        <>
          <div className='content'>
            <hr />
            <Summary
              firstName={report.firstName}
              lastName={report.lastName}
              currentRole={report.currentRole}
              education={report.education}
              experience={report.experience}
            />
            {careerPath.length > 1 && (
              <>
                <div className='g--spacer--small hide show--tablet' />
                <p className='g--font-h4--light'>Your short-term goal</p>
                <Card>
                  <Role
                    title={_.first(careerPath).name}
                    salary={this.getRoleSalaryIncrease(
                      _.first(careerPath).details
                    )}
                    experience={Math.round(
                      _.first(careerPath).details.experience.mean
                    )}
                    demand={_.first(careerPath).details.demand.level}
                  />
                  <hr />
                  <p className='g--font-body-bold'>
                    Top 3 most in-demand skills for this role
                  </p>
                  {_.slice(shortTermMissingSkills, 0, 3).map(s => (
                    <Course
                      key={s.name}
                      skill={s.name}
                      desc={s.description}
                      href={`https://www.udemy.com/courses/search/?q=${s.name}`}
                    />
                  ))}
                  {this.state.shortTermSalaryBoosterSkills &&
                    this.state.shortTermSalaryBoosterSkills.length > 0 && (
                      <>
                        <hr />
                        <p className='g--font-body-bold'>
                          Maximize your salary
                        </p>
                        <p>Skills that companies are paying a premium for</p>
                        {this.state.shortTermSalaryBoosterSkills &&
                          this.state.shortTermSalaryBoosterSkills.map(s => (
                            <div key={s.name}>
                              <Course
                                skill={s.name}
                                salary={s.salaryIncrease}
                                desc={s.description}
                                href={`https://www.udemy.com/courses/search/?q=${s.name}`}
                              />
                            </div>
                          ))}
                      </>
                    )}
                  {shortTermMissingSkills.length > 3 && (
                    <>
                      <hr />
                      <p className='g--font-body-bold'>
                        Other skills typically needed for this role
                      </p>
                      <div className='row row--flow row--gutters-xsmall'>
                        {_.slice(shortTermMissingSkills, 3).map((s, index) => (
                          <div className='row__column' key={index}>
                            <Skill
                              title={s.name}
                              key={s.name}
                              disableTogle={true}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </Card>
              </>
            )}
            <>
              <hr />
              <p className='g--font-h4--light'>Your long-term opportunity</p>
              <Card>
                <Role
                  title={_.last(careerPath).name}
                  salary={this.getRoleSalaryIncrease(
                    _.last(careerPath).details
                  )}
                  experience={Math.round(
                    _.last(careerPath).details.experience.mean
                  )}
                />
                {longTermMissingSkills.length > 0 && (
                  <>
                    <p className='g--font-body-bold'>
                      Skills typically needed for this role
                    </p>
                    <div className='row row--flow row--gutters-xsmall'>
                      {_.slice(longTermMissingSkills).map((s, index) => (
                        <div className='row__column' key={index}>
                          <Skill
                            title={s.name}
                            key={s.name}
                            disableTogle={true}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </Card>
              <hr />
              <p className='g--font-body-bold--light'>
                {`Progams to build the skills you need to become a  ${
                  _.last(careerPath).name
                }`}
              </p>
              {report.programs.length > 0 && (
                <div className='programs row row--snap'>
                  {report.programs.map((p, pos) => (
                    <div className='row__column' key={pos}>
                      <Program
                        position={++pos}
                        totalPrograms={report.programs.length}
                        key={p.Id}
                        title={p.title}
                        description={p.description}
                        skillPercentage={parseInt(
                          (this.skillMatches(
                            p.skills_list,
                            _.uniq(
                              _.flatMap(this.state.longTermMissingSkills, s =>
                                _.flatMap(s, 'name')
                              )
                            )
                          ).length *
                            100) /
                            _.uniq(
                              _.flatMap(this.state.longTermMissingSkills, s =>
                                _.flatMap(s, 'name')
                              )
                            ).length
                        )}
                        link={p.link}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div className='programs__loaders'>
                <div id='loadingPrograms' className='programs__loading'>
                  <Spinner />
                  <p className='g--font-body--light'>{`Our algorithms are working at high speeds to suggest programs to become a ${
                    _.last(careerPath).name
                  }`}</p>
                </div>
                <p
                  id='noProgramsAlert'
                  className='programs__alert g--font-body--light'
                >
                  We advise you to look for mentors that have been on a similar
                  career path
                </p>
              </div>
            </>
          </div>
          <hr />
          <Share
            name={report.firstName}
            uuid={report.uuid}
            suid={report.suid}
          />
          <hr />
          <p className='g--font-body--light'>
            <Link to='/career-mapper/data'>
              <b className='g--font-body-bold--light'>Career Mapper data</b>
            </Link>{' '}
            is provided by various leading public &amp; private big data sources
          </p>
        </>
      )
    }

    return <PageLoader title='Loading...' />
  }
}

SharedReport.defaultProps = {}

SharedReport.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired
}

const mapStateToProps = state => ({ report: state.report || {} })

const mapDispatchToProps = () => ({})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SharedReport)
)
