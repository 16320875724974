/* eslint-disable dot-notation */
/* global process */
import fetch from 'cross-fetch'
import { RSAA } from 'redux-api-middleware'

import * as ActionTypes from './types'
import { getCookieData } from '../utils/handleCookies'

function workExperience(workExperienceString) {
  switch (workExperienceString) {
    case 'Student':
    case 'No work experience':
      return '0'
    case 'Less than 3':
      return '1 2 3'
    case '3-5':
      return '3 4 5'
    case '6-7':
      return '6 7'
    case '8-10':
      return '8 9 10'
    case '11-13':
      return '11 12 13'
    case '13+':
      return '14 15'
    default:
      return '0'
  }
}

const sfAPI = data => {
  console.log({ data })
  return ({
    [RSAA]: {
      types: [
        ActionTypes.CREATE_LEAD_REQUEST,
        ActionTypes.CREATE_LEAD_SUCCESS,
        ActionTypes.CREATE_LEAD_FAILURE
      ],
      endpoint: `${process.env.REACT_APP_SF_SERVICE}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      fetch: async (...args) => fetch(...args)
    }
  })
}

function buildDataObject(state, cookie, programCode, subject) {
  const { user } = state

  const dataObject = {
    formType: "SKILLS",
    firstName: user.firstName,
    lastName: user.lastName,
    selectYourProgram: programCode,
    countryCode: user.country,
    email: user.email,
    workExperience: workExperience(user.experience),
    source: `${window.location.origin}/report/${user.uuid}/${user.suid}`,
    partnerCode: cookie.partnerCode,
    webCampaign: cookie.firstwebCampaign,
    webMedium: cookie.webMedium,
    webTerm: cookie.webTerm,
    webContent: cookie.webContent,
    webAdgroup: cookie.webAdgroup,
    webPlacement: cookie.webPlacement,
    firstpartnerCode: cookie.firstpartnerCode,
    firstwebCampaign: cookie.webCampaign,
    firstwebMedium: cookie.firstwebMedium,
    firstwebTerm: cookie.firstwebTerm,
    firstwebContent: cookie.firstwebContent,
    firstwebAdgroup: cookie.firstwebAdgroup,
    firstwebPlacement: cookie.firstwebPlacement,
    weferringUrl: encodeURIComponent(cookie.referringUrl),
    campaignID: `${process.env.REACT_APP_CAMPAIGNID}`,
    subject: subject
  }

  if (user.phone) {
    dataObject.phoneNumber = user.phone
  }

  if (user.countryState) {
    dataObject.state = user.countryState
  }

  return dataObject
}

export const createLead = () => (dispatch, getState) => {
  const { user, api } = getState()
  if (user.stored) return
  let recommendedProgramCode = ''
  const userCookie = getCookieData()

  const { recommendedPrograms } = api

  if (recommendedPrograms.programs && recommendedPrograms.programs.length) {
    recommendedProgramCode = recommendedPrograms.programs[0].programCode
  }

  return dispatch(
    sfAPI(
      buildDataObject(
        getState(),
        userCookie,
        recommendedProgramCode,
        'Career Mapper'
      )
    )
  )
}

export const createBBALead = () => (dispatch, getState) => {
  const { user } = getState()
  if (user.stored) return
  const userCookie = getCookieData()

  return dispatch(
    sfAPI(buildDataObject(getState(), userCookie, 'BBA', 'Career Finder'))
  )
}
