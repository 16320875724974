import _ from 'lodash'
import * as ActionTypes from '../actions/types'

import { educationOptions } from '../utils/config'

function addUserCareerPath(action, state) {
  const careerPath = JSON.parse(JSON.stringify(state.careerPath || []))
  careerPath.push({ name: action.role, skills: [] })

  return careerPath
}

function addPreSelectedSkills(action, state) {
  const careerPath = JSON.parse(JSON.stringify(state.careerPath || []))
  const role = _.find(careerPath, function (r) {
    return r.name === action.roleName
  })

  role.skills = _.union(role.skills, action.skillsList)

  return careerPath
}

function addRoleSkill(action, state) {
  const careerPath = JSON.parse(JSON.stringify(state.careerPath || []))
  const role = _.find(careerPath, function (r) {
    return r.name === action.roleName
  })

  if (_.indexOf(role.skills, action.skillName) === -1) {
    role.skills.push(action.skillName)
  }

  return careerPath
}

function removeRoleSkill(action, state) {
  const careerPath = JSON.parse(JSON.stringify(state.careerPath || []))
  const role = _.find(careerPath, function (r) {
    return r.name === action.roleName
  })

  if (_.indexOf(role.skills, action.skillName) !== -1) {
    _.pull(role.skills, action.skillName)
  }

  return careerPath
}

function clearRoleSkills(action, state) {
  const careerPath = JSON.parse(JSON.stringify(state.careerPath || []))
  const role = _.find(careerPath, function (r) {
    return r.name === action.roleName
  })

  role.skills = []

  return careerPath
}

function clearCareerPath(action, state) {
  let careerPath = JSON.parse(JSON.stringify(state.careerPath || []))
  careerPath = _.dropRight(careerPath, careerPath.length - action.pos)

  return careerPath
}

function setDataFromLinkedIn({ payload }) {
  const keys = _.keys(payload)
  const details = JSON.parse(
    JSON.stringify(keys.length === 1 ? payload[keys[0]].result : payload.result)
  )

  const education = _.replace(
    _.last(_.orderBy(details.education.distribution, 'frequency')).bin,
    'degree',
    'Degree'
  )

  let experience = 'Student'
  if (details.experience.mean > 13) {
    experience = '13+'
  } else if (details.experience.mean >= 11) {
    experience = '11-13'
  } else if (details.experience.mean >= 8) {
    experience = '8-10'
  } else if (details.experience.mean >= 6) {
    experience = '6-7'
  } else if (details.experience.mean >= 3) {
    experience = '3-5'
  } else if (details.experience.mean < 3) {
    experience = 'Less than 3'
  }

  return {
    education:
      _.indexOf(educationOptions, education) === -1 ? 'High School' : education,
    experience
  }
}

function addFinderStrength(action, state) {
  const finderStrenghts = JSON.parse(
    JSON.stringify(state.finderStrenghts || [])
  )

  if (_.indexOf(finderStrenghts, action.strengthName) === -1) {
    finderStrenghts.push(action.strengthName)
  }

  return finderStrenghts
}

function removeFinderStrength(action, state) {
  const finderStrenghts = JSON.parse(
    JSON.stringify(state.finderStrenghts || [])
  )

  if (_.indexOf(finderStrenghts, action.strengthName) !== -1) {
    _.pull(finderStrenghts, action.strengthName)
  }

  return finderStrenghts
}

export default (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER_UUID:
      return Object.assign({}, state, {
        uuid: action.uuid
      })

    case ActionTypes.SET_USER_SUID:
      return Object.assign({}, state, {
        suid: action.suid
      })

    case ActionTypes.REGISTER_USER:
      const {
        firstName,
        lastName,
        email,
        phone,
        country,
        countryState,
        privacyPolicy
      } = action.user
      return Object.assign({}, state, {
        firstName,
        lastName,
        email,
        phone,
        country,
        countryState,
        privacyPolicy
      })

    case ActionTypes.SET_USER_FIRSTNAME:
      return Object.assign({}, state, {
        firstName: action.firstName
      })

    case ActionTypes.SET_USER_LASTNAME:
      return Object.assign({}, state, {
        lastName: action.lastName
      })

    case ActionTypes.SET_USER_EMAIL:
      return Object.assign({}, state, {
        email: action.email
      })

    case ActionTypes.SET_USER_COUNTRY:
      return Object.assign({}, state, {
        country: action.country
      })

    case ActionTypes.SET_USER_EDUCATION:
      return Object.assign({}, state, {
        education: action.education
      })

    case ActionTypes.SET_USER_JOB_CURRENT:
      return Object.assign({}, state, {
        currentRole: action.job,
        careerPath: []
      })

    case ActionTypes.SET_USER_JOB_ID_CURRENT:
      return Object.assign({}, state, {
        currentRoleId: action.jobId,
        careerPath: []
      })

    case ActionTypes.SET_USER_EXPERIENCE:
      return Object.assign({}, state, {
        experience: action.experience,
        currentRole: action.experience === 'Student' ? 'Student' : null
      })

    case ActionTypes.SET_USER_GRADUATION_YEAR:
      return Object.assign({}, state, {
        graduationYear: action.graduationYear
      })

    case ActionTypes.ADD_USER_CAREER_PATH_ROLE:
      return Object.assign({}, state, {
        careerPath: addUserCareerPath(action, state)
      })

    case ActionTypes.ADD_USER_ROLE_PRE_SKILL:
      return Object.assign({}, state, {
        careerPath: addPreSelectedSkills(action, state)
      })

    case ActionTypes.ADD_USER_ROLE_SKILL:
      return Object.assign({}, state, {
        careerPath: addRoleSkill(action, state)
      })

    case ActionTypes.REMOVE_USER_ROLE_SKILL:
      return Object.assign({}, state, {
        careerPath: removeRoleSkill(action, state)
      })

    case ActionTypes.CLEAR_USER_ROLE_SKILL:
      return Object.assign({}, state, {
        careerPath: clearRoleSkills(action, state)
      })

    case ActionTypes.CLEAR_USER_CAREER_PATH_ROLE:
      return Object.assign({}, state, {
        careerPath: clearCareerPath(action, state),
        finderReportId: null,
        finderStrengths: [],
        stored: false
      })

    case ActionTypes.SET_USER_NEXT_ROLE:
      return Object.assign({}, state, {
        nextRole: action.nextRole
      })

    case ActionTypes.SET_USER_FUTURE_ROLE:
      return Object.assign({}, state, {
        futureRole: action.futureRole
      })

    case ActionTypes.SET_USER_CAREER_GOAL:
      return Object.assign({}, state, {
        careerGoal: action.careerGoal
      })

    case ActionTypes.ADD_USER_FINDER_STRENGTH:
      return Object.assign({}, state, {
        finderStrenghts: addFinderStrength(action, state)
      })

    case ActionTypes.REMOVE_USER_FINDER_STRENGTH:
      return Object.assign({}, state, {
        finderStrenghts: removeFinderStrength(action, state)
      })

    case ActionTypes.SET_USER_FINDER_REPORT_ID:
      return Object.assign({}, state, {
        finderReportId: action.reportId
      })

    case ActionTypes.ADD_FINDER_REPORT:
      return Object.assign({}, state, {
        reportDetails: action.report
      })

    case ActionTypes.CLEAR_FINDER_REPORT:
      return Object.assign({}, state, {
        reportDetails: undefined,
        finderReportId: undefined,
        finderStrenghts: undefined,
        stored: false
      })

    case ActionTypes.CLEAR_MAPPER_REPORT:
      return Object.assign({}, state, {
        careerPath: undefined
      })

    case ActionTypes.CLEAR_USER_NEXT_ROLE:
      delete state.nextRole
      delete state.futureRole
      delete state.careerGoal
      return state

    case ActionTypes.CLEAR_USER_FUTURE_ROLE:
      delete state.futureRole
      delete state.careerGoal
      return state

    case ActionTypes.CLEAR_USER_CAREER_GOAL:
      delete state.careerGoal
      return state

    case ActionTypes.LINKEDIN_PROFILE_SUCCESS:
      const linkedInProfile = action.payload

      return Object.assign({}, state, {
        firstName: linkedInProfile.localizedFirstName,
        lastName: linkedInProfile.localizedLastName,
        email: linkedInProfile.elements[0]['handle~'].emailAddress,
        country: linkedInProfile.location
          ? linkedInProfile.location.countryCode.toUpperCase()
          : 'UK',
        privacyPolicy: true
      })

    case ActionTypes.BG_JOB_TITLE_LI_SUCCESS:
      if (action.payload.length > 0) {
        return Object.assign({}, state, { currentRole: action.payload[0] })
      }
      return state

    case ActionTypes.BG_JOB_DETAILS_LI_SUCCESS:
      return Object.assign({}, state, setDataFromLinkedIn(action))

    case ActionTypes.CLEAR_USER_ALL:
      return {}

    case ActionTypes.CREATE_LEAD_SUCCESS:
      return Object.assign({}, state, { stored: true })
    default:
      return state
  }
}
