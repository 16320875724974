import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { intersection, isEqual } from 'lodash'

import { Spinner, Card, Degree, Message } from 'hult-component-library'
import Discipline from '../../../components/discipline'
import HultIntro from '../../../components/hult-intro'
import PageLoader from '../../../components/page-loader'
import Summary from '../../../components/summary'
import Share from '../../../components/share'
import { addFinderReport } from '../../../actions'

class SharedReport extends Component {
  state = { disciplineCount: 0 }

  componentDidMount() {
    const { report, match } = this.props
    const { uuid, suid } = match.params

    if (uuid && suid && report.uuid === uuid && report.suid === suid)
      this.getReportData()
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.report, this.props.report)) {
      this.getReportData()
    }
  }

  getReportData() {
    const { report } = this.props
    let { disciplineCount } = this.state

    if (report && report.reportDetails) {
      if (report.reportDetails.first_discipline) {
        disciplineCount++
      }
      if (report.reportDetails.second_discipline) {
        disciplineCount++
      }
      if (report.reportDetails.third_discipline) {
        disciplineCount++
      }

      this.setState({
        disciplineCount
      })
    }
  }

  render() {
    const { report, match } = this.props
    const { uuid, suid } = match.params

    console.log(report)

    if (uuid && suid && report.uuid === uuid && report.suid === suid) {
      return (
        <>
          <div className='content'>
            <div className='g--spacer--small hide show--tablet' />
            <Summary
              firstName={report.firstName}
              lastName={report.lastName}
              education={`Class of ${report.graduationYear}`}
              strengthList={
                report.reportDetails
                  ? [
                      report.reportDetails.response_q1 || '',
                      report.reportDetails.response_q2 || '',
                      report.reportDetails.response_q3 || ''
                    ]
                  : []
              }
              isHighSchoolStudent
            />
            {this.state.disciplineCount > 0 && (
              <>
                <hr />
                <Message
                  message={`Based on your answers, your top ${this.state.disciplineCount} career tracks are...`}
                />
              </>
            )}
            {report.reportDetails ? (
              <>
                {report.reportDetails.first_discipline &&
                  typeof report.reportDetails.first_discipline === 'object' && (
                    <>
                      <hr />
                      <p className='g--font-h4--light'>Top match</p>
                      <Discipline
                        position='1'
                        matchRating={
                          report.reportDetails.first_discipline_percentage
                        }
                        title={report.reportDetails.first_discipline.title}
                        description={
                          report.reportDetails.first_discipline.description
                        }
                        avatar='smile'
                        startSalary={
                          report.reportDetails.first_discipline.start_salary
                        }
                        futureSalary={
                          report.reportDetails.first_discipline.future_salary
                        }
                        details='first_discipline'
                        allStrengths={
                          report.reportDetails.first_discipline.strengths.length
                        }
                        strengths={
                          intersection(
                            report.reportDetails.first_discipline.strengths,
                            this.props.selectedStrenghts
                          ).length
                        }
                        hrefDetails='/report-discipline/'
                      />
                    </>
                  )}
                {report.reportDetails.second_discipline &&
                  typeof report.reportDetails.second_discipline ===
                    'object' && (
                    <>
                      <hr />
                      <p className='g--font-h4--light'>
                        You could also look at...
                      </p>
                      <Discipline
                        position='2'
                        matchRating={
                          report.reportDetails.second_discipline_percentage
                        }
                        title={report.reportDetails.second_discipline.title}
                        description={
                          report.reportDetails.second_discipline.description
                        }
                        startSalary={
                          report.reportDetails.second_discipline.start_salary
                        }
                        futureSalary={
                          report.reportDetails.second_discipline.future_salary
                        }
                        details='second_discipline'
                        allStrengths={
                          report.reportDetails.second_discipline.strengths
                            .length
                        }
                        strengths={
                          intersection(
                            report.reportDetails.second_discipline.strengths,
                            this.props.selectedStrenghts
                          ).length
                        }
                        hrefDetails='/report-discipline/'
                      />
                    </>
                  )}
                {report.reportDetails.third_discipline &&
                  typeof report.reportDetails.third_discipline === 'object' && (
                    <>
                      <hr />
                      <p className='g--font-h4--light'>Or how about...</p>
                      <Discipline
                        position='3'
                        matchRating={
                          report.reportDetails.third_discipline_percentage
                        }
                        title={report.reportDetails.third_discipline.title}
                        description={
                          report.reportDetails.third_discipline.description
                        }
                        startSalary={
                          report.reportDetails.third_discipline.start_salary
                        }
                        futureSalary={
                          report.reportDetails.third_discipline.future_salary
                        }
                        details='third_discipline'
                        allStrengths={
                          report.reportDetails.third_discipline.strengths.length
                        }
                        strengths={
                          intersection(
                            report.reportDetails.third_discipline.strengths,
                            this.props.selectedStrenghts
                          ).length
                        }
                        hrefDetails='/report/discipline/'
                      />
                    </>
                  )}
                {report.reportDetails.recommendation_major &&
                  report.reportDetails.recommendation_minor && (
                    <>
                      <hr />
                      <p className='g--font-h4--light'>Hult's recommendation</p>
                      <Card>
                        <Message message='You’re on track—now let’s get you there! The HultBot recommends the following undergraduate program...' />
                        <hr />
                        <Degree
                          title={
                            report.reportDetails.recommendation_major.title
                          }
                          type='Major'
                          description={
                            report.reportDetails.recommendation_major
                              .description
                          }
                        />
                        <Degree
                          title={
                            report.reportDetails.recommendation_minor.title
                          }
                          type='Minor'
                          description={
                            report.reportDetails.recommendation_minor
                              .description
                          }
                        />
                        <hr />
                        <HultIntro />
                      </Card>
                      <hr />
                      <Share
                        name={report.firstName}
                        uuid={report.uuid}
                        suid={report.suid}
                      />
                      <hr />
                      <p className='g--font-body--light'>
                        <Link to='/career-mapper/data'>
                          <b className='g--font-body-bold--light'>
                            Career Finder data
                          </b>
                        </Link>{' '}
                        is provided by various leading public &amp; private big
                        data sources
                      </p>
                    </>
                  )}
              </>
            ) : (
              <>
                <hr />
                <Spinner />
              </>
            )}
          </div>
        </>
      )
    }

    return <PageLoader title='Loading...' />
  }
}

SharedReport.defaultProps = {}

SharedReport.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired
}

const mapStateToProps = state => ({ report: state.report || {} })

const mapDispatchToProps = dispatch => ({
  callAddFinderReport: report => {
    dispatch(addFinderReport(report))
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SharedReport)
)
